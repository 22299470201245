////
/// @group wk-css.components.buttons
/// @author [GPO](https://gpo.wolterskluwer.com/)
/// @since 0.17.0
/// @access public
////

/// Creates a button with icon
/// @param  {String} $pos [right] - Button icon position
/// @param  {Color} $bg [$wk-transparent-color] - Box color
@mixin wk-button-icon($pos: right, $bg: $wk-transparent-color) {
    position: relative;
    padding-#{$pos}: wk-rem(64px);

    &::before {
        background: $bg;
        color: wk-primary-color(wkWhite);
        margin: 0;
        padding: wk-rem(17px);
        position: absolute;
        top: 0;
        width: wk-rem(48px);
        #{$pos}: 0;
    }
}
