/////
/// Navbar dropdown
/// @since 0.18.0
/// @group wk-css.components.navbar-dropdown
/// @author [GPO](https://gpo.wolterskluwer.com/)
/////

/// Declares properties for navbar dropdown container
%wk-navbar-dropdown-container {
    @include wk-clearfix;
    background: $wk-navbar-dropdown-background-color;
    color: $wk-navbar-dropdown-color;
    display: none;

    #{$wk-navbar-dropdown-column-name}-group {
        @include wk-responsive-grid(
            (
                child: $wk-navbar-dropdown-column-name,
                flex: $wk-navbar-dropdown-column-flex,
                gutters: $wk-navbar-dropdown-column-gutters,
                mobile-count: $wk-navbar-dropdown-column-mobile-count,
                tablet-count: $wk-navbar-dropdown-column-tablet-count,
                desktop-count: $wk-navbar-dropdown-column-desktop-count
            )
        );
    }

    &.wk-navbar-dropdown-classic {
        overflow: hidden;

        .wk-nav > li {
            position: relative;
        }
    }
}

/// Declares properties for navbar dropdown
%wk-navbar-dropdown {
    @include wk-viewport-insulate;

    padding-bottom: 0;
    padding-top: $wk-navbar-dropdown-top-gutter-mobile;

    @include breakpoint($wk-target-tablet-desktop) {
        padding-bottom: $wk-navbar-dropdown-bottom-gutter;
        padding-top: 0;
    }

    @include breakpoint($wk-desktop-max-width + (2 * $wk-page-content-padding)) {
        margin-left: auto;
        margin-right: auto;
        max-width: $wk-desktop-max-width;
    }

    #{$wk-navbar-dropdown-column-name} {
        padding-bottom: 0;
        padding-top: $wk-navbar-dropdown-container-gutter;

        @include breakpoint($wk-target-tablet-desktop) {
            padding-top: $wk-navbar-dropdown-default-gutter;
        }
    }

    &.wk-navbar-dropdown-classic {
        @include breakpoint($wk-desktop-max-width + (2 * $wk-page-content-padding)) {
            padding-left: $wk-page-content-padding;
            padding-right: $wk-page-content-padding;
        }
    }

    ul,
    li,
    dd,
    figure {
        margin: 0;
        padding: 0;
    }

    li {
        list-style-type: none;
    }

    .wk-dropdown-column-list,
    [wk-dropdown-column*='list'] {
        li,
        dt,
        dd {
            font-size: $wk-navbar-dropdown-list-item-font-size;
            line-height: $wk-navbar-dropdown-list-item-line-height;
            margin-bottom: $wk-navbar-dropdown-list-item-margin-bottom-mobile;

            @include breakpoint($wk-target-tablet-desktop) {
                margin-bottom: $wk-navbar-dropdown-list-item-margin-bottom;
            }
        }

        dt {
            opacity: $wk-navbar-dropdown-subhead-item-opacity;
        }

        a {
            color: $wk-navbar-dropdown-color;
            display: block;

            @include breakpoint($wk-target-tablet-desktop) {
                display: inline;
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }

    li,
    dd > figcaption {
        margin-bottom: $wk-navbar-dropdown-list-item-margin-bottom;
    }
}
