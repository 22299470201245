////
/// @group wk-css.components.breadcrumbs
/// @author [GPO](https://gpo.wolterskluwer.com/)
/// @since 0.8.0
/// @access public
////

@import 'breadcrumbs.variables';

@include wk-exports('breadcrumbs') {

    %scss-deps-breadcrumbs {
        components: wk-component-dependencies(
            product-name
        );
        utils: wk-utils-dependencies(
            function wk-rem,
            function wk-primary-color,
            mixin container,
            mixin gutter,
            mixin wk-page-content,
            mixin wk-target-icons,
            variable wk-target-tablet-desktop,
            variable wk-font-weight,
            variable wk-headings-font-stack
        );
    }

    .wk-breadcrumb-bar {
        background-color: $wk-breadcrumb-bar-background-color;
        margin-bottom: gutter();
        position: relative;
        z-index: 1;

        .wk-breadcrumb-container {
            @include container;

            background-color: $wk-breadcrumb-bar-background-color;
        }
    }

    .wk-breadcrumb {
        @include wk-hidden;
        @include wk-viewport-insulate;

        font-size: 0;
        list-style-type: none;
        margin: 0;
        padding-bottom: $wk-breadcrumb-padding;
        padding-top: $wk-breadcrumb-padding;

        @include breakpoint($wk-target-tablet-desktop) {
            @include wk-shown;
            float: $wk-breadcrumb-desktop-float;
        }

        > li {
            color: $wk-breadcrumb-color;
            display: $wk-breadcrumb-display;
            font-family: $wk-breadcrumb-font-family;
            font-size: $wk-breadcrumb-font-size;
            font-weight: $wk-breadcrumb-font-weight;

            @include wk-target-icons {
                margin-left: $wk-breadcrumb-icon-margin-left;
                margin-right: $wk-breadcrumb-icon-margin-right;
            }

            &:first-child {
                font-weight: $wk-breadcrumb-first-child-font-weight;
            }

            &:not(:first-child)::before {
                color: $wk-breadcrumb-divider-color;
                content: $wk-breadcrumb-divider;
                margin-left: $wk-breadcrumb-divider-margin;
                margin-right: $wk-breadcrumb-divider-margin;
            }
        }
    }
}
