/////
/// Navbar dropdown
/// @since 0.18.0
/// @group wk-css.components.navbar-dropdown
/// @author [GPO](https://gpo.wolterskluwer.com/)
/////

/// Creates open state css selectors for dropdowns
/// @param Boolean $show-on-hover - dropdown show on hover or on wk-state
///
/// @example scss - Usage
/// .wk-navbar-dropdown-container {
///   @include wk-navbar-dropdown-container(true);
/// }
///
/// @example scss - Output
/// .wk-nav > li:hover{
///     .wk-navbar-dropdown-container {
///         display: block;
///         left: 0;
///         position: relative;
///         width: 100%;
///         @media (min-width: 65rem){
///             position: absolute;
///         }
///         &.wk-navbar-dropdown-classic{
///             left: inherit;
///             width: auto;
///         }
///     }
/// }
@mixin wk-navbar-dropdown-container($show-on-hover) {

    $wk-navbar-dropdown-parent: $wk-navbar-dropdown-hover-selector !default;
    @if $show-on-hover == true {
        $wk-navbar-dropdown-parent: $wk-navbar-dropdown-hover-selector;
    } @else {
        $wk-navbar-dropdown-parent: $wk-navbar-dropdown-state-selector;
    }

    #{$wk-navbar-dropdown-parent} {
        display: block;
        left: 0;
        position: relative;
        width: 100%;

        @include breakpoint($wk-target-tablet-desktop) {
            position: absolute;
        }
    }

    &.wk-navbar-dropdown-classic {
        #{$wk-navbar-dropdown-parent} {
            left: inherit;
            width: auto;
        }
    }
}




