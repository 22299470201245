/////
/// Navbar dropdown
/// @since 0.18.0
/// @group wk-css.components.navbar-dropdown
/// @author [GPO](https://gpo.wolterskluwer.com/)
/////

@import 'navbar-dropdown.variables';
@import 'navbar-dropdown.mixins';
@import 'navbar-dropdown.placeholders';

.wk-navbar-dropdown-container {
    @extend %wk-navbar-dropdown-container;
    @include wk-navbar-dropdown-container($wk-navbar-dropdown-hover-enabled);
}

.wk-navbar-dropdown {
    @extend %wk-navbar-dropdown;
}

.wk-navbar .wk-nav > li {
    > a:hover,
    > a:focus,
    &.active > a:focus {
        background-color: wk-primary-color(wkBlue, shade1);
    }
}
