////
/// @group wk-css.components.dropdown
/// @author [GPO](https://gpo.wolterskluwer.com/)
/// @since 0.18.0
/// @access public
////

@import 'dropdown.variables';

@include wk-exports('dropdown') {

    %scss-deps-dropdown {
        utils: wk-utils-dependencies();
    }

    .wk-dropdown {
        display: inline-block;
        position: relative;

        .wk-dropdown-toggle {
            padding-right: $wk-dropdown-toggle-padding-right;
            position: relative;

            &::after {
                content: $wk-dropdown-toggle-icon-hex;
                font-family: $wk-dropdown-toggle-icon-font;
                position: absolute;
                right: $wk-dropdown-toggle-icon-right;
                transform: rotate(360deg);
                transition: transform .15s linear;
            }
        }

        .wk-dropdown-menu {
            border: $wk-dropdown-menu-border;
            box-shadow: $wk-dropdown-menu-box-shadow;
            display: none;
            position: absolute;
            top: 100%;
            z-index: $wk-dropdown-menu-z-index;

            &.wk-dropdown-left {
                left: 0;
            }

            &.wk-dropdown-right {
                right: 0;
            }

            &.wk-dropdown-up {
                bottom: 100%;
                top: auto;
            }
        }

        &:hover,
        &.wk-dropdown-open {
            > .wk-dropdown-menu {
                display: block;
            }

            > .wk-dropdown-toggle {
                &::after {
                    transform: rotate(180deg);
                    transition: transform .15s;
                }
            }
        }
    }

    // Default element styling inside dropdown
    // ---------------------------------------
    .wk-dropdown-menu {
        background: $wk-dropdown-menu-bg-color;
        min-width: $wk-dropdown-menu-min-width;
        width: 100%;

        @include breakpoint($wk-target-tablet-desktop) {
            width: auto;
        }

        > ul {
            list-style-type: none;
            margin: 0;
            max-height: $wk-dropdown-menu-ul-max-height;
            overflow-x: hidden;
            overflow-y: auto;
            padding: 0;

            li {
                min-width: $wk-dropdown-menu-li-min-width;

                a {
                    background: $wk-dropdown-menu-li-a-bg;
                    color: $wk-dropdown-menu-li-a-color;
                    display: block;
                    font-family: $wk-dropdown-li-a-font-family;
                    padding: $wk-dropdown-menu-li-a-padding;
                }

                a:hover {
                    background: $wk-dropdown-menu-li-a-bg-hover;
                }
            }
        }

        > dl {
            font-family: $wk-dropdown-menu-dl-font-family;
            max-height: $wk-dropdown-menu-dl-max-height;
            overflow-x: hidden;
            overflow-y: auto;
            width: 100%;

            dt,
            dd {
                display: block;
                min-width: $wk-dropdown-menu-dd-dt-min-width;
                padding: $wk-dropdown-menu-dd-dt-padding;

                a {
                    color: $wk-dropdown-menu-dd-dt-a-color;
                    display: block;
                    padding: $wk-dropdown-menu-dd-dt-a-padding;
                }

                a:hover {
                    background: $wk-dropdown-menu-dd-dt-a-bg-hover;
                }
            }

            dt {
                color: $wk-dropdown-menu-dt-color;
                margin: 0;
                padding-bottom: 0;
            }

            dd {
                margin: 0;
                padding: 0;
            }
        }
    }

    // Component specific overrides
    // ----------------------------
    a.wk-dropdown-toggle {
        padding-right: 30px;
    }

    .wk-header {
        .wk-more-menu {
            &.open {
                background: $wk-dropdown-header-more-menu-bg-color;
            }
        }

        .wk-nav {
            &.open {
                background: none;

                > li > a {
                    background: $wk-dropdown-header-ul-li-a-bg-color;
                    color: $wk-dropdown-header-ul-li-a-color;

                    @include breakpoint($wk-target-tablet-desktop) {
                        background: none;
                        color: $wk-header-nav-color;
                    }
                }

                > li > a:hover {
                    background: $wk-dropdown-header-ul-li-a-hover-bg;

                    @include breakpoint($wk-target-tablet-desktop) {
                        background: none;
                        color: $wk-header-nav-link-color-hover;
                    }
                }
            }

            .wk-dropdown {
                .wk-dropdown-menu {
                    background: none;
                    border: 0;
                    box-shadow: none;
                    position: relative;

                    @include breakpoint($wk-target-tablet-desktop) {
                        position: absolute;
                    }
                }

                .wk-dropdown-body {
                    background: $wk-dropdown-header-menu-body-bg-color;
                    min-width: $wk-dropdown-header-menu-body-min-width;
                    padding: $wk-dropdown-header-menu-body-padding;
                }

                .wk-dropdown-toggle {
                    padding-right: 30px;
                }
            }

            .wk-dropdown:hover,
            .wk-dropdown.wk-dropdown-open {
                background: none;

                > .wk-dropdown-toggle {
                    background: $wk-dropdown-header-open-toggle-bg-color;
                    color: $wk-dropdown-header-open-toggle-color;
                }
            }

            .wk-dropdown-menu {
                a {
                    background: $wk-dropdown-header-ul-li-a-bg-color;
                    color: $wk-dropdown-header-ul-li-a-color;
                    padding: wk-rem(10px 15px);
                }

                a:hover {
                    background: $wk-dropdown-header-ul-li-a-hover-bg;
                }
            }
        }
    }

    button.wk-dropdown-toggle {
        margin-bottom: 0;
        padding-right: 30px;
    }
}
