////
/// @group wk-css.components.icons
/// @author [GPO](https://gpo.wolterskluwer.com/)
/// @since 0.8.0
/// @access public
////

/// Style the wk-icons HTML element with this selector mixin
///
/// @example scss - Usage
///  @include wk-target-icons-element {
///      display: block;
///  }
///
/// @example css - Output
///  [class^='wk-icon-'],
///  [class*=' wk-icon-'] {
///      display: block;
///  }
@mixin wk-target-icons-element {
    [class^='wk-icon-'],
    [class*=' wk-icon-'] {
        @content;
    }
}

/// Style wk-icons with this selector mixin
///
/// @example scss - Usage
///  @include wk-target-icons {
///      font-size: 10rem;
///  }
///
/// @example css - Output
///  [class^='wk-icon-']::before,
///  [class*=' wk-icon-']::before {
///      font-size: 10rem;
///  }
@mixin wk-target-icons {
    [class^='wk-icon-']::before,
    [class*=' wk-icon-']::before {
        @content;
    }
}

