////
/// @group wk-css.components.list
/// @author [GPO](https://gpo.wolterskluwer.com/)
/// @since 0.12.0
/// @access public
////

@import 'list.variables';

@include wk-exports('list') {

    %scss-deps-list {
        utils: wk-utils-dependencies(
            function gutter,
            function wk-primary-color,
            function wk-rem-value,
            variable wk-font-stacks
        );
    }

    .wk-list {
        background-color: $wk-list-background-color;
        border: 1px solid $wk-list-border-color;
        border-radius: $wk-list-border-radius;
        font-family: map-get($wk-font-stacks, global-sans);
        list-style-type: none;
        padding: ($wk-list-spacing / 2) 0;

        > li,
        dd,
        dt {
            padding: $wk-list-item-spacing $wk-list-spacing;
        }

        > li,
        dd {
            &:hover {
                background-color: $wk-list-hover-background;
                cursor: pointer;
            }

            &:focus,
            &.wk-active {
                background-color: $wk-list-focus-background;
                color: $wk-list-focus-color;
            }
        }

        > dt {
            color: $wk-list-title-color;
        }

        > dd,
        li {
            margin-left: 0;
        }
    }

}
