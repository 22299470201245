////
/// @group wk-css.components.alerts
/// @author [GPO](https://gpo.wolterskluwer.com/)
/// @since 0.17.0
/// @access public
////

%wk-alert {
    @include wk-has-context(true);

    background-color: $wk-alert-bg-color;
    border-color: $wk-alert-border-color;
    border-style: $wk-alert-border-style;
    border-width: $wk-alert-border-width;
    color: $wk-alert-color;
    font-family: $wk-alert-font-family;
    font-style: $wk-alert-font-style;
    margin-bottom: $wk-alert-margin-bottom;
    padding: $wk-alert-padding;

    a {
        font-weight: map-get($wk-font-weight, bold);

        &:hover {
            text-decoration: underline;
        }
    }

    .wk-alert-title {
        font-weight: map-get($wk-font-weight, bold);
    }

    .wk-alert-title,
    > p {
        margin-bottom: wk-rem(5px);
    }
}
