////
/// @group wk-css.components.labels
/// @author [GPO](https://gpo.wolterskluwer.com/)
/// @since 0.9.0
/// @access public
////

@import 'labels.mixins';

/// The background color of the label
///
/// @type Color
$wk-label-default-background: wk-primary-color(wkGray, tint1) !default;

/// The text color of the label
///
/// @type Color
$wk-label-default-color: wk-primary-color(wkWhite) !default;

/// The font family of the label
///
/// @type Unit
$wk-label-font-family: map-get($wk-font-stacks, global-sans) !default;

/// The font size of the label
///
/// @type Unit
$wk-label-font-size: wk-rem(12px) !default;

/// The padding of the label
///
/// @type Unit
$wk-label-padding: wk-rem(5px 10px) !default;

@include wk-exports('labels') {

    %scss-deps-labels {
        utils: wk-utils-dependencies(
            variable wk-font-weight,
            function wk-primary-color,
            mixin wk-has-context
        );
    }

    %wk-label {
        @include wk-has-context;

        display: inline-block;
        font-family: $wk-label-font-family;
        font-size: $wk-label-font-size;
        font-weight: map-get($wk-font-weight, medium);
        padding: $wk-label-padding;
        text-transform: uppercase;
        vertical-align: middle;
        white-space: nowrap;
    }

    .wk-label {
        @extend %wk-label;

        background-color: $wk-label-default-background;
        color: $wk-label-default-color;
    }

    .wk-label-info {
        @extend %wk-label;
        @include wk-context;
    }

    .wk-label-success {
        @extend %wk-label;
        @include wk-context(success);
    }

    .wk-label-danger {
        @extend %wk-label;
        @include wk-context(danger);
    }
}
