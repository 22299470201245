////
/// @group wk-css.lib.post-normalize
/// @author [GPO](https://gpo.wolterskluwer.com/)
/// @since 0.1.0
/// @access public
////

@import 'wk-components/type/type.mixins';

// Back to ie5 times
html {
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

@include wk-headings {
    margin-bottom: .3em;
    margin-top: 1em;
}

input[type='search'] {
    //override normalize's content-box
    box-sizing: border-box;
}

input:not([type='radio']):not([type='checkbox']),
textarea,
select {
    border-radius: 0; //reset for safari, chrome, and iOS
}

button {
    background: none; //they all have different colors and even gradients
}

img {
    // make images responsive by default
    height: auto;
    max-width: 100%;
}
