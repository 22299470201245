////
/// @group wk-css.components.footer
/// @author [GPO](https://gpo.wolterskluwer.com/)
/// @since 0.3.0
/// @access public
////
@import 'footer.variables';

@include wk-exports('footer') {

    %scss-deps-footer {
        components: wk-component-dependencies(
            layout,
            toolbelt
        );
        utils: wk-utils-dependencies(
            function wk-primary-color,
            function wk-rem,
            mixin breakpoint,
            mixin container,
            mixin wk-page-content,
            mixin wk-viewport-insulate,
            variable wk-font-scale,
            variable wk-font-weight,
            variable wk-headings-font-stack,
            variable wk-target-desktop,
            variable wk-target-tablet-desktop
        );
    }

    .wk-footer {
        background-color: $wk-footer-background-color;
        margin-top: wk-rem(28px);

        .wk-footer-container {
            @include container;
            @include wk-viewport-insulate;
            padding-bottom: wk-rem(30px);
            padding-top: wk-rem(30px);
        }

        .wk-footer-nav {
            @include wk-clearfix;

            @include breakpoint($wk-target-tablet-desktop) {
                @include wk-layout-25-25-25-25('.wk-footer-nav-list');

                margin-bottom: wk-rem(40px);
                width: auto;
            }

            .wk-footer-nav-list {
                margin-bottom: wk-rem(40px);

                @include breakpoint($wk-target-tablet-desktop) {
                    margin-bottom: 0;
                }

                .wk-footer-heading {
                    color: $wk-footer-heading-color;
                    font-size: map-get($wk-font-scale, base);
                    font-weight: map-get($wk-font-weight, regular);
                    margin-bottom: wk-rem(16px);
                    margin-top: 0;

                    @include breakpoint($wk-target-tablet-desktop) {
                        font-size: map-get($wk-font-scale, smaller);
                        margin-bottom: wk-rem(8px);
                    }
                }

                > ul {
                    list-style: none;
                    padding: 0;

                    // scss-lint:disable NestingDepth,SelectorDepth
                    > li {
                        color: $wk-footer-nav-list-color;
                        font-family: $wk-headings-font-stack;
                        font-size: map-get($wk-font-scale, base);
                        font-weight: map-get($wk-font-weight, regular);
                        margin-bottom: wk-rem(16px);

                        @include breakpoint($wk-target-tablet-desktop) {
                            font-size: map-get($wk-font-scale, smaller);
                            margin-bottom: wk-rem(8px);
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }

                        > a {
                            color: $wk-footer-nav-link-color;
                            display: block;

                            @include breakpoint($wk-target-desktop) {
                                display: inline;
                            }

                            &:hover {
                                color: $wk-footer-nav-link-color-hover;
                            }
                        }
                    }
                    // scss-lint:enable NestingDepth,SelectorDepth
                }
            }
        }

        .wk-logo {
            @include breakpoint($wk-target-desktop) {
                float: $wk-footer-wk-logo-float;
            }
        }

        .wk-tagline {
            color: $wk-footer-tagline-color;
            font-family: $wk-headings-font-stack;
            font-size: map-get($wk-font-scale, smaller);
            font-weight: map-get($wk-font-weight, light);
            margin-top: wk-rem(28px);

            @include breakpoint($wk-target-desktop) {
                float: right;
                font-size: map-get($wk-font-scale, bigger);
                margin-top: 0;
                position: relative;
                top: 7px;
            }
        }

        .wk-social-icons-container {

            li {
                display: inline-block;
            }

            .wk-social-item {
                font-size: $wk-footer-social-icons-size;
                margin-right: $wk-footer-social-icons-margin;

                span {
                    height: 1px;
                    overflow: hidden;
                    position: absolute;
                    top: -10px;
                    width: 1px;
                }
            }
        }

        .wk-footer-copyright {
            background-color: $wk-footer-copyright-background;
            color: $wk-footer-copyright-color;
            font-family: $wk-headings-font-stack;
            font-size: map-get($wk-font-scale, smallest);

            .wk-footer-copyright-container {
                @include container;
                @include wk-viewport-insulate;
                padding-bottom: wk-rem(20px);
                padding-top: wk-rem(20px);
            }
        }

        .wk-footer-meta-info {
            padding-left: 0;

            li {
                display: inline;
                list-style-type: none;
                margin-right: $wk-footer-meta-info-li-margin;
                padding-left: 0;
            }

            a {
                color: $wk-footer-copyright-color;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
