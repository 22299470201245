////
/// @group wk-css.components.breakpoint-tag
/// @author [GPO](https://gpo.wolterskluwer.com/)
/// @since 0.7.0
/// @access public
////

@import 'breakpoint-tag.variables';

@include wk-exports('breakpoint-tag') {

    %scss-deps-breakpoint-tag {
        utils: wk-utils-dependencies(
            function wk-primary-color,
            variable wk-target-tablet,
            variable wk-target-desktop,
            variable wk-desktop-max-width,
            variable wk-z-index
        );
    }

    .wk-breakpoint-tag {
        background-color: $wk-breakpoint-tag-color;
        bottom: 10px;
        color: wk-primary-color(wkGray);
        opacity: .3;
        padding: 6px;
        position: fixed;
        right: 10px;
        text-shadow: $wk-breakpoint-text-shadow;
        z-index: $wk-breakpoint-tag-z-index;

        &::before {
            content: 'mobile';

            @include breakpoint($wk-target-tablet) {
                content: 'tablet';
            }

            @include breakpoint($wk-target-desktop) {
                content: 'desktop';
            }

            @include breakpoint($wk-desktop-max-width) {
                content: 'desktop (max ' + $wk-desktop-max-width + ')';
            }
        }
    }
}
