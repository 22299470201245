////
/// @group wk-css.components.alerts
/// @author [GPO](https://gpo.wolterskluwer.com/)
/// @since 0.7.0
/// @access public
////

@import 'alerts.variables';
@import 'alerts.placeholders';

@include wk-exports('alerts') {

    %scss-deps-alerts {
        utils: wk-utils-dependencies(
            function wk-rem,
            mixin wk-transition,
            mixin wk-has-context
        );
    }

    .wk-alert {
        @extend %wk-alert;
    }

    .wk-alert-info {
        @extend %wk-alert;
        @include wk-context(info, true);
    }

    .wk-alert-danger {
        @extend %wk-alert;
        @include wk-context(danger, true);
    }

    .wk-alert-success {
        @extend %wk-alert;
        @include wk-context(success, true);
    }

    .wk-alert-dismissible,
    [wk-alert-dismissible] {
        padding-right: wk-rem(46px);
        position: relative;

        .wk-icon-close-empty {
            @include wk-transition(opacity, .2s);
            background-color: transparent;
            border: 0;
            border-radius: 0;
            color: inherit;
            display: inline-block;
            font-size: wk-rem(38px);
            margin: 0;
            padding: 0;
            position: absolute;
            right: wk-rem(4px);
            top: wk-rem(4px);
            width: auto;
        }

        .wk-icon-close-empty::before {
            margin: 0;
        }

        .wk-icon-close-empty:hover {
            background-color: transparent;
            color: inherit;
            opacity: .6;
        }
    }
}
