////
/// @group wk-css.components.popover
/// @author [GPO](https://gpo.wolterskluwer.com/)
/// @since 0.11.0
/// @access public
////

@import 'popover.variables';

@include wk-exports('popover') {

    %scss-deps-pagination {
        components: wk-component-dependencies(tooltip);
        utils: wk-utils-dependencies(
            function wk-primary-color,
            mixin wk-tooltip
        );
    }

    .wk-popover {
        @include wk-tooltip($wk-popover-background-color, $wk-popover-border, $wk-popover-border-color, $wk-popover-box-shadow);

        max-width: $wk-popover-max-width;
        position: absolute;

        .wk-popover-body {
            padding: $wk-popover-body-padding;
        }
    }

    .wk-user-popover {

        .wk-user-popover-section {
            border-bottom: 1px solid $wk-popover-border-color;
            padding: $wk-popover-body-padding;

            &:nth-child(even) {
                background: $wk-user-popover-even-background;
            }

            &:last-child {
                border-bottom: 0;
            }

            .wk-module-heading {
                margin: 0;
                margin-bottom: 10px;
            }
        }
    }
}
