/**
 * WK CSS - WK CSS framework provided by the GPO.
 * @version v0.19.2
 * @revision 121efb6
 * @build January 17, 2017 15:06:58
 * @copyright Copyright © 2017, Wolters Kluwer. All rights reserved.
 */
@charset "UTF-8";
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: .67em 0; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -.5em; }

sub {
  bottom: -.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: .35em .625em .75em; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

html {
  box-sizing: border-box; }

*,
*::before,
*::after {
  box-sizing: inherit; }

h1,
h2,
h3,
h4,
h5,
h6,
.wk-h1,
.wk-h2,
.wk-h3,
.wk-h4,
.wk-h5,
.wk-h6 {
  margin-bottom: .3em;
  margin-top: 1em; }

input[type='search'] {
  box-sizing: border-box; }

input:not([type='radio']):not([type='checkbox']),
textarea,
select {
  border-radius: 0; }

button {
  background: none; }

img {
  height: auto;
  max-width: 100%; }

body {
  background-color: #fff; }

body,
html {
  color: #474747;
  font-family: "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
  font-size: 16px;
  line-height: 1.4; }

blockquote,
cite,
address,
p,
ul,
ol,
dl {
  font-size: 0.9375rem;
  margin-bottom: 1em;
  margin-top: 0; }
  blockquote:last-child,
  cite:last-child,
  address:last-child,
  p:last-child,
  ul:last-child,
  ol:last-child,
  dl:last-child {
    margin-bottom: 0; }

small {
  font-size: 0.9375rem; }

::-moz-selection {
  background-color: #d4e8b1; }

::selection {
  background-color: #d4e8b1; }

h1,
h2,
h3,
h4,
h5,
h6,
.wk-h1,
.wk-h2,
.wk-h3,
.wk-h4,
.wk-h5,
.wk-h6 {
  color: #474747;
  font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
  margin-bottom: 0.25rem;
  margin-top: 1rem; }
  h1.subset,
  h2.subset,
  h3.subset,
  h4.subset,
  h5.subset,
  h6.subset,
  .wk-h1.subset,
  .wk-h2.subset,
  .wk-h3.subset,
  .wk-h4.subset,
  .wk-h5.subset,
  .wk-h6.subset {
    font-weight: 200; }

h1,
.wk-h1 {
  font-size: 2.0736rem;
  font-weight: 700; }

h2,
.wk-h2 {
  font-size: 1.728rem;
  font-weight: 700; }

h3,
.wk-h3 {
  font-size: 1.44rem;
  font-weight: 700; }

h4,
.wk-h4 {
  font-size: 1.2rem;
  font-weight: 600; }

h5,
.wk-h5 {
  font-size: 1rem;
  font-weight: 400; }

h6,
.wk-h6 {
  font-size: 1rem;
  font-weight: 300; }

a {
  color: #007ac3;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.1s ease-out; }
  a:hover {
    color: #85bc20; }

code {
  background-color: #f6f6f6;
  border: 1px solid #ededed;
  border-radius: 0;
  color: #474747;
  padding: .1rem .4rem; }

code,
pre {
  font-family: "DejaVu Sans Mono", Consolas, Menlo, Monaco, "Lucida Console", "Bitstream Vera Sans Mono", "Courier New", monospace;
  font-size: 0.8125rem; }

pre code {
  background-color: transparent;
  border: 0; }

.wk-code-block {
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word; }

blockquote {
  border-left: 2px solid #bfbfbf;
  margin: 0 0 1.2rem;
  padding: 1rem 1.4rem 0; }
  blockquote cite {
    display: block; }
    blockquote cite::before {
      content: '— '; }

.wk-alert, .wk-alert-info, .wk-alert-danger, .wk-alert-success {
  background-color: #fff;
  border-color: #474747;
  border-style: solid;
  border-width: 1px;
  color: #474747;
  font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
  font-style: italic;
  margin-bottom: 1.25rem;
  padding: 0.75rem 1rem; }
  .wk-context-info.wk-alert, .wk-context-info.wk-alert-info, .wk-context-info.wk-alert-danger, .wk-context-info.wk-alert-success, [wk-context*='info'].wk-alert, [wk-context*='info'].wk-alert-info, [wk-context*='info'].wk-alert-danger, [wk-context*='info'].wk-alert-success {
    background-color: #fff;
    border-color: #007ac3;
    color: #007ac3; }
  .wk-context-success.wk-alert, .wk-context-success.wk-alert-info, .wk-context-success.wk-alert-danger, .wk-context-success.wk-alert-success, [wk-context*='success'].wk-alert, [wk-context*='success'].wk-alert-info, [wk-context*='success'].wk-alert-danger, [wk-context*='success'].wk-alert-success {
    background-color: #fff;
    border-color: #85bc20;
    color: #85bc20; }
  .wk-context-danger.wk-alert, .wk-context-danger.wk-alert-info, .wk-context-danger.wk-alert-danger, .wk-context-danger.wk-alert-success, [wk-context*='danger'].wk-alert, [wk-context*='danger'].wk-alert-info, [wk-context*='danger'].wk-alert-danger, [wk-context*='danger'].wk-alert-success {
    background-color: #fff;
    border-color: #e5202e;
    color: #e5202e; }
  .wk-alert a, .wk-alert-info a, .wk-alert-danger a, .wk-alert-success a {
    font-weight: 700; }
    .wk-alert a:hover, .wk-alert-info a:hover, .wk-alert-danger a:hover, .wk-alert-success a:hover {
      text-decoration: underline; }
  .wk-alert .wk-alert-title, .wk-alert-info .wk-alert-title, .wk-alert-danger .wk-alert-title, .wk-alert-success .wk-alert-title {
    font-weight: 700; }
  .wk-alert .wk-alert-title, .wk-alert-info .wk-alert-title, .wk-alert-danger .wk-alert-title, .wk-alert-success .wk-alert-title, .wk-alert > p, .wk-alert-info > p, .wk-alert-danger > p, .wk-alert-success > p {
    margin-bottom: 0.3125rem; }

.wk-alert-info {
  background-color: #fff;
  border-color: #007ac3;
  color: #007ac3; }

.wk-alert-danger {
  background-color: #fff;
  border-color: #e5202e;
  color: #e5202e; }

.wk-alert-success {
  background-color: #fff;
  border-color: #85bc20;
  color: #85bc20; }

.wk-alert-dismissible,
[wk-alert-dismissible] {
  padding-right: 2.875rem;
  position: relative; }
  .wk-alert-dismissible .wk-icon-close-empty,
  [wk-alert-dismissible] .wk-icon-close-empty {
    transition: opacity 0.2s ease-out;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    color: inherit;
    display: inline-block;
    font-size: 2.375rem;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0.25rem;
    top: 0.25rem;
    width: auto; }
  .wk-alert-dismissible .wk-icon-close-empty::before,
  [wk-alert-dismissible] .wk-icon-close-empty::before {
    margin: 0; }
  .wk-alert-dismissible .wk-icon-close-empty:hover,
  [wk-alert-dismissible] .wk-icon-close-empty:hover {
    background-color: transparent;
    color: inherit;
    opacity: .6; }

.wk-spin {
  -webkit-animation: spin 2s infinite linear;
          animation: spin 2s infinite linear;
  display: inline-block; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg); } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg); } }

.wk-badge,
[wk-badge] {
  background-color: #007ac3;
  border-radius: 1rem;
  color: #fff;
  font-size: 0.77248em;
  font-weight: 700;
  padding: 0.125rem 0.5rem;
  position: relative;
  text-align: center;
  top: -.1em;
  white-space: nowrap; }
  button .wk-badge,
  .wk-button .wk-badge,
  .wk-button-full .wk-badge, button
  [wk-badge],
  .wk-button
  [wk-badge],
  .wk-button-full
  [wk-badge] {
    background-color: #fff;
    border-radius: 1rem;
    color: #007ac3;
    font-size: 0.77248em;
    font-weight: 700;
    padding: 0.125rem 0.5rem;
    position: relative;
    text-align: center;
    top: -.1em;
    white-space: nowrap; }
  .wk-badge.wk-badge-grey, .wk-badge[wk-badge*='grey'],
  [wk-badge].wk-badge-grey,
  [wk-badge][wk-badge*='grey'] {
    background-color: #bfbfbf;
    border-radius: 1rem;
    color: #fff;
    font-size: 0.77248em;
    font-weight: 700;
    padding: 0.125rem 0.5rem;
    position: relative;
    text-align: center;
    top: -.1em;
    white-space: nowrap; }

.wk-layout-50-50 {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto; }
  .wk-layout-50-50:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 37.5rem) {
    .wk-layout-50-50 > .wk-layout-item {
      width: 49.15254%;
      float: left;
      margin-right: 1.69492%; }
      .wk-layout-50-50 > .wk-layout-item:nth-child(2n) {
        float: right;
        margin-right: 0; } }

.wk-layout-50-50-persist {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto; }
  .wk-layout-50-50-persist:after {
    content: " ";
    display: block;
    clear: both; }
  .wk-layout-50-50-persist > .wk-layout-item {
    width: 49.15254%;
    float: left;
    margin-right: 1.69492%; }
    .wk-layout-50-50-persist > .wk-layout-item:nth-child(2n) {
      float: right;
      margin-right: 0; }

.wk-layout-33-66 {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto; }
  .wk-layout-33-66:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 37.5rem) {
    .wk-layout-33-66 > .wk-layout-item {
      width: 32.20339%;
      float: left;
      margin-right: 1.69492%; }
      .wk-layout-33-66 > .wk-layout-item:nth-child(2n) {
        width: 66.10169%;
        float: left;
        margin-right: 1.69492%;
        float: right;
        margin-right: 0; } }

.wk-layout-33-66-persist {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto; }
  .wk-layout-33-66-persist:after {
    content: " ";
    display: block;
    clear: both; }
  .wk-layout-33-66-persist > .wk-layout-item {
    width: 32.20339%;
    float: left;
    margin-right: 1.69492%; }
    .wk-layout-33-66-persist > .wk-layout-item:nth-child(2n) {
      width: 66.10169%;
      float: left;
      margin-right: 1.69492%;
      float: right;
      margin-right: 0; }

.wk-layout-66-33 {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto; }
  .wk-layout-66-33:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 37.5rem) {
    .wk-layout-66-33 > .wk-layout-item {
      width: 66.10169%;
      float: left;
      margin-right: 1.69492%; }
      .wk-layout-66-33 > .wk-layout-item:nth-child(2n) {
        width: 32.20339%;
        float: left;
        margin-right: 1.69492%;
        float: right;
        margin-right: 0; } }

.wk-layout-66-33-persist {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto; }
  .wk-layout-66-33-persist:after {
    content: " ";
    display: block;
    clear: both; }
  .wk-layout-66-33-persist > .wk-layout-item {
    width: 66.10169%;
    float: left;
    margin-right: 1.69492%; }
    .wk-layout-66-33-persist > .wk-layout-item:nth-child(2n) {
      width: 32.20339%;
      float: left;
      margin-right: 1.69492%;
      float: right;
      margin-right: 0; }

.wk-layout-25-75 {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto; }
  .wk-layout-25-75:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 37.5rem) {
    .wk-layout-25-75 > .wk-layout-item {
      width: 23.72881%;
      float: left;
      margin-right: 1.69492%; }
      .wk-layout-25-75 > .wk-layout-item:nth-child(2n) {
        width: 74.57627%;
        float: left;
        margin-right: 1.69492%;
        float: right;
        margin-right: 0; } }

.wk-layout-25-75-persist {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto; }
  .wk-layout-25-75-persist:after {
    content: " ";
    display: block;
    clear: both; }
  .wk-layout-25-75-persist > .wk-layout-item {
    width: 23.72881%;
    float: left;
    margin-right: 1.69492%; }
    .wk-layout-25-75-persist > .wk-layout-item:nth-child(2n) {
      width: 74.57627%;
      float: left;
      margin-right: 1.69492%;
      float: right;
      margin-right: 0; }

.wk-layout-75-25 {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto; }
  .wk-layout-75-25:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 37.5rem) {
    .wk-layout-75-25 > .wk-layout-item {
      width: 74.57627%;
      float: left;
      margin-right: 1.69492%; }
      .wk-layout-75-25 > .wk-layout-item:nth-child(2n) {
        width: 23.72881%;
        float: left;
        margin-right: 1.69492%;
        float: right;
        margin-right: 0; } }

.wk-layout-75-25-persist {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto; }
  .wk-layout-75-25-persist:after {
    content: " ";
    display: block;
    clear: both; }
  .wk-layout-75-25-persist > .wk-layout-item {
    width: 74.57627%;
    float: left;
    margin-right: 1.69492%; }
    .wk-layout-75-25-persist > .wk-layout-item:nth-child(2n) {
      width: 23.72881%;
      float: left;
      margin-right: 1.69492%;
      float: right;
      margin-right: 0; }

.wk-layout-33-33-33 {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto; }
  .wk-layout-33-33-33:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 37.5rem) {
    .wk-layout-33-33-33 > .wk-layout-item {
      width: 32.20339%;
      float: left;
      margin-right: 1.69492%; }
      .wk-layout-33-33-33 > .wk-layout-item:nth-child(3n) {
        float: right;
        margin-right: 0; } }

.wk-layout-33-33-33-persist {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto; }
  .wk-layout-33-33-33-persist:after {
    content: " ";
    display: block;
    clear: both; }
  .wk-layout-33-33-33-persist > .wk-layout-item {
    width: 32.20339%;
    float: left;
    margin-right: 1.69492%; }
    .wk-layout-33-33-33-persist > .wk-layout-item:nth-child(3n) {
      float: right;
      margin-right: 0; }

.wk-layout-25-25-25-25 {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto; }
  .wk-layout-25-25-25-25:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 37.5rem) {
    .wk-layout-25-25-25-25 > .wk-layout-item {
      width: 23.72881%;
      float: left;
      margin-right: 1.69492%; }
      .wk-layout-25-25-25-25 > .wk-layout-item:nth-child(4n) {
        float: right;
        margin-right: 0; } }

.wk-layout-25-25-25-25-persist {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto; }
  .wk-layout-25-25-25-25-persist:after {
    content: " ";
    display: block;
    clear: both; }
  .wk-layout-25-25-25-25-persist > .wk-layout-item {
    width: 23.72881%;
    float: left;
    margin-right: 1.69492%; }
    .wk-layout-25-25-25-25-persist > .wk-layout-item:nth-child(4n) {
      float: right;
      margin-right: 0; }

.wk-layout-push-25 {
  margin-left: 25.42373%; }

.wk-layout-push-33 {
  margin-left: 33.89831%; }

.wk-layout-centered-small {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto; }
  .wk-layout-centered-small:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 37.5rem) {
    .wk-layout-centered-small {
      max-width: 28.125rem; } }

.wk-layout-centered-medium {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 37.5rem; }
  .wk-layout-centered-medium:after {
    content: " ";
    display: block;
    clear: both; }

.wk-layout-centered-large {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto; }
  .wk-layout-centered-large:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 37.5rem) and (max-width: 65rem) {
    .wk-layout-centered-large {
      max-width: 37.5rem; } }
  @media (min-width: 65rem) {
    .wk-layout-centered-large {
      max-width: 65rem; } }

@font-face {
  font-family: "wk-icons";
  src: url("fonts/wk-icons/font/wk-icons.eot?") format("eot"), url("fonts/wk-icons/font/wk-icons.woff") format("woff"), url("fonts/wk-icons/font/wk-icons.ttf") format("truetype"); }

[class^='wk-icon-'],
[class*=' wk-icon-'] {
  display: inline-block; }

[class^='wk-icon-']::before,
[class*=' wk-icon-']::before, .wk-table .wk-table-ascent::after,
.wk-table .wk-table-descent::after {
  display: inline-block;
  font-family: 'wk-icons';
  font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1em;
  margin-left: .2em;
  margin-right: .2em;
  speak: none;
  text-align: center;
  text-decoration: inherit;
  text-transform: none;
  width: 1em; }

.wk-icon-alert-triangle:before {
  content: '\e800'; }

/* '' */
.wk-icon-alert:before {
  content: '\e801'; }

/* '' */
.wk-icon-angle-down:before {
  content: '\e802'; }

/* '' */
.wk-icon-angle-left:before {
  content: '\e803'; }

/* '' */
.wk-icon-angle-right:before {
  content: '\e804'; }

/* '' */
.wk-icon-angle-up:before {
  content: '\e805'; }

/* '' */
.wk-icon-apps:before {
  content: '\e806'; }

/* '' */
.wk-icon-arrow-back:before {
  content: '\e807'; }

/* '' */
.wk-icon-arrow-down:before {
  content: '\e808'; }

/* '' */
.wk-icon-arrow-forward:before {
  content: '\e809'; }

/* '' */
.wk-icon-arrow-left:before {
  content: '\e80a'; }

/* '' */
.wk-icon-arrow-right:before {
  content: '\e80b'; }

/* '' */
.wk-icon-arrow-thin-down:before {
  content: '\e80c'; }

/* '' */
.wk-icon-arrow-thin-left:before {
  content: '\e80d'; }

/* '' */
.wk-icon-arrow-thin-right:before {
  content: '\e80e'; }

/* '' */
.wk-icon-arrow-thin-up:before {
  content: '\e80f'; }

/* '' */
.wk-icon-arrow-up:before {
  content: '\e810'; }

/* '' */
.wk-icon-at-outline:before {
  content: '\e811'; }

/* '' */
.wk-icon-at:before {
  content: '\e812'; }

/* '' */
.wk-icon-attach:before {
  content: '\e813'; }

/* '' */
.wk-icon-bell-outline:before {
  content: '\e814'; }

/* '' */
.wk-icon-bell:before {
  content: '\e815'; }

/* '' */
.wk-icon-book-outline:before {
  content: '\e816'; }

/* '' */
.wk-icon-book:before {
  content: '\e817'; }

/* '' */
.wk-icon-bookmarks-outline:before {
  content: '\e818'; }

/* '' */
.wk-icon-bookmarks:before {
  content: '\e819'; }

/* '' */
.wk-icon-box-outline:before {
  content: '\e81a'; }

/* '' */
.wk-icon-box:before {
  content: '\e81b'; }

/* '' */
.wk-icon-briefcase-outline:before {
  content: '\e81c'; }

/* '' */
.wk-icon-briefcase:before {
  content: '\e81d'; }

/* '' */
.wk-icon-calculator-outline:before {
  content: '\e81e'; }

/* '' */
.wk-icon-calculator:before {
  content: '\e81f'; }

/* '' */
.wk-icon-calendar-outline:before {
  content: '\e820'; }

/* '' */
.wk-icon-calendar:before {
  content: '\e821'; }

/* '' */
.wk-icon-cart-outline:before {
  content: '\e822'; }

/* '' */
.wk-icon-cart:before {
  content: '\e823'; }

/* '' */
.wk-icon-chatboxes-outline:before {
  content: '\e824'; }

/* '' */
.wk-icon-chatboxes:before {
  content: '\e825'; }

/* '' */
.wk-icon-chatbubble-outline:before {
  content: '\e826'; }

/* '' */
.wk-icon-chatbubble:before {
  content: '\e827'; }

/* '' */
.wk-icon-checkmark-empty:before {
  content: '\e828'; }

/* '' */
.wk-icon-checkmark-outline:before {
  content: '\e829'; }

/* '' */
.wk-icon-checkmark:before {
  content: '\e82a'; }

/* '' */
.wk-icon-chevron-down:before {
  content: '\e82b'; }

/* '' */
.wk-icon-chevron-left:before {
  content: '\e82c'; }

/* '' */
.wk-icon-chevron-right:before {
  content: '\e82d'; }

/* '' */
.wk-icon-chevron-up:before {
  content: '\e82e'; }

/* '' */
.wk-icon-circle-filled:before {
  content: '\e82f'; }

/* '' */
.wk-icon-circle-outline:before {
  content: '\e830'; }

/* '' */
.wk-icon-clock-outline:before {
  content: '\e831'; }

/* '' */
.wk-icon-clock:before {
  content: '\e832'; }

/* '' */
.wk-icon-close-empty:before {
  content: '\e833'; }

/* '' */
.wk-icon-close-outline:before {
  content: '\e834'; }

/* '' */
.wk-icon-close:before {
  content: '\e835'; }

/* '' */
.wk-icon-cloud-download-outline:before {
  content: '\e836'; }

/* '' */
.wk-icon-cloud-download:before {
  content: '\e837'; }

/* '' */
.wk-icon-cloud-outline:before {
  content: '\e838'; }

/* '' */
.wk-icon-cloud-upload-outline:before {
  content: '\e839'; }

/* '' */
.wk-icon-cloud-upload:before {
  content: '\e83a'; }

/* '' */
.wk-icon-cloud:before {
  content: '\e83b'; }

/* '' */
.wk-icon-compose-outline:before {
  content: '\e83c'; }

/* '' */
.wk-icon-compose:before {
  content: '\e83d'; }

/* '' */
.wk-icon-contact-outline:before {
  content: '\e83e'; }

/* '' */
.wk-icon-contact:before {
  content: '\e83f'; }

/* '' */
.wk-icon-copy-outline:before {
  content: '\e840'; }

/* '' */
.wk-icon-copy:before {
  content: '\e841'; }

/* '' */
.wk-icon-download-outline:before {
  content: '\e842'; }

/* '' */
.wk-icon-download:before {
  content: '\e843'; }

/* '' */
.wk-icon-drag:before {
  content: '\e844'; }

/* '' */
.wk-icon-edit:before {
  content: '\e845'; }

/* '' */
.wk-icon-email-outline:before {
  content: '\e846'; }

/* '' */
.wk-icon-email:before {
  content: '\e847'; }

/* '' */
.wk-icon-facebook-outline:before {
  content: '\e848'; }

/* '' */
.wk-icon-facebook:before {
  content: '\e849'; }

/* '' */
.wk-icon-filing-outline:before {
  content: '\e84a'; }

/* '' */
.wk-icon-filing:before {
  content: '\e84b'; }

/* '' */
.wk-icon-flag-outline:before {
  content: '\e84c'; }

/* '' */
.wk-icon-flag:before {
  content: '\e84d'; }

/* '' */
.wk-icon-folder-outline:before {
  content: '\e84e'; }

/* '' */
.wk-icon-folder:before {
  content: '\e84f'; }

/* '' */
.wk-icon-gear-outline:before {
  content: '\e850'; }

/* '' */
.wk-icon-gear:before {
  content: '\e851'; }

/* '' */
.wk-icon-grid-view-outline:before {
  content: '\e852'; }

/* '' */
.wk-icon-grid-view:before {
  content: '\e853'; }

/* '' */
.wk-icon-heart-outline:before {
  content: '\e854'; }

/* '' */
.wk-icon-heart:before {
  content: '\e855'; }

/* '' */
.wk-icon-help-empty:before {
  content: '\e856'; }

/* '' */
.wk-icon-help-outline:before {
  content: '\e857'; }

/* '' */
.wk-icon-home-outline:before {
  content: '\e858'; }

/* '' */
.wk-icon-home:before {
  content: '\e859'; }

/* '' */
.wk-icon-info-empty:before {
  content: '\e85a'; }

/* '' */
.wk-icon-info-outline:before {
  content: '\e85b'; }

/* '' */
.wk-icon-info:before {
  content: '\e85c'; }

/* '' */
.wk-icon-keypad-outline:before {
  content: '\e85d'; }

/* '' */
.wk-icon-keypad:before {
  content: '\e85e'; }

/* '' */
.wk-icon-levels:before {
  content: '\e85f'; }

/* '' */
.wk-icon-lightbulb-outline:before {
  content: '\e860'; }

/* '' */
.wk-icon-lightbulb:before {
  content: '\e861'; }

/* '' */
.wk-icon-linkedin-outline:before {
  content: '\e862'; }

/* '' */
.wk-icon-linkedin:before {
  content: '\e863'; }

/* '' */
.wk-icon-list-outline:before {
  content: '\e864'; }

/* '' */
.wk-icon-list:before {
  content: '\e865'; }

/* '' */
.wk-icon-location-outline:before {
  content: '\e866'; }

/* '' */
.wk-icon-location:before {
  content: '\e867'; }

/* '' */
.wk-icon-locked-outline:before {
  content: '\e868'; }

/* '' */
.wk-icon-locked:before {
  content: '\e869'; }

/* '' */
.wk-icon-log-in:before {
  content: '\e86a'; }

/* '' */
.wk-icon-log-out:before {
  content: '\e86b'; }

/* '' */
.wk-icon-medical-outline:before {
  content: '\e86c'; }

/* '' */
.wk-icon-medical:before {
  content: '\e86d'; }

/* '' */
.wk-icon-medkit-outline:before {
  content: '\e86e'; }

/* '' */
.wk-icon-medkit:before {
  content: '\e86f'; }

/* '' */
.wk-icon-menu:before {
  content: '\e870'; }

/* '' */
.wk-icon-mic-off:before {
  content: '\e871'; }

/* '' */
.wk-icon-mic-outline:before {
  content: '\e872'; }

/* '' */
.wk-icon-mic:before {
  content: '\e873'; }

/* '' */
.wk-icon-minus-empty:before {
  content: '\e874'; }

/* '' */
.wk-icon-minus-outline:before {
  content: '\e875'; }

/* '' */
.wk-icon-minus:before {
  content: '\e876'; }

/* '' */
.wk-icon-more-outline:before {
  content: '\e877'; }

/* '' */
.wk-icon-more:before {
  content: '\e878'; }

/* '' */
.wk-icon-paper-outline:before {
  content: '\e879'; }

/* '' */
.wk-icon-paper:before {
  content: '\e87a'; }

/* '' */
.wk-icon-people-outline:before {
  content: '\e87b'; }

/* '' */
.wk-icon-people:before {
  content: '\e87c'; }

/* '' */
.wk-icon-person-outline:before {
  content: '\e87d'; }

/* '' */
.wk-icon-person:before {
  content: '\e87e'; }

/* '' */
.wk-icon-personadd-outline:before {
  content: '\e87f'; }

/* '' */
.wk-icon-personadd:before {
  content: '\e880'; }

/* '' */
.wk-icon-pie-outline:before {
  content: '\e881'; }

/* '' */
.wk-icon-pie:before {
  content: '\e882'; }

/* '' */
.wk-icon-plus-empty:before {
  content: '\e883'; }

/* '' */
.wk-icon-plus-outline:before {
  content: '\e884'; }

/* '' */
.wk-icon-printer-outline:before {
  content: '\e885'; }

/* '' */
.wk-icon-printer:before {
  content: '\e886'; }

/* '' */
.wk-icon-pulse-strong:before {
  content: '\e887'; }

/* '' */
.wk-icon-pulse:before {
  content: '\e888'; }

/* '' */
.wk-icon-refresh-empty:before {
  content: '\e889'; }

/* '' */
.wk-icon-refresh-outline:before {
  content: '\e88a'; }

/* '' */
.wk-icon-refresh:before {
  content: '\e88b'; }

/* '' */
.wk-icon-reload:before {
  content: '\e88c'; }

/* '' */
.wk-icon-rss-outline:before {
  content: '\e88d'; }

/* '' */
.wk-icon-rss:before {
  content: '\e88e'; }

/* '' */
.wk-icon-search-strong:before {
  content: '\e88f'; }

/* '' */
.wk-icon-search:before {
  content: '\e890'; }

/* '' */
.wk-icon-settings-strong:before {
  content: '\e891'; }

/* '' */
.wk-icon-settings:before {
  content: '\e892'; }

/* '' */
.wk-icon-spin:before, button.wk-loading:before, button[wk-button*='loading']:before, button[wk-loading]:before,
.wk-button.wk-loading:before,
.wk-button[wk-button*='loading']:before,
.wk-button[wk-loading]:before,
[wk-button].wk-loading:before,
[wk-button][wk-button*='loading']:before,
[wk-button][wk-loading]:before {
  content: '\e893'; }

/* '' */
.wk-icon-star-half:before {
  content: '\e894'; }

/* '' */
.wk-icon-star-outline:before {
  content: '\e895'; }

/* '' */
.wk-icon-star:before {
  content: '\e896'; }

/* '' */
.wk-icon-telephone-outline:before {
  content: '\e897'; }

/* '' */
.wk-icon-telephone:before {
  content: '\e898'; }

/* '' */
.wk-icon-thumbs-down:before {
  content: '\e899'; }

/* '' */
.wk-icon-thumbs-up:before {
  content: '\e89a'; }

/* '' */
.wk-icon-trash-outline:before {
  content: '\e89b'; }

/* '' */
.wk-icon-trash:before {
  content: '\e89c'; }

/* '' */
.wk-icon-twitter-outline:before {
  content: '\e89d'; }

/* '' */
.wk-icon-twitter:before {
  content: '\e89e'; }

/* '' */
.wk-icon-undo-outline:before {
  content: '\e89f'; }

/* '' */
.wk-icon-undo:before {
  content: '\e8a0'; }

/* '' */
.wk-icon-unlocked-outline:before {
  content: '\e8a1'; }

/* '' */
.wk-icon-unlocked:before {
  content: '\e8a2'; }

/* '' */
.wk-icon-upload-outline:before {
  content: '\e8a3'; }

/* '' */
.wk-icon-upload:before {
  content: '\e8a4'; }

/* '' */
.wk-icon-wrench:before {
  content: '\e8a5'; }

/* '' */
.wk-icon-youtube-outline:before {
  content: '\e8a6'; }

/* '' */
.wk-icon-youtube:before {
  content: '\e8a7'; }

/* '' */
.wk-icon-file-pdf:before {
  content: '\e8a8'; }

/* '' */
.wk-icon-file-word:before {
  content: '\e8a9'; }

/* '' */
.wk-icon-file-excel:before {
  content: '\e8aa'; }

/* '' */
.wk-icon-funnel:before {
  content: '\e8ab'; }

/* '' */
input[type='radio'],
input[type='checkbox'],
.wk-checkbox,
.wk-radio {
  font-size: 1rem;
  margin-right: 0.3125em; }

input:not([type='radio']):not([type='checkbox']),
.wk-input {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #fff;
  border: 1px solid #dadada;
  border-radius: 0;
  color: #474747;
  font-family: "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
  font-size: 1rem;
  height: 3rem;
  line-height: 1.5;
  padding: 0.6875rem 1rem;
  width: 100%; }
  input:not([type='radio']):not([type='checkbox'])[disabled],
  .wk-input[disabled] {
    background-color: #ededed;
    color: #ededed;
    cursor: not-allowed; }
  input:not([type='radio']):not([type='checkbox']):focus, input:not([type='radio']):not([type='checkbox']):hover:focus,
  .wk-input:focus,
  .wk-input:hover:focus {
    border-color: #409bd2; }
  input:not([type='radio']):not([type='checkbox']):hover,
  .wk-input:hover {
    border-color: #474747; }
  input:not([type='radio']):not([type='checkbox'])::-ms-clear,
  .wk-input::-ms-clear {
    height: 0;
    width: 0; }

:-moz-placeholder,
::-moz-placeholder,
:-ms-input-placeholder,
::-webkit-input-placeholder {
  color: #bfbfbf; }

textarea {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #fff;
  border: 1px solid #dadada;
  border-radius: 0;
  color: #474747;
  font-family: "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
  font-size: 1rem;
  height: 3rem;
  line-height: 1.5;
  padding: 0.6875rem 1rem;
  width: 100%;
  display: block;
  min-height: 5em;
  resize: vertical; }
  textarea[disabled] {
    background-color: #ededed;
    color: #ededed;
    cursor: not-allowed; }
  textarea:focus, textarea:hover:focus {
    border-color: #409bd2; }
  textarea:hover {
    border-color: #474747; }
  textarea::-ms-clear {
    height: 0;
    width: 0; }

.wk-textarea-short {
  min-height: 5em; }

.wk-textarea-medium {
  min-height: 8em; }

.wk-textarea-tall {
  min-height: 12em; }

.wk-textarea-no-resize {
  resize: none; }

.ie9 .wk-select-field::after {
  content: ''; }

.ie9 .wk-select-field > select {
  padding: 0; }

html.no-smil.no-borderimage .wk-select-field::after {
  display: none; }

@media (min-width: 37.5rem) {
  html.no-smil.no-borderimage .wk-select-field > select {
    padding-right: 0.4375rem; } }

html.no-smil.no-borderimage .wk-select-field > select::-ms-expand {
  display: block; }

.wk-select-field {
  position: relative; }
  .wk-select-field::after {
    color: #474747;
    content: '\e82b';
    font-family: 'wk-icons';
    pointer-events: none;
    position: absolute;
    right: .5em;
    top: .8em;
    z-index: 1049; }
  .wk-select-field > select {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background-color: #fff;
    border: 1px solid #dadada;
    border-radius: 0;
    color: #474747;
    font-family: "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
    font-size: 1rem;
    height: 3rem;
    line-height: 1.5;
    padding: 0.6875rem 1rem;
    width: 100%;
    display: block;
    font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
    .wk-select-field > select[disabled] {
      background-color: #ededed;
      color: #ededed;
      cursor: not-allowed; }
    .wk-select-field > select:focus, .wk-select-field > select:hover:focus {
      border-color: #409bd2; }
    .wk-select-field > select:hover {
      border-color: #474747; }
    .wk-select-field > select::-ms-clear {
      height: 0;
      width: 0; }
    @media (min-width: 37.5rem) {
      .wk-select-field > select {
        padding-right: 1.375rem; } }
    .wk-select-field > select::-ms-expand {
      display: none; }
    .wk-select-field > select > option {
      overflow: hidden;
      text-overflow: ellipsis; }
    .wk-select-field > select:focus {
      border-color: #474747; }

.wk-checkbox-field[disabled],
.wk-radio-field[disabled] {
  color: #ededed; }

label {
  font-weight: 400; }

.wk-field-item {
  font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
  margin-bottom: 1rem; }
  .wk-field-item textarea,
  .wk-field-item input:not([type='radio']):not([type='checkbox']),
  .wk-field-item .wk-textarea,
  .wk-field-item .wk-select-field,
  .wk-field-item .wk-radio-field,
  .wk-field-item .wk-checkbox-field,
  .wk-field-item .wk-input {
    font-weight: 300;
    margin-bottom: 0.1875rem; }
  .wk-field-item .wk-field-header {
    font-weight: 400;
    margin-bottom: 0.1875rem; }
    .wk-field-item .wk-field-header > .wk-field-tip {
      color: #a3a3a3;
      float: right;
      font-size: 0.87891rem;
      font-style: italic;
      margin-left: 0.375rem;
      margin-top: .1em; }
  .wk-field-item.wk-field-has-error textarea,
  .wk-field-item.wk-field-has-error input:not([type='radio']):not([type='checkbox']),
  .wk-field-item.wk-field-has-error .wk-select-field > select,
  .wk-field-item.wk-field-has-error .wk-textarea,
  .wk-field-item.wk-field-has-error .wk-input {
    border-color: #e5202e;
    color: #e5202e; }
  .wk-field-item.wk-field-has-error .wk-field-error {
    visibility: visible; }
  .wk-field-item.wk-field-has-error .wk-field-has-icon .wk-field-icon {
    color: #e5202e; }

.wk-field-has-icon {
  position: relative; }
  .wk-field-has-icon input:not([type='radio']):not([type='checkbox']),
  .wk-field-has-icon .wk-input {
    padding-right: 3.1875rem; }
  .wk-field-has-icon .wk-field-icon {
    background-color: transparent;
    padding: 0;
    position: absolute;
    right: 1rem;
    top: 0.8125rem;
    width: auto;
    z-index: 1049; }
    .wk-field-has-icon .wk-field-icon [class^='wk-icon-']::before,
    .wk-field-has-icon .wk-field-icon [class*=' wk-icon-']::before {
      transition: color 0.1s ease-out;
      color: #a3a3a3;
      font-size: 1.375rem;
      margin: 0; }
    .wk-field-has-icon .wk-field-icon:hover {
      background-color: transparent; }
    .wk-field-has-icon .wk-field-icon.wk-field-icon-button:hover [class^='wk-icon-']::before,
    .wk-field-has-icon .wk-field-icon.wk-field-icon-button:hover [class*=' wk-icon-']::before {
      color: #474747; }
    .wk-field-has-icon .wk-field-icon.wk-field-icon-button:focus [class^='wk-icon-']::before,
    .wk-field-has-icon .wk-field-icon.wk-field-icon-button:focus [class*=' wk-icon-']::before {
      color: #474747; }

.wk-field-error {
  color: #e5202e;
  font-size: 0.87891rem;
  font-style: italic;
  visibility: hidden; }
  .wk-field-error::after {
    content: '.';
    visibility: hidden; }

@font-face {
  font-family: "Fira Sans";
  font-weight: 300;
  src: url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FiraSans-Light/FiraSans-Light.eot?") format("eot"), url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FiraSans-Light/FiraSans-Light.woff") format("woff"), url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FiraSans-Light/FiraSans-Light.ttf") format("truetype"); }

@font-face {
  font-family: "Fira Sans";
  font-style: italic;
  font-weight: 300;
  src: url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FiraSans-LightItalic/FiraSans-LightItalic.eot?") format("eot"), url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FiraSans-LightItalic/FiraSans-LightItalic.woff") format("woff"), url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FiraSans-LightItalic/FiraSans-LightItalic.ttf") format("truetype"); }

@font-face {
  font-family: "Fira Sans";
  font-weight: 400;
  src: url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FiraSans-Regular/FiraSans-Regular.eot?") format("eot"), url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FiraSans-Regular/FiraSans-Regular.woff") format("woff"), url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FiraSans-Regular/FiraSans-Regular.ttf") format("truetype"); }

@font-face {
  font-family: "Fira Sans";
  font-style: italic;
  font-weight: 400;
  src: url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FiraSans-Italic/FiraSans-Italic.eot?") format("eot"), url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FiraSans-Italic/FiraSans-Italic.woff") format("woff"), url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FiraSans-Italic/FiraSans-Italic.ttf") format("truetype"); }

@font-face {
  font-family: "Fira Sans";
  font-weight: 500;
  src: url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FiraSans-Medium/FiraSans-Medium.eot?") format("eot"), url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FiraSans-Medium/FiraSans-Medium.woff") format("woff"), url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FiraSans-Medium/FiraSans-Medium.ttf") format("truetype"); }

@font-face {
  font-family: "Fira Sans";
  font-style: italic;
  font-weight: 500;
  src: url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FiraSans-MediumItalic/FiraSans-MediumItalic.eot?") format("eot"), url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FiraSans-MediumItalic/FiraSans-MediumItalic.woff") format("woff"), url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FiraSans-MediumItalic/FiraSans-MediumItalic.ttf") format("truetype"); }

@font-face {
  font-family: "Fira Sans";
  font-weight: 700;
  src: url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FiraSans-Bold/FiraSans-Bold.eot?") format("eot"), url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FiraSans-Bold/FiraSans-Bold.woff") format("woff"), url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FiraSans-Bold/FiraSans-Bold.ttf") format("truetype"); }

@font-face {
  font-family: "Fira Sans";
  font-style: italic;
  font-weight: 700;
  src: url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FiraSans-BoldItalic/FiraSans-BoldItalic.eot?") format("eot"), url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FiraSans-BoldItalic/FiraSans-BoldItalic.woff") format("woff"), url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FiraSans-BoldItalic/FiraSans-BoldItalic.ttf") format("truetype"); }

@font-face {
  font-family: "Franziska";
  font-weight: 300;
  src: url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FFFranziskaWebProLight/FFFranziskaWebProLight.eot?") format("eot"), url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FFFranziskaWebProLight/FFFranziskaWebProLight.woff") format("woff"), url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FFFranziskaWebProLight/FFFranziskaWebProLight.ttf") format("truetype"); }

@font-face {
  font-family: "Franziska";
  font-style: italic;
  font-weight: 300;
  src: url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FFFranziskaWebProLightItalic/FFFranziskaWebProLightItalic.eot?") format("eot"), url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FFFranziskaWebProLightItalic/FFFranziskaWebProLightItalic.woff") format("woff"), url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FFFranziskaWebProLightItalic/FFFranziskaWebProLightItalic.ttf") format("truetype"); }

@font-face {
  font-family: "Franziska";
  font-weight: 500;
  src: url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FFFranziskaWebProMedium/FFFranziskaWebProMedium.eot?") format("eot"), url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FFFranziskaWebProMedium/FFFranziskaWebProMedium.woff") format("woff"), url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FFFranziskaWebProMedium/FFFranziskaWebProMedium.ttf") format("truetype"); }

@font-face {
  font-family: "Franziska";
  font-style: italic;
  font-weight: 500;
  src: url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FFFranziskaWebProMediumItalic/FFFranziskaWebProMediumItalic.eot?") format("eot"), url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FFFranziskaWebProMediumItalic/FFFranziskaWebProMediumItalic.woff") format("woff"), url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FFFranziskaWebProMediumItalic/FFFranziskaWebProMediumItalic.ttf") format("truetype"); }

@font-face {
  font-family: "Franziska";
  font-weight: 400;
  src: url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FFFranziskaWebPro/FFFranziskaWebPro.eot?") format("eot"), url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FFFranziskaWebPro/FFFranziskaWebPro.woff") format("woff"), url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FFFranziskaWebPro/FFFranziskaWebPro.ttf") format("truetype"); }

@font-face {
  font-family: "Franziska";
  font-style: italic;
  font-weight: 400;
  src: url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FFFranziskaWebProBookItalic/FFFranziskaWebProBookItalic.eot?") format("eot"), url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FFFranziskaWebProBookItalic/FFFranziskaWebProBookItalic.woff") format("woff"), url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FFFranziskaWebProBookItalic/FFFranziskaWebProBookItalic.ttf") format("truetype"); }

@font-face {
  font-family: "Franziska";
  font-weight: 600;
  src: url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FFFranziskaWebProDemiBold/FFFranziskaWebProDemiBold.eot?") format("eot"), url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FFFranziskaWebProDemiBold/FFFranziskaWebProDemiBold.woff") format("woff"), url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FFFranziskaWebProDemiBold/FFFranziskaWebProDemiBold.ttf") format("truetype"); }

@font-face {
  font-family: "Franziska";
  font-style: italic;
  font-weight: 600;
  src: url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FFFranziskaWebProDemiBoldItalic/FFFranziskaWebProDemiBoldItalic.eot?") format("eot"), url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FFFranziskaWebProDemiBoldItalic/FFFranziskaWebProDemiBoldItalic.woff") format("woff"), url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FFFranziskaWebProDemiBoldItalic/FFFranziskaWebProDemiBoldItalic.ttf") format("truetype"); }

@font-face {
  font-family: "Franziska";
  font-weight: 700;
  src: url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FFFranziskaWebProBold/FFFranziskaWebProBold.eot?") format("eot"), url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FFFranziskaWebProBold/FFFranziskaWebProBold.woff") format("woff"), url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FFFranziskaWebProBold/FFFranziskaWebProBold.ttf") format("truetype"); }

@font-face {
  font-family: "Franziska";
  font-style: italic;
  font-weight: 700;
  src: url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FFFranziskaWebProBoldItalic/FFFranziskaWebProBoldItalic.eot?") format("eot"), url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FFFranziskaWebProBoldItalic/FFFranziskaWebProBoldItalic.woff") format("woff"), url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FFFranziskaWebProBoldItalic/FFFranziskaWebProBoldItalic.ttf") format("truetype"); }

@font-face {
  font-family: "DejaVu Sans Mono";
  font-weight: 400;
  src: url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/DejaVuSansMono-Regular/DejaVuSansMono-Regular.eot?") format("eot"), url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/DejaVuSansMono-Regular/DejaVuSansMono-Regular.woff") format("woff"), url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/DejaVuSansMono-Regular/DejaVuSansMono-Regular.ttf") format("truetype"); }

button,
.wk-button,
[wk-button] {
  transition: background-color 0.1s ease-out;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #007ac3;
  border: 0;
  border-radius: 0;
  color: #fff;
  font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
  font-size: 0.87891rem;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 1rem;
  padding: 1.0625rem 0.9375rem;
  -webkit-tap-highlight-color: transparent;
  -ms-touch-action: manipulation;
      touch-action: manipulation;
  width: 100%; }
  button.wk-context-info, button[wk-context*='info'],
  .wk-button.wk-context-info,
  .wk-button[wk-context*='info'],
  [wk-button].wk-context-info,
  [wk-button][wk-context*='info'] {
    background-color: #007ac3;
    color: #fff; }
  button.wk-context-success, button[wk-context*='success'],
  .wk-button.wk-context-success,
  .wk-button[wk-context*='success'],
  [wk-button].wk-context-success,
  [wk-button][wk-context*='success'] {
    background-color: #85bc20;
    color: #fff; }
  button.wk-context-danger, button[wk-context*='danger'],
  .wk-button.wk-context-danger,
  .wk-button[wk-context*='danger'],
  [wk-button].wk-context-danger,
  [wk-button][wk-context*='danger'] {
    background-color: #e5202e;
    color: #fff; }
  @media (min-width: 37.5rem) {
    button,
    .wk-button,
    [wk-button] {
      display: inline-block;
      margin-bottom: 0;
      width: auto; } }
  button:hover:not([disabled]),
  .wk-button:hover:not([disabled]),
  [wk-button]:hover:not([disabled]) {
    background-color: #409bd2;
    color: #fff; }
  button .wk-button-icon-right, button[wk-button*='icon-right'],
  .wk-button .wk-button-icon-right,
  .wk-button[wk-button*='icon-right'],
  [wk-button] .wk-button-icon-right,
  [wk-button][wk-button*='icon-right'] {
    position: relative;
    padding-right: 4rem; }
    button .wk-button-icon-right::before, button[wk-button*='icon-right']::before,
    .wk-button .wk-button-icon-right::before,
    .wk-button[wk-button*='icon-right']::before,
    [wk-button] .wk-button-icon-right::before,
    [wk-button][wk-button*='icon-right']::before {
      background: rgba(255, 255, 255, 0.2);
      color: #fff;
      margin: 0;
      padding: 1.0625rem;
      position: absolute;
      top: 0;
      width: 3rem;
      right: 0; }
  button .wk-button-icon-left, button[wk-button*='icon-left'],
  .wk-button .wk-button-icon-left,
  .wk-button[wk-button*='icon-left'],
  [wk-button] .wk-button-icon-left,
  [wk-button][wk-button*='icon-left'] {
    position: relative;
    padding-left: 4rem; }
    button .wk-button-icon-left::before, button[wk-button*='icon-left']::before,
    .wk-button .wk-button-icon-left::before,
    .wk-button[wk-button*='icon-left']::before,
    [wk-button] .wk-button-icon-left::before,
    [wk-button][wk-button*='icon-left']::before {
      background: rgba(255, 255, 255, 0.2);
      color: #fff;
      margin: 0;
      padding: 1.0625rem;
      position: absolute;
      top: 0;
      width: 3rem;
      left: 0; }
  button.wk-button-secondary, button[wk-button*='secondary'],
  .wk-button.wk-button-secondary,
  .wk-button[wk-button*='secondary'],
  [wk-button].wk-button-secondary,
  [wk-button][wk-button*='secondary'] {
    background-color: #bfbfbf;
    color: #474747; }
    button.wk-button-secondary:hover:not([disabled]), button[wk-button*='secondary']:hover:not([disabled]),
    .wk-button.wk-button-secondary:hover:not([disabled]),
    .wk-button[wk-button*='secondary']:hover:not([disabled]),
    [wk-button].wk-button-secondary:hover:not([disabled]),
    [wk-button][wk-button*='secondary']:hover:not([disabled]) {
      background-color: #dadada;
      color: #474747; }
  button.wk-button-success, button[wk-context='success'],
  .wk-button.wk-button-success,
  .wk-button[wk-context='success'],
  [wk-button].wk-button-success,
  [wk-button][wk-context='success'] {
    background-color: #85bc20;
    color: #fff; }
    button.wk-button-success:hover:not([disabled]), button[wk-context='success']:hover:not([disabled]),
    .wk-button.wk-button-success:hover:not([disabled]),
    .wk-button[wk-context='success']:hover:not([disabled]),
    [wk-button].wk-button-success:hover:not([disabled]),
    [wk-button][wk-context='success']:hover:not([disabled]) {
      background-color: #a4cd58; }
  button.wk-button-danger, button[wk-context='danger'],
  .wk-button.wk-button-danger,
  .wk-button[wk-context='danger'],
  [wk-button].wk-button-danger,
  [wk-button][wk-context='danger'] {
    background-color: #e5202e;
    color: #fff; }
    button.wk-button-danger:hover:not([disabled]), button[wk-context='danger']:hover:not([disabled]),
    .wk-button.wk-button-danger:hover:not([disabled]),
    .wk-button[wk-context='danger']:hover:not([disabled]),
    [wk-button].wk-button-danger:hover:not([disabled]),
    [wk-button][wk-context='danger']:hover:not([disabled]) {
      background-color: #ec5862; }
  button[disabled],
  .wk-button[disabled],
  [wk-button][disabled] {
    opacity: 0.3; }
  button.wk-button-full, button[wk-button*='full'],
  .wk-button.wk-button-full,
  .wk-button[wk-button*='full'],
  [wk-button].wk-button-full,
  [wk-button][wk-button*='full'] {
    width: 100%; }
  button.wk-loading, button[wk-button*='loading'], button[wk-loading],
  .wk-button.wk-loading,
  .wk-button[wk-button*='loading'],
  .wk-button[wk-loading],
  [wk-button].wk-loading,
  [wk-button][wk-button*='loading'],
  [wk-button][wk-loading] {
    position: relative; }
    button.wk-loading::before, button[wk-button*='loading']::before, button[wk-loading]::before,
    .wk-button.wk-loading::before,
    .wk-button[wk-button*='loading']::before,
    .wk-button[wk-loading]::before,
    [wk-button].wk-loading::before,
    [wk-button][wk-button*='loading']::before,
    [wk-button][wk-loading]::before {
      -webkit-animation: spin 2s infinite linear;
              animation: spin 2s infinite linear;
      display: inline-block;
      font-family: 'wk-icons';
      left: 48%;
      position: absolute;
      -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
      @media (min-width: 37.5rem) {
        button.wk-loading::before, button[wk-button*='loading']::before, button[wk-loading]::before,
        .wk-button.wk-loading::before,
        .wk-button[wk-button*='loading']::before,
        .wk-button[wk-loading]::before,
        [wk-button].wk-loading::before,
        [wk-button][wk-button*='loading']::before,
        [wk-button][wk-loading]::before {
          left: 41%; } }
    button.wk-loading::after, button[wk-button*='loading']::after, button[wk-loading]::after,
    .wk-button.wk-loading::after,
    .wk-button[wk-button*='loading']::after,
    .wk-button[wk-loading]::after,
    [wk-button].wk-loading::after,
    [wk-button][wk-button*='loading']::after,
    [wk-button][wk-loading]::after {
      content: '.';
      visibility: hidden; }
      @media (min-width: 37.5rem) {
        button.wk-loading::after, button[wk-button*='loading']::after, button[wk-loading]::after,
        .wk-button.wk-loading::after,
        .wk-button[wk-button*='loading']::after,
        .wk-button[wk-loading]::after,
        [wk-button].wk-loading::after,
        [wk-button][wk-button*='loading']::after,
        [wk-button][wk-loading]::after {
          display: none; } }
    button.wk-loading > span, button[wk-button*='loading'] > span, button[wk-loading] > span,
    .wk-button.wk-loading > span,
    .wk-button[wk-button*='loading'] > span,
    .wk-button[wk-loading] > span,
    [wk-button].wk-loading > span,
    [wk-button][wk-button*='loading'] > span,
    [wk-button][wk-loading] > span {
      display: none; }
      @media (min-width: 37.5rem) {
        button.wk-loading > span, button[wk-button*='loading'] > span, button[wk-loading] > span,
        .wk-button.wk-loading > span,
        .wk-button[wk-button*='loading'] > span,
        .wk-button[wk-loading] > span,
        [wk-button].wk-loading > span,
        [wk-button][wk-button*='loading'] > span,
        [wk-button][wk-loading] > span {
          display: inline-block;
          visibility: hidden; } }
  button.wk-button-icon,
  .wk-button.wk-button-icon,
  [wk-button].wk-button-icon {
    font-size: 1.38084rem;
    padding: 0.8125rem; }
    button.wk-button-icon > [class^='wk-icon-']::before,
    button.wk-button-icon > [class*=' wk-icon-']::before,
    .wk-button.wk-button-icon > [class^='wk-icon-']::before,
    .wk-button.wk-button-icon > [class*=' wk-icon-']::before,
    [wk-button].wk-button-icon > [class^='wk-icon-']::before,
    [wk-button].wk-button-icon > [class*=' wk-icon-']::before {
      padding: 0; }
    button.wk-button-icon > .wk-button-text,
    .wk-button.wk-button-icon > .wk-button-text,
    [wk-button].wk-button-icon > .wk-button-text {
      font-size: 1rem;
      vertical-align: middle; }

a.wk-button,
a[wk-button] {
  display: block;
  text-align: center; }
  @media (min-width: 37.5rem) {
    a.wk-button,
    a[wk-button] {
      display: inline-block; } }
  a.wk-button:hover,
  a[wk-button]:hover {
    color: #fff; }
  a.wk-button .wk-button-link, a.wk-button[wk-button*='link'],
  a[wk-button] .wk-button-link,
  a[wk-button][wk-button*='link'] {
    position: relative;
    padding-left: 4rem;
    background-color: transparent;
    color: #474747;
    margin-bottom: 0;
    padding: 0;
    padding-left: 1.25rem; }
    a.wk-button .wk-button-link::before, a.wk-button[wk-button*='link']::before,
    a[wk-button] .wk-button-link::before,
    a[wk-button][wk-button*='link']::before {
      background: transparent;
      color: #fff;
      margin: 0;
      padding: 1.0625rem;
      position: absolute;
      top: 0;
      width: 3rem;
      left: 0; }
    a.wk-button .wk-button-link:hover, a.wk-button[wk-button*='link']:hover,
    a[wk-button] .wk-button-link:hover,
    a[wk-button][wk-button*='link']:hover {
      background-color: transparent;
      color: #474747;
      text-decoration: underline; }
    a.wk-button .wk-button-link::before, a.wk-button[wk-button*='link']::before,
    a[wk-button] .wk-button-link::before,
    a[wk-button][wk-button*='link']::before {
      color: #474747;
      padding: 0;
      text-decoration: none;
      width: 3px; }

.wk-button-bar {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto; }
  .wk-button-bar:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 37.5rem) {
    .wk-button-bar .wk-button-group-left {
      float: left; } }
  .wk-button-bar .wk-button-group-left + .wk-button-group-right {
    margin-top: 1rem; }
    @media (min-width: 37.5rem) {
      .wk-button-bar .wk-button-group-left + .wk-button-group-right {
        margin-top: 0; } }
  @media (min-width: 37.5rem) {
    .wk-button-bar .wk-button-group-right {
      float: right; } }
  @media (min-width: 37.5rem) {
    .wk-button-bar button,
    .wk-button-bar .wk-button,
    .wk-button-bar [wk-button] {
      float: left;
      margin-bottom: 0;
      margin-right: 1rem; }
      .wk-button-bar button:last-child,
      .wk-button-bar .wk-button:last-child,
      .wk-button-bar [wk-button]:last-child {
        margin-right: 0; } }
  .wk-button-bar button:last-child,
  .wk-button-bar .wk-button:last-child,
  .wk-button-bar [wk-button]:last-child {
    margin-bottom: 0; }

.wk-hide-text {
  background-color: transparent;
  border: 0;
  color: transparent;
  font: 0 / 0 a;
  text-shadow: none; }

h1.wk-hide-text,
h2.wk-hide-text,
h3.wk-hide-text,
h4.wk-hide-text,
h5.wk-hide-text,
h6.wk-hide-text {
  margin: 0; }

.wk-clearfix::after {
  clear: both;
  content: ' ';
  display: table; }

.wk-text-overflow {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.wk-shown {
  display: block; }

.wk-hidden {
  display: none; }

@media (min-width: 37.5rem) {
  .wk-logo-small {
    display: none; } }

.wk-logo-large {
  display: none; }
  @media (min-width: 37.5rem) {
    .wk-logo-large {
      display: block; } }

.wk-img-responsive {
  height: auto;
  max-width: 100%; }

.wk-unstyled-list {
  list-style: none;
  margin: 0;
  padding: 0; }

.wk-fixed-top {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999; }

.wk-header {
  background-color: #fff;
  width: 100%; }
  .wk-header > .wk-header-container {
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;
    position: relative; }
    .wk-header > .wk-header-container:after {
      content: " ";
      display: block;
      clear: both; }
    @media (min-width: 37.5rem) {
      .wk-header > .wk-header-container {
        padding: 0.5625rem 1rem; } }
    @media (min-width: 77rem) {
      .wk-header > .wk-header-container {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0.5625rem; } }
  .wk-header .wk-logo {
    display: block;
    float: left;
    margin: 0.78125rem 0.5rem 0.8125rem 1rem; }
    @media (min-width: 37.5rem) {
      .wk-header .wk-logo {
        margin: 1.46875rem 0; } }
  .wk-header .wk-nav > li,
  .wk-header .wk-more-menu > li {
    display: block; }
    @media (min-width: 37.5rem) {
      .wk-header .wk-nav > li,
      .wk-header .wk-more-menu > li {
        padding: 0; } }
    .wk-header .wk-nav > li [class^='wk-icon-']::before,
    .wk-header .wk-nav > li [class*=' wk-icon-']::before,
    .wk-header .wk-more-menu > li [class^='wk-icon-']::before,
    .wk-header .wk-more-menu > li [class*=' wk-icon-']::before {
      color: #757575;
      font-size: 1.375rem;
      margin-left: 0;
      position: relative;
      top: 0.125rem; }
    .wk-header .wk-nav > li:last-child,
    .wk-header .wk-more-menu > li:last-child {
      margin-right: 0; }
  .wk-header .wk-nav.wk-green-icons > li [class^='wk-icon-']::before,
  .wk-header .wk-nav.wk-green-icons > li [class*=' wk-icon-']::before,
  .wk-header .wk-more-menu.wk-green-icons > li [class^='wk-icon-']::before,
  .wk-header .wk-more-menu.wk-green-icons > li [class*=' wk-icon-']::before {
    color: #85bc20; }
  .wk-header .wk-nav.wk-gray-icons > li [class^='wk-icon-']::before,
  .wk-header .wk-nav.wk-gray-icons > li [class*=' wk-icon-']::before,
  .wk-header .wk-more-menu.wk-gray-icons > li [class^='wk-icon-']::before,
  .wk-header .wk-more-menu.wk-gray-icons > li [class*=' wk-icon-']::before {
    color: #757575; }
  .wk-header .wk-nav.wk-gray-icons > li > a:hover [class^='wk-icon-']::before,
  .wk-header .wk-nav.wk-gray-icons > li > a:hover [class*=' wk-icon-']::before,
  .wk-header .wk-more-menu.wk-gray-icons > li > a:hover [class^='wk-icon-']::before,
  .wk-header .wk-more-menu.wk-gray-icons > li > a:hover [class*=' wk-icon-']::before {
    color: #85bc20;
    transition: color .3s; }
  .wk-header .wk-nav {
    display: none;
    background-color: #474747;
    color: #fff;
    float: left;
    width: 100%; }
    @media (min-width: 37.5rem) {
      .wk-header .wk-nav {
        display: block;
        background-color: transparent;
        float: right;
        margin-top: 1.1875rem;
        width: auto; } }
    @media (min-width: 77rem) {
      .wk-header .wk-nav {
        padding: 0; } }
    .wk-header .wk-nav [class^='wk-icon-']::before,
    .wk-header .wk-nav [class*=' wk-icon-']::before {
      line-height: 0; }
    .wk-header .wk-nav > li:last-child {
      padding-right: 0; }
    .wk-header .wk-nav > li > a {
      padding: 0.625rem 0.9375rem; }
    .wk-header .wk-nav > li > a,
    .wk-header .wk-nav > li > a:hover {
      color: #fff; }
      @media (min-width: 37.5rem) {
        .wk-header .wk-nav > li > a,
        .wk-header .wk-nav > li > a:hover {
          color: #757575; } }
    .wk-header .wk-nav > li > a:hover {
      color: #85bc20; }
    .wk-header .wk-nav.open {
      display: block;
      background-color: rgba(71, 71, 71, 0.95);
      position: absolute;
      top: 2.9375rem;
      z-index: 1040; }
      @media (min-width: 37.5rem) {
        .wk-header .wk-nav.open {
          background-color: transparent;
          position: relative;
          top: 0; } }
      .wk-header .wk-nav.open [class^='wk-icon-']::before,
      .wk-header .wk-nav.open [class*=' wk-icon-']::before {
        color: #fff; }
  .wk-header .wk-more-menu {
    list-style: none;
    margin: 0;
    padding: 0;
    float: right;
    font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif; }
    .wk-header .wk-more-menu > li {
      display: block; }
      .wk-header .wk-more-menu > li > a {
        display: block; }
    @media (min-width: 37.5rem) {
      .wk-header .wk-more-menu {
        display: none; } }
    .wk-header .wk-more-menu a {
      color: #757575;
      padding: 0.75rem 1rem 0.6875rem; }
    .wk-header .wk-more-menu a:hover {
      color: #85bc20; }
    .wk-header .wk-more-menu.open {
      background-color: rgba(71, 71, 71, 0.95); }
      .wk-header .wk-more-menu.open [class^='wk-icon-']::before,
      .wk-header .wk-more-menu.open [class*=' wk-icon-']::before {
        color: #fff; }
      .wk-header .wk-more-menu.open a {
        color: #fff; }
      .wk-header .wk-more-menu.open a:hover {
        color: #85bc20; }

.wk-footer {
  background-color: #757575;
  margin-top: 1.75rem; }
  .wk-footer .wk-footer-container {
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1.875rem;
    padding-top: 1.875rem; }
    .wk-footer .wk-footer-container:after {
      content: " ";
      display: block;
      clear: both; }
    .wk-footer .wk-footer-container:after {
      content: " ";
      display: block;
      clear: both; }
    @media (min-width: 77rem) {
      .wk-footer .wk-footer-container {
        padding-left: 0;
        padding-right: 0; } }
  .wk-footer .wk-footer-nav::after {
    clear: both;
    content: ' ';
    display: table; }
  @media (min-width: 37.5rem) {
    .wk-footer .wk-footer-nav {
      max-width: 75rem;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 2.5rem;
      width: auto; }
      .wk-footer .wk-footer-nav:after {
        content: " ";
        display: block;
        clear: both; } }
  @media (min-width: 37.5rem) and (min-width: 37.5rem) {
    .wk-footer .wk-footer-nav > .wk-footer-nav-list {
      width: 23.72881%;
      float: left;
      margin-right: 1.69492%; }
      .wk-footer .wk-footer-nav > .wk-footer-nav-list:nth-child(4n) {
        float: right;
        margin-right: 0; } }
  .wk-footer .wk-footer-nav .wk-footer-nav-list {
    margin-bottom: 2.5rem; }
    @media (min-width: 37.5rem) {
      .wk-footer .wk-footer-nav .wk-footer-nav-list {
        margin-bottom: 0; } }
    .wk-footer .wk-footer-nav .wk-footer-nav-list .wk-footer-heading {
      color: #bfbfbf;
      font-size: 1rem;
      font-weight: 400;
      margin-bottom: 1rem;
      margin-top: 0; }
      @media (min-width: 37.5rem) {
        .wk-footer .wk-footer-nav .wk-footer-nav-list .wk-footer-heading {
          font-size: 0.87891rem;
          margin-bottom: 0.5rem; } }
    .wk-footer .wk-footer-nav .wk-footer-nav-list > ul {
      list-style: none;
      padding: 0; }
      .wk-footer .wk-footer-nav .wk-footer-nav-list > ul > li {
        color: #fff;
        font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
        font-size: 1rem;
        font-weight: 400;
        margin-bottom: 1rem; }
        @media (min-width: 37.5rem) {
          .wk-footer .wk-footer-nav .wk-footer-nav-list > ul > li {
            font-size: 0.87891rem;
            margin-bottom: 0.5rem; } }
        .wk-footer .wk-footer-nav .wk-footer-nav-list > ul > li:last-child {
          margin-bottom: 0; }
        .wk-footer .wk-footer-nav .wk-footer-nav-list > ul > li > a {
          color: #fff;
          display: block; }
          @media (min-width: 65rem) {
            .wk-footer .wk-footer-nav .wk-footer-nav-list > ul > li > a {
              display: inline; } }
          .wk-footer .wk-footer-nav .wk-footer-nav-list > ul > li > a:hover {
            color: #ededed; }
  @media (min-width: 65rem) {
    .wk-footer .wk-logo {
      float: left; } }
  .wk-footer .wk-tagline {
    color: #fff;
    font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
    font-size: 0.87891rem;
    font-weight: 300;
    margin-top: 1.75rem; }
    @media (min-width: 65rem) {
      .wk-footer .wk-tagline {
        float: right;
        font-size: 1.13778rem;
        margin-top: 0;
        position: relative;
        top: 7px; } }
  .wk-footer .wk-social-icons-container li {
    display: inline-block; }
  .wk-footer .wk-social-icons-container .wk-social-item {
    font-size: 1.75rem;
    margin-right: 0.625rem; }
    .wk-footer .wk-social-icons-container .wk-social-item span {
      height: 1px;
      overflow: hidden;
      position: absolute;
      top: -10px;
      width: 1px; }
  .wk-footer .wk-footer-copyright {
    background-color: #f6f6f6;
    color: #757575;
    font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
    font-size: 0.82397rem; }
    .wk-footer .wk-footer-copyright .wk-footer-copyright-container {
      max-width: 75rem;
      margin-left: auto;
      margin-right: auto;
      max-width: 75rem;
      margin-left: auto;
      margin-right: auto;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-bottom: 1.25rem;
      padding-top: 1.25rem; }
      .wk-footer .wk-footer-copyright .wk-footer-copyright-container:after {
        content: " ";
        display: block;
        clear: both; }
      .wk-footer .wk-footer-copyright .wk-footer-copyright-container:after {
        content: " ";
        display: block;
        clear: both; }
      @media (min-width: 77rem) {
        .wk-footer .wk-footer-copyright .wk-footer-copyright-container {
          padding-left: 0;
          padding-right: 0; } }
  .wk-footer .wk-footer-meta-info {
    padding-left: 0; }
    .wk-footer .wk-footer-meta-info li {
      display: inline;
      list-style-type: none;
      margin-right: 2rem;
      padding-left: 0; }
    .wk-footer .wk-footer-meta-info a {
      color: #757575; }
      .wk-footer .wk-footer-meta-info a:hover {
        text-decoration: underline; }

.wk-row {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto; }
  .wk-row:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 37.5rem) {
    .wk-row div[class^='wk-col']:last-child {
      float: right;
      margin-right: 0; }
    .wk-row .wk-col-1 {
      width: 6.77966%;
      float: left;
      margin-right: 1.69492%; }
    .wk-row .wk-col-2 {
      width: 15.25424%;
      float: left;
      margin-right: 1.69492%; }
    .wk-row .wk-col-3 {
      width: 23.72881%;
      float: left;
      margin-right: 1.69492%; }
    .wk-row .wk-col-4 {
      width: 32.20339%;
      float: left;
      margin-right: 1.69492%; }
    .wk-row .wk-col-5 {
      width: 40.67797%;
      float: left;
      margin-right: 1.69492%; }
    .wk-row .wk-col-6 {
      width: 49.15254%;
      float: left;
      margin-right: 1.69492%; }
    .wk-row .wk-col-7 {
      width: 57.62712%;
      float: left;
      margin-right: 1.69492%; }
    .wk-row .wk-col-8 {
      width: 66.10169%;
      float: left;
      margin-right: 1.69492%; }
    .wk-row .wk-col-9 {
      width: 74.57627%;
      float: left;
      margin-right: 1.69492%; }
    .wk-row .wk-col-10 {
      width: 83.05085%;
      float: left;
      margin-right: 1.69492%; }
    .wk-row .wk-col-11 {
      width: 91.52542%;
      float: left;
      margin-right: 1.69492%; }
    .wk-row .wk-col-12 {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0; } }

.wk-row-persist {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto; }
  .wk-row-persist:after {
    content: " ";
    display: block;
    clear: both; }
  .wk-row-persist div[class^='wk-col']:last-child {
    float: right;
    margin-right: 0; }
  .wk-row-persist .wk-col-1 {
    width: 6.77966%;
    float: left;
    margin-right: 1.69492%; }
  .wk-row-persist .wk-col-2 {
    width: 15.25424%;
    float: left;
    margin-right: 1.69492%; }
  .wk-row-persist .wk-col-3 {
    width: 23.72881%;
    float: left;
    margin-right: 1.69492%; }
  .wk-row-persist .wk-col-4 {
    width: 32.20339%;
    float: left;
    margin-right: 1.69492%; }
  .wk-row-persist .wk-col-5 {
    width: 40.67797%;
    float: left;
    margin-right: 1.69492%; }
  .wk-row-persist .wk-col-6 {
    width: 49.15254%;
    float: left;
    margin-right: 1.69492%; }
  .wk-row-persist .wk-col-7 {
    width: 57.62712%;
    float: left;
    margin-right: 1.69492%; }
  .wk-row-persist .wk-col-8 {
    width: 66.10169%;
    float: left;
    margin-right: 1.69492%; }
  .wk-row-persist .wk-col-9 {
    width: 74.57627%;
    float: left;
    margin-right: 1.69492%; }
  .wk-row-persist .wk-col-10 {
    width: 83.05085%;
    float: left;
    margin-right: 1.69492%; }
  .wk-row-persist .wk-col-11 {
    width: 91.52542%;
    float: left;
    margin-right: 1.69492%; }
  .wk-row-persist .wk-col-12 {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }

.wk-table-permit-overflow {
  border: 1px solid #ededed;
  border-bottom: 0;
  border-top: 0;
  margin-bottom: 1.25rem;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%; }
  .wk-table-permit-overflow > .wk-table {
    border: 0;
    margin-bottom: 0; }
    .wk-table-permit-overflow > .wk-table th:last-child,
    .wk-table-permit-overflow > .wk-table td:last-child {
      border-right: 0; }
    .wk-table-permit-overflow > .wk-table th:first-child,
    .wk-table-permit-overflow > .wk-table td:first-child {
      border-left: 0; }

th {
  text-align: left; }

caption {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  text-align: left; }

.wk-table {
  border-collapse: collapse;
  margin-bottom: 1.25rem;
  max-width: 100%;
  width: 100%; }
  .wk-table > thead > tr > th,
  .wk-table > thead > tr > td,
  .wk-table > tfoot > tr > th,
  .wk-table > tbody > tr > td,
  .wk-table > tbody > tr > th {
    padding: 0.625rem;
    vertical-align: top; }
  .wk-table th {
    white-space: nowrap; }
  .wk-table th,
  .wk-table td {
    border: 1px solid #dadada; }
  .wk-table > tfoot > tr > th,
  .wk-table > thead > tr > th {
    background-color: #f6f6f6;
    color: #474747;
    font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
    font-size: 0.82397rem; }
  .wk-table > tbody > tr > td {
    background-color: #fff;
    border-top: #ededed;
    color: #474747;
    font-size: 0.9375rem; }
  .wk-table > tbody + tbody {
    border-top: 2px solid #dadada; }
  .wk-table .wk-table-ascent,
  .wk-table .wk-table-descent {
    background-color: #ededed; }
  .wk-table .wk-table-ascent::after {
    content: '\e802'; }
  .wk-table .wk-table-descent::after {
    content: '\e805'; }
  .wk-table .wk-table-sorted {
    background-color: #f6f6f6; }

.wk-label, .wk-label-info, .wk-label-success, .wk-label-danger {
  display: inline-block;
  font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
  font-size: 0.75rem;
  font-weight: 500;
  padding: 0.3125rem 0.625rem;
  text-transform: uppercase;
  vertical-align: middle;
  white-space: nowrap; }
  .wk-context-info.wk-label, .wk-context-info.wk-label-info, .wk-context-info.wk-label-success, .wk-context-info.wk-label-danger, [wk-context*='info'].wk-label, [wk-context*='info'].wk-label-info, [wk-context*='info'].wk-label-success, [wk-context*='info'].wk-label-danger {
    background-color: #007ac3;
    color: #fff; }
  .wk-context-success.wk-label, .wk-context-success.wk-label-info, .wk-context-success.wk-label-success, .wk-context-success.wk-label-danger, [wk-context*='success'].wk-label, [wk-context*='success'].wk-label-info, [wk-context*='success'].wk-label-success, [wk-context*='success'].wk-label-danger {
    background-color: #85bc20;
    color: #fff; }
  .wk-context-danger.wk-label, .wk-context-danger.wk-label-info, .wk-context-danger.wk-label-success, .wk-context-danger.wk-label-danger, [wk-context*='danger'].wk-label, [wk-context*='danger'].wk-label-info, [wk-context*='danger'].wk-label-success, [wk-context*='danger'].wk-label-danger {
    background-color: #e5202e;
    color: #fff; }

.wk-label {
  background-color: #757575;
  color: #fff; }

.wk-label-info {
  background-color: #007ac3;
  color: #fff; }

.wk-label-success {
  background-color: #85bc20;
  color: #fff; }

.wk-label-danger {
  background-color: #e5202e;
  color: #fff; }

.wk-document a:hover {
  text-decoration: underline; }

.wk-document p,
.wk-document ol,
.wk-document ul {
  color: #474747;
  font-family: "Franziska", "Times New Roman", Times, serif;
  font-size: 1.06667rem;
  line-height: 1.7; }

.wk-document ol,
.wk-document ul {
  margin: 1em 0 1em 3em;
  padding: 0; }
  .wk-document ol > li,
  .wk-document ul > li {
    margin-bottom: .5em; }

.wk-document ul {
  list-style-type: square; }

.wk-document p {
  margin-bottom: 1.25rem; }

.wk-document-legacy .dps-resource a.smallLink:link {
  color: #0000cc;
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: normal;
  text-decoration: underline; }

.wk-document-legacy .dps-resource a.smallLink:active {
  color: #000099; }

.wk-document-legacy .dps-resource a.smallLink:visited {
  color: #660066;
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: normal;
  text-decoration: underline; }

.wk-document-legacy .dps-resource a.smallLink:hover {
  color: #0000cc;
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: normal;
  text-decoration: underline; }

.wk-document-legacy .dps-resource a.small-link {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: normal; }
  .wk-document-legacy .dps-resource a.small-link:link {
    color: blue; }

.wk-document-legacy .dps-resource a.metadataLink:link {
  color: #ffffff;
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  font-size: small;
  font-weight: bold;
  text-decoration: underline; }

.wk-document-legacy .dps-resource a.metadataLink:active {
  color: #ffffff; }

.wk-document-legacy .dps-resource a.metadataLink:visited {
  color: #ffffff;
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  font-size: small;
  font-weight: bold;
  text-decoration: underline; }

.wk-document-legacy .dps-resource a.metadataLink:hover {
  color: #ffffff;
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  font-size: small;
  font-weight: bold;
  text-decoration: underline; }

.wk-document-legacy .dps-resource a.breadCrumb:link {
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  font-size: 11px;
  color: #0000cc;
  text-decoration: none; }

.wk-document-legacy .dps-resource a.breadCrumb:active {
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  font-size: 11px;
  color: #000099; }

.wk-document-legacy .dps-resource a.breadCrumb:visited {
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  font-size: 11px;
  color: #660066; }

.wk-document-legacy .dps-resource a.breadCrumb:hover {
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  font-size: 11px;
  color: #0000cc;
  text-decoration: underline; }

.wk-document-legacy .dps-resource a:empty {
  display: none; }

.wk-document-legacy .dps-resource .outer-table-wrapper {
  overflow: auto;
  margin-bottom: 1.875rem; }

.wk-document-legacy .dps-resource .inner-table-wrapper {
  overflow: visible; }

.wk-document-legacy .dps-resource div.content-wrap {
  padding: 10px;
  padding: 0; }

.wk-document-legacy .dps-resource div.tableVspace {
  width: 100%;
  height: 16px;
  border: 1px  black solid; }

.wk-document-legacy .dps-resource div.path-line-content {
  margin: 12px 0 0;
  padding: 4px 0 0; }

.wk-document-legacy .dps-resource div.tochead {
  margin-left: 0;
  margin-top: 3pt;
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  font-size: 120%;
  font-weight: bold; }

.wk-document-legacy .dps-resource div.toc {
  margin-left: 10pt;
  margin-top: 3pt; }

.wk-document-legacy .dps-resource div.tocindent {
  margin-left: 10pt;
  margin-bottom: 6pt; }

.wk-document-legacy .dps-resource div.space {
  display: table;
  vertical-align: bottom;
  margin: 4px 0; }

.wk-document-legacy .dps-resource div.graphic {
  width: 100%;
  text-align: center; }

.wk-document-legacy .dps-resource div.warningMsg {
  color: #cc0000;
  font-weight: bold; }

.wk-document-legacy .dps-resource div.nav-caution-note-wrapper {
  position: relative; }

.wk-document-legacy .dps-resource div.cnBubble {
  left: 5px;
  z-index: 9999999;
  position: absolute;
  min-width: 480px;
  padding: 0;
  background: #ffffbe;
  border-radius: 0;
  border: #ff8315 solid 2px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.75); }
  .wk-document-legacy .dps-resource div.cnBubble:after {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 8px 8px 8px 0;
    border-color: transparent #ffffbe;
    display: block;
    width: 0;
    z-index: 1;
    left: -8px;
    top: 10px; }
  .wk-document-legacy .dps-resource div.cnBubble:before {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 9px 9px 9px 0;
    border-color: transparent #ff8315;
    display: block;
    width: 0;
    z-index: 0;
    left: -11px;
    top: 9px; }

.wk-document-legacy .dps-resource div.cnContent {
  padding: 8px; }

.wk-document-legacy .dps-resource div.media-container {
  display: inline-block;
  margin: 5px 0 10px; }
  .wk-document-legacy .dps-resource div.media-container span.tooltip-hide {
    display: none; }
  .wk-document-legacy .dps-resource div.media-container span.tooltip-show {
    display: inline;
    margin-left: 20px;
    margin-top: 20px;
    position: absolute;
    border: 1px solid #cccccc;
    background: #ffffff;
    color: #6c6c6c; }

.wk-document-legacy .dps-resource div.cnBody p {
  margin: 6px 0 0;
  font-style: italic; }

.wk-document-legacy .dps-resource .docHeadSpacer {
  font-family: "Fira Sans", "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-right: 4pt; }

.wk-document-legacy .dps-resource h1.metadata {
  text-align: left;
  font-size: 120%;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  margin-top: 12px;
  margin-bottom: 0; }

.wk-document-legacy .dps-resource h1.valueadd {
  padding: 0;
  margin: 0;
  font: bold large/1em Arial, sans-serif;
  border-bottom: #660066 2px solid; }

.wk-document-legacy .dps-resource h2.valueadd {
  padding: 0;
  margin: 0;
  font: bold medium/1em Arial, sans-serif; }

.wk-document-legacy .dps-resource h3.valueadd {
  padding: 0;
  margin: 0;
  font: bold small/1em Arial, sans-serif; }

.wk-document-legacy .dps-resource table {
  width: 100%; }
  .wk-document-legacy .dps-resource table.metadata {
    background-color: transparent;
    font-size: 8pt;
    font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
    margin-top: 0; }
  .wk-document-legacy .dps-resource table.noteIndent {
    margin-left: 15px; }

.wk-document-legacy .dps-resource td.metadatahead {
  font-weight: bold;
  padding-left: 10pt;
  padding-right: 10pt;
  width: 150pt; }

.wk-document-legacy .dps-resource td.metadatahead_print {
  font-weight: bold;
  padding-left: 0;
  padding-right: 10pt;
  width: 150pt; }

.wk-document-legacy .dps-resource td.metadatahead2 {
  font-weight: bold;
  padding-right: 10pt; }

.wk-document-legacy .dps-resource td.olText {
  height: auto;
  padding-bottom: 10pt;
  padding-left: 6px; }

.wk-document-legacy .dps-resource td.digit {
  text-align: right; }

.wk-document-legacy .dps-resource td.small-cap {
  text-transform: uppercase;
  font-weight: normal; }

.wk-document-legacy .dps-resource td.bold-small-cap {
  text-transform: uppercase;
  font-weight: bold; }

.wk-document-legacy .dps-resource td.underline {
  text-decoration: underline;
  font-weight: normal; }

.wk-document-legacy .dps-resource td.roman {
  font-weight: normal; }

.wk-document-legacy .dps-resource td.bold {
  font-weight: bold; }

.wk-document-legacy .dps-resource td.italic {
  font-style: italic; }

.wk-document-legacy .dps-resource td.bold-italic {
  font-style: italic;
  font-weight: bold; }

.wk-document-legacy .dps-resource td.metricsCell {
  width: 25%;
  color: #888888;
  font-size: .85em;
  vertical-align: top; }
  .wk-document-legacy .dps-resource td.metricsCell .dateCreated {
    font-weight: bold; }
  .wk-document-legacy .dps-resource td.metricsCell .metrics .author.deleted {
    font-style: italic; }
  .wk-document-legacy .dps-resource td.metricsCell .metrics .author.disabled {
    font-style: italic; }

.wk-document-legacy .dps-resource td.notesCell {
  width: 75%; }

.wk-document-legacy .dps-resource td p.hp {
  margin: 0 0 10px 0; }

.wk-document-legacy .dps-resource p.caption {
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  font-size: small;
  font-weight: normal;
  font-style: italic; }

.wk-document-legacy .dps-resource p.blockquote {
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  font-size: small;
  line-height: 1.25em; }

.wk-document-legacy .dps-resource p.related-topics-state {
  margin: 0 10px 0 0;
  padding: 0;
  font: bold italic small/1.3em Arial, Helvetica, sans-serif;
  color: #000080; }

.wk-document-legacy .dps-resource p.docType {
  display: block;
  padding: 0;
  font: bold small/1em Arial, sans-serif;
  color: #244680; }

.wk-document-legacy .dps-resource p:empty {
  display: none; }

.wk-document-legacy .dps-resource pre {
  font-family: "Courier New", "Courier", "monospace";
  font-size: small; }

.wk-document-legacy .dps-resource span.toc {
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  font-size: 80%; }

.wk-document-legacy .dps-resource span.emphasis {
  font-weight: bold; }

.wk-document-legacy .dps-resource span.numemphasis {
  font-weight: bold;
  color: maroon;
  font-size: 110%; }

.wk-document-legacy .dps-resource span.footnote {
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  font-size: small;
  line-height: 1.25em; }

.wk-document-legacy .dps-resource span.leftCol {
  display: inline;
  background-color: white;
  vertical-align: bottom;
  text-align: left; }

.wk-document-legacy .dps-resource span.rightCol {
  display: table-cell;
  float: right;
  background-color: white;
  text-align: right;
  vertical-align: bottom; }

.wk-document-legacy .dps-resource span.space {
  display: inline-block;
  vertical-align: bottom;
  text-align: left; }

.wk-document-legacy .dps-resource .break {
  display: block; }

.wk-document-legacy .dps-resource ul.nested-ul {
  list-style-image: none; }

.wk-document-legacy .dps-resource ul.square {
  list-style-type: square; }

.wk-document-legacy .dps-resource ul.none {
  list-style-type: none; }

.wk-document-legacy .dps-resource ul.bull {
  list-style-type: square; }

.wk-document-legacy .dps-resource ul.bull > li {
  display: list-item !important; }

.wk-document-legacy .dps-resource li {
  margin: 0 0 10px 0; }

.wk-document-legacy .dps-resource .menupathBoxRow1 {
  width: 100%;
  height: 24px;
  text-align: right; }

.wk-document-legacy .dps-resource .menupathBoxRow2 {
  width: 100%; }

.wk-document-legacy .dps-resource .doc-path-wrapper {
  margin: 0;
  padding: 10px; }

.wk-document-legacy .dps-resource .doc_path_container {
  background-color: white;
  margin: 0;
  border: solid #00504c 1px;
  padding: 4px; }
  .wk-document-legacy .dps-resource .doc_path_container div.navigation-line div.link-box {
    text-align: center;
    margin: 10px 0 5px; }

.wk-document-legacy .dps-resource .clickpathNode {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: bold;
  color: #000000;
  text-decoration: none;
  margin: 0 5px; }

.wk-document-legacy .dps-resource .docid {
  width: 100%;
  height: 18px;
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  font-size: small;
  font-weight: bold;
  background-color: #00504c;
  color: #ffffff;
  padding: 2pt; }

.wk-document-legacy .dps-resource .date {
  color: navy; }

.wk-document-legacy .dps-resource .inline {
  color: purple; }

.wk-document-legacy .dps-resource .inline2 {
  color: green; }

.wk-document-legacy .dps-resource .center {
  text-align: center; }

.wk-document-legacy .dps-resource .metadataContainer {
  display: none; }

.wk-document-legacy .dps-resource .showMetadata {
  position: absolute;
  float: right; }

.wk-document-legacy .dps-resource .docSearchTerm {
  background-color: #ffff66;
  font-weight: bold; }

.wk-document-legacy .dps-resource #moreInfoBox {
  border: 1px solid #00504c;
  padding: 6px;
  width: 99%;
  -moz-box-sizing: padding-box; }

.wk-document-legacy .dps-resource .moreInfoText {
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 11pt;
  color: #006699;
  text-transform: capitalize;
  white-space: nowrap; }

.wk-document-legacy .dps-resource .moreInfoBullet {
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 14pt;
  color: #006699;
  text-transform: capitalize;
  white-space: nowrap;
  padding-left: 6px;
  padding-right: 6px;
  text-align: center; }

.wk-document-legacy .dps-resource .newDevBox {
  background: #ffffee;
  padding: 10px;
  bordnr: 1px solid; }
  .wk-document-legacy .dps-resource .newDevBox h1 {
    margin-top: 0;
    border-bottom: 2px solid #660066;
    font: bold small/1.5em Arial, sans-serif;
    text-transform: uppercase; }
  .wk-document-legacy .dps-resource .newDevBox h2 {
    margin-bottom: 0; }
  .wk-document-legacy .dps-resource .newDevBox p {
    margin-top: 0; }

.wk-document-legacy .dps-resource #newDevBtn {
  width: 130px;
  height: 19px;
  padding: 2px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px; }

.wk-document-legacy .dps-resource .newDevBtnHighlighted {
  border: 1px solid #0000cc;
  background-color: #f4f3c9;
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  color: #ff0000;
  font-weight: bold;
  font-size: 11px;
  text-align: center; }

.wk-document-legacy .dps-resource .quicklinks {
  margin: 4px 10px 0 15px;
  padding: 0;
  background: #fef8e3;
  border: 1px solid #ccc;
  font: small/1.3em Arial, Helvetica, sans-serif; }
  .wk-document-legacy .dps-resource .quicklinks p {
    margin: 0 12px;
    padding: 0 0 12px 0; }
  .wk-document-legacy .dps-resource .quicklinks h1, .wk-document-legacy .dps-resource .quicklinks h2, .wk-document-legacy .dps-resource .quicklinks h3 {
    background-color: #003366;
    margin: 0 -1px 7px;
    padding: 4px 10px;
    color: #eee;
    font: bold small/1.3em Arial, Helvetica, sans-serif; }
  .wk-document-legacy .dps-resource .quicklinks li {
    margin: 0;
    padding: 0 0 0 2px;
    line-height: 1.1em; }
  .wk-document-legacy .dps-resource .quicklinks ul {
    margin-left: 12px; }

.wk-document-legacy .dps-resource .deleted {
  font: bold small/1.3em Arial, Helvetica, sans-serif;
  color: #ff0000; }

.wk-document-legacy .dps-resource .vetoed {
  font: bold small/1.3em Arial, Helvetica, sans-serif;
  color: #0000ee; }

.wk-document-legacy .dps-resource .added {
  font: bold italic small/1.3em Arial, Helvetica, sans-serif; }

.wk-document-legacy .dps-resource .addedgreen {
  font: bold italic small/1.3em Arial, Helvetica, sans-serif;
  color: #008000; }

.wk-document-legacy .dps-resource #learningCenter {
  height: 178px;
  width: 596px;
  margin-top: 20px; }

.wk-document-legacy .dps-resource #LCloginBtn {
  position: relative;
  left: 470px;
  top: 140px; }

.wk-document-legacy .dps-resource .breadCrumb {
  padding: 8px 0; }

.wk-document-legacy .dps-resource #smartRelate {
  padding: 0 0 35px 0;
  margin: 0 3px 10px 6px; }

.wk-document-legacy .dps-resource .noteContainer {
  padding-left: 25px;
  margin: 15px 0; }

.wk-document-legacy .dps-resource .noteBody {
  font-family: "Franziska", "Times New Roman", Times, serif; }

.wk-document-legacy .dps-resource .noteHead {
  font-weight: bold;
  margin: 0 0 0 15px; }

.wk-document-legacy .dps-resource .noteDisplayLabel,
.wk-document-legacy .dps-resource .note,
.wk-document-legacy .dps-resource .caution-note,
.wk-document-legacy .dps-resource .planning-note,
.wk-document-legacy .dps-resource .example,
.wk-document-legacy .dps-resource .faq,
.wk-document-legacy .dps-resource .comment,
.wk-document-legacy .dps-resource .cch-comment,
.wk-document-legacy .dps-resource .department-comment,
.wk-document-legacy .dps-resource .practitioner-comment,
.wk-document-legacy .dps-resource .checklist,
.wk-document-legacy .dps-resource .key-concepts,
.wk-document-legacy .dps-resource .tools,
.wk-document-legacy .dps-resource .gray-area,
.wk-document-legacy .dps-resource .cch-pointer,
.wk-document-legacy .dps-resource .cch-practice-tip,
.wk-document-legacy .dps-resource .compliance-tip,
.wk-document-legacy .dps-resource .compliance-pointer,
.wk-document-legacy .dps-resource .exam-standard,
.wk-document-legacy .dps-resource .expert,
.wk-document-legacy .dps-resource .forms,
.wk-document-legacy .dps-resource .legal,
.wk-document-legacy .dps-resource .policy,
.wk-document-legacy .dps-resource .sample-plan,
.wk-document-legacy .dps-resource .best-practice,
.wk-document-legacy .dps-resource .background,
.wk-document-legacy .dps-resource .summary,
.wk-document-legacy .dps-resource .iResearchAids,
.wk-document-legacy .dps-resource .cch-chart {
  color: #474747;
  font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 6px;
  position: relative; }
  .wk-document-legacy .dps-resource .noteDisplayLabel:before,
  .wk-document-legacy .dps-resource .note:before,
  .wk-document-legacy .dps-resource .caution-note:before,
  .wk-document-legacy .dps-resource .planning-note:before,
  .wk-document-legacy .dps-resource .example:before,
  .wk-document-legacy .dps-resource .faq:before,
  .wk-document-legacy .dps-resource .comment:before,
  .wk-document-legacy .dps-resource .cch-comment:before,
  .wk-document-legacy .dps-resource .department-comment:before,
  .wk-document-legacy .dps-resource .practitioner-comment:before,
  .wk-document-legacy .dps-resource .checklist:before,
  .wk-document-legacy .dps-resource .key-concepts:before,
  .wk-document-legacy .dps-resource .tools:before,
  .wk-document-legacy .dps-resource .gray-area:before,
  .wk-document-legacy .dps-resource .cch-pointer:before,
  .wk-document-legacy .dps-resource .cch-practice-tip:before,
  .wk-document-legacy .dps-resource .compliance-tip:before,
  .wk-document-legacy .dps-resource .compliance-pointer:before,
  .wk-document-legacy .dps-resource .exam-standard:before,
  .wk-document-legacy .dps-resource .expert:before,
  .wk-document-legacy .dps-resource .forms:before,
  .wk-document-legacy .dps-resource .legal:before,
  .wk-document-legacy .dps-resource .policy:before,
  .wk-document-legacy .dps-resource .sample-plan:before,
  .wk-document-legacy .dps-resource .best-practice:before,
  .wk-document-legacy .dps-resource .background:before,
  .wk-document-legacy .dps-resource .summary:before,
  .wk-document-legacy .dps-resource .iResearchAids:before,
  .wk-document-legacy .dps-resource .cch-chart:before {
    background-repeat: no-repeat;
    background-position: 0 0;
    content: '';
    height: 16px;
    left: -25px;
    position: absolute;
    top: 3px;
    width: 16px; }

.wk-document-legacy .dps-resource .caution-note:before {
  background-image: url(data:image/gif;base64,R0lGODlhEAAQAMQcALvi///sqP/nnd7w///tsd7x/9fu/+n1/+n2/9Ls///tt8Dk/8Dj/+Pz/9Hr/7vh///qov/daprN87u7u//jk2OQsXSs1uTz/5kAAMwAAP///5lmM////wAAAAAAAAAAACH5BAEAABwALAAAAAAQABAAAAV4IMdZZGmS4nit67axa6VqtLZp1MtKlXXVNpxuxfPVbhlMbtf70ZCYyAZxOBRZN0UyAnk1roXCDbLtDgZXwzhQ7hquiRshABEIKDnHVU7f4nN7OHgRhH8bVwsuiosbDFcPAJEAD5SQlBMyFhObnJ2bMiIVoqOkoiIhADs=); }

.wk-document-legacy .dps-resource .noteDisplayLabel:before,
.wk-document-legacy .dps-resource .note:before,
.wk-document-legacy .dps-resource .compliance-tip:before {
  background-image: url(data:image/gif;base64,R0lGODlhEAAQAMQbALvi/8Dj/7vh/9fu/8Dk/8bm/9ju/9Ls/+nv4d7w/97x///ff9Hr///44en1/6HO6ufw7Pe+W20/Ef/ShJrN82OQsXSs1uTz//+ZM5lmM////////wAAAAAAAAAAAAAAACH5BAEAABsALAAAAAAQABAAAAV74LZZZGmS4nitbLtWqiZrWZ21VGVdzZxpkdsKkdvJfr/gRVi80DCSZE0jcTQlmgl0EsFqJU2Fd0ImQxPNwWBslhgGRcjhVy5DGQ/dUrOAAr1QTQUSUFJYEgVNBAQSAVIBjQFNAgCVNhmUAgI5IxSen6CeMCIVpaanpSIhADs=); }

.wk-document-legacy .dps-resource .planning-note:before,
.wk-document-legacy .dps-resource .compliance-pointer:before,
.wk-document-legacy .dps-resource .summary:before {
  background-image: url(data:image/gif;base64,R0lGODlhEAAQALMNAL1IAvvsx//jk//wxP/dam0/EcVrI5rN82OQsXSs1uTz/////5lmM////wAAAAAAACH5BAEAAA0ALAAAAAAQABAAAARfsLVEq6Vyqs37RhojjuR2IInCLGy7rIxyputg3zUzqwswGEBDYVjYrQK35KCI4gEEwaCASVsIrlgs1QmNGqbGBWFMJm9XAILXQDiLy+UzcT4/TQ74vB4PkiD+gIF/EhEAOw==); }

.wk-document-legacy .dps-resource .comment:before,
.wk-document-legacy .dps-resource .cch-comment:before,
.wk-document-legacy .dps-resource .department-comment:before,
.wk-document-legacy .dps-resource .practitioner-comment:before {
  background-image: url(data:image/gif;base64,R0lGODlhEAAQAMQTAMDj/7vh/7vi/8Dk/8Xm/8bm/8vo/8vp///vvG0/EaN0QZrN8/e+W+Tz/2OQsXSs1v/ShP+ZM////////wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAABMALAAAAAAQABAAAAVz4DQ9ZGmS4tisbLs6qiQrdM0uztNItOzzigZOp5AhjshjYliEOJ9QSCLXjEanj6r1iS0yvuAwA9tQiMXLXMNAi7jfkcRh2CgQCIm8O08o0AcDAACBCXGBAHQBAosCAQF5jQE4IwuVlpVLlTAiDp2en50iIQA7); }

.wk-document-legacy .dps-resource .sample-plan:before,
.wk-document-legacy .dps-resource .background:before {
  background-image: url(data:image/gif;base64,R0lGODlhEAAQAMQQALvh/8Dj/8Xm/7vi/8bm/8GAP5rN8+Tz/3Ss1v/dav/jk2OQsf/wxG0/Ef///5lmM////wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAABAALAAAAAAQABAAAAVwIAQhJPKcZ0GK4+EeDyMzRXMsreOgKV0bC8RBF5vVZA2gkPco+BqMZBA2o0GjCumyWqhls0pqFdsATx+KtLrcSGhh6rXbHUbHs+7G+5Ho+9sNAgR1fn96AQGEhW4AAwAAQCN6k5MGlpEiC5qbnJoiIQA7); }

.wk-document-legacy .dps-resource .example:before {
  background-image: url(data:image/gif;base64,R0lGODlhEAAQAMQfAF99AMDj/9fu/9HfnPe+W+n2/8vp/97w/+n1/93w/9Hr/8Dk/8Xm/97x/9ju/+r2/8bm/9Hs/+Pz/9jt/7vi/7vh/5rN82OQsW0/EXSs1uTz//+ZMwAAAJlmM////////yH5BAEAAB8ALAAAAAAQABAAAAWD4PdlZGmS4qitK9dy7qV6tKfB7q1ZV6bVHhhLx/PRhENi78ehaR6IghSBKLZYklVnu8X4GpzEoXE4JDpAr0YgcLAdEwF6MAB4uV1FRDGv39MGgWgAhH8EBBsYEAwMGI6PGWiHiQEBC5aVRWgbnBgVnxUUnzwfj48WqKmkIhetrq+tIiEAOw==); }

.wk-document-legacy .dps-resource .faq:before {
  background-image: url(data:image/gif;base64,R0lGODlhEAAQANUgAMDj/7vi/9Hr/8Xm/7vh/8bm/97w/8Dk//e+W8vo/97x/9Lr/9ju/8DY8d3w/8zo/+Pz/9Hs/+r2/+n1/9Ls/9fu/8vp///ShG0/EZrN82OQsXSs1uTz//+ZM////5lmM////wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAACAALAAAAAAQABAAAAaNQBBoQywaicIhZ8n5OD9MjkbpqX6qVQwzo9lwrJ6L+ILQcrhez1WMwZA7WvT308ZIPm/MRB7lYMJwHBByCgoOBmtwDQYGchUVDBV4cI+RchQCmXRtCwsRAnIJFqMPoxaio3IFAwMFTxirrXIHBwAAV3AAtQByBAG/mxi+BARcQxnIycrIU0Iaz9DRz0JBADs=); }

.wk-document-legacy .dps-resource .checklist:before {
  background-image: url(data:image/gif;base64,R0lGODlhEAAQAMQbAMXm/7vi/8vp/8Dk/8bm/9fu/97w/9Lr/8vo/+r2/+n1/9ju/9Hr/9Hs/+Pz/8Dj/7vh//e+W//ShG0/EZrN82OQsXSs1uTz//+ZM5lmM////////wAAAAAAAAAAAAAAACH5BAEAABsALAAAAAAQABAAAAWA4LZZZGmS4nitbLtWqiZrbpZdVGVdM81mmglOx5v9NBJhbte7JICSyESxXNmsyAhm4qhmJBpbdmswLCeYCHg8KSwKVbRkrp0cDg1GFYGO1AUIAoJVBABoWwAAhYpVAwMPExMPD4+PD1UQAZoBEJ2ZnTkjFKOkpaMwIhWqq6yqIiEAOw==); }

.wk-document-legacy .dps-resource .key-concepts:before {
  background-image: url(data:image/gif;base64,R0lGODlhEAAQAMQdAGs8DLvh/8vp/8Dj/8Xm/8bm/97w/9fu/+Pz/5CQkNju/9Lr/9Hr/7vi/+r2/97x/8Dk/9Hs/8vo//e+W5rN82OQsXSs1m0/Ef/ShOTz//+ZM5lmM////////wAAAAAAACH5BAEAAB0ALAAAAAAQABAAAAWDYNdZZGla2yZaWeu+G4ddLGdzbxZjE53dONeu5wMKZT1KpXbLOGITjeaiZKWuu+kFUY0BkZeHwaDcYc7n6OWgOJRlaJ52EWG8MamL/CIR+O8bBHpqBQQEBV0zF4NTAxADA1U6E5SUUwENAQFKHQkUG1JSABSkpBUiHRUVAKwAqq+nHSEAOw==); }

.wk-document-legacy .dps-resource .tools:before {
  background-image: url(data:image/gif;base64,R0lGODlhEAAQAMQcAMDj/8vp///ShMXm/9Hr/9fu/8bm/8Dk/7vi/9Lr/97x/8vo/9Ls/97w/9ju/93w/+n2/9Hs/7vh//e+W20/EZrN83Ss1mOQseTz//+ZM5lmM////////wAAAAAAAAAAACH5BAEAABwALAAAAAAQABAAAAV+IMdZZGmS4oitLKa966Vu9LZqW6at1WVhNRtOx+r9gq7N7kbxAWtJgQaiETR/rah0krliFIpHA8fNmClGTKHgIHcp8EKaQXBTCIlEhJBe2AMBC4ABaXYGAwOHiUZ2BwAAjo4APTgUXQgIEpoSmRKUXGgVoqOjMhxwF6mqq6YhADs=); }

.wk-document-legacy .dps-resource .cch-pointer:before {
  background-image: url(data:image/gif;base64,R0lGODlhEAAQAMQYALvi/7yUacvp/8Dj/8Xm/97w/8bm/8Dk/9Lr/9Hr/9Hs/9ju/9fu//e+W7vh/5rN8+Tz/2OQsXSs1m0/Ef/ShP+ZM////5lmM////wAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAABgALAAAAAAQABAAAAV1IIZJZGmS4gitbLtGqiVbwWW3TyRBcn3JF5wO4pNRbEibzmakOJ/OiW5igUYnWOzUCp0UCjnJhEvJLhjhSaPBnSAUiXSlsq5jBfg0ds6fGAgEBmEQBwFZWQMHAwODDgCPAA6SjpI5Iw+YmZqYMCIRn6ChnyIhADs=); }

.wk-document-legacy .dps-resource .cch-practice-tip:before {
  background-image: url(data:image/gif;base64,R0lGODlhEAAQAOZnALvh///omf/lj//jhf/trsbm/5J3Wsvp/2c1A//hfZS92eHu+cDj/3BDFZmWkHNHG6WASKyHS/jdiNHs/8vo/6GCY/vfgsrR1ZFoMal/Nn5bNphtLJuWjaq+zMjc6t/CeXROJ9LY229BEoVWF4FqUeDn7Y2Ab8Crls6yd8Dk/4ZaJbKMTKmmn8DGydLl87CNVIFrUd/UyXZJG6qtq8Xm/52kpbvi/8Slapyiou/ZnJbA34JpTqaUgZBxUayMYKqBOJxwKdfu/+jGav/wvcS+t+Px/Kq4wOTb0srn/PrmqNCsV9Ls/7ugfsCib2s8DHhHD97q88WlZmg3BryVQoxeHf3z0PLgraydjbrR4dHr/6B2NqSSfmo6CYVkQqqYhGs6CXVSLf/qo5rN82OQsXSs1uTz/////////wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAAGcALAAAAAAQABAAAAe6gGdnZISFhoSCg2WLizwLjItjimaUZiEIV5BlYmNkZZVmBkwiUJCcnpVHCFU+W6adRTEnFTJNQ1ZfPV5EJacGKhA3OQTESSgvGA8KnQtdEWHQ0WFRDRenZS4aKwHc3B9cLUHXSx5OEgLoAlosE1nXFEhSFgP0AxszBwfXBVhPCUIZlCT4wYFGgWspjFABAsLBjhFTTDBgcA0ADjA1bAAA0IEEjI2cBolRIKakSTE6SkoSNKaly5ctBQUCADs=); }

.wk-document-legacy .dps-resource .exam-standard:before {
  background-image: url(data:image/gif;base64,R0lGODlhEAAQALMPALvi/45kOry8vLvh///wxP/jk5rN8//danSs1uTz/2OQsW0/Ef+ZM5lmM////////yH5BAEAAA8ALAAAAAAQABAAAARv8D1Eq6Vyps37VpojOh5nKEjSrKvKLsnJjs5CL82iNA5P/LYfwabj8QpIG7KwGO56jqUyyXzyDlgb9rCoGh1bbZb7XJgXDOKZfD0wAucAY55WnO+C+xmVGAD+AAOCfoInEwaIiYqIIBIKj5CRjxIRADs=); }

.wk-document-legacy .dps-resource .expert:before {
  background-image: url(data:image/gif;base64,R0lGODlhEAAQAMQZALvi/7vh/6wAAN3v9t7v9uPz/8LT1+n1/8Dj/97x/+H1+eTz/9IAAPe+W//wxP/dansAAP/ShJrN82OQsXSs1m0/Ef+ZM5lmM////////wAAAAAAAAAAAAAAAAAAAAAAACH5BAEAABkALAAAAAAQABAAAAV8YJZRZGmS4nitbHsZ01hZFWbfdyVN1ExXwGBw12sYj8fKYkFcVCLQaLRyIA6eUmml0ExUHOBwWMcjKGo4DASCIVNYlYf8wVa7Lw/anG6D3B8+FoIWazRNQkEQDAwQCE0BAJEAAQECjAIBOyMSnJ0SjIqaIhOkpRMMpKgiIQA7); }

.wk-document-legacy .dps-resource .forms:before {
  background-image: url(data:image/gif;base64,R0lGODlhEAAQALMLAP/dam0/EZrN82OQsf/wxHSs1sVrI/+ZMwAAAJlmM////////wAAAAAAAAAAAAAAACH5BAEAAAsALAAAAAAQABAAAARgcK1Cq6Vypc37DsOUKGRpKoEwFKPhvi+RriNh3/jMKnAvqzsD7mYA6Fo9A6J4VACeUAACYQQiewgFoikcZrVWXvKrpSkO6PSBrGUF3vD3d4qYCO74exahygz+gH9TfxIRADs=); }

.wk-document-legacy .dps-resource .legal:before {
  background-image: url(data:image/gif;base64,R0lGODlhEAAQAMQdANfu/8bm//e+W8Xm/9Hr/8Dk/7vi/97x/9Hs/8vo/7vh/97w/93w/9Ls/9ju/9Lr/+Pz/8Dj/8vp///dav/ShJrN82OQsXSs1m0/EeTz//+ZM////5lmM////wAAAAAAACH5BAEAAB0ALAAAAAAQABAAAAWFYNddZGmS4pitbMa1lrrN28ptGFtZV0bXHAoGt9r1fhwNhkIhGn20DEYjYOKerQwkWR1ieJnDgbE4cCbTJeYJADgAZwwgDXg2CIQ4gvDA7J8JEnEShIGETwFxAQEDA4yOT3ERBRGTlhE7HHEGnAqan5oWcRWkFVxNFAIaHBitFq8WrbKzIQA7); }

.wk-document-legacy .dps-resource .policy:before {
  background-image: url(data:image/gif;base64,R0lGODlhEAAQAMQeAObm5svp/4yMjN7w/9fu/8Xm/7vh/8Dj/4CAgP+ZmaZ+Tsbm/9Hs/7vi/+Pz/9Hr/8Dk/9Lr/9ju//9mZv/wnnSs1prN820/EWOQsaampv+ZAJlmMwAAAP///////wAAACH5BAEAAB4ALAAAAAAQABAAAAWAoOdVZGlyqLetbHtxQJJwW2ffN9cB2UR3maAwo+NlBD+cjUNBBJGY2pBI6WgQSEtUybRqNByHVhrsfsGDwThXPXMIEsI6SDmDLxHGY22j1MEdFwGDcxkUCgocGYEFBQt8OkoXEAcHfEo2Fw0GBloXn6ChFxakGCIeGKmqqx2mHiEAOw==); }

.wk-document-legacy .dps-resource .best-practice:before {
  background-image: url(data:image/gif;base64,R0lGODlhEAAQAMQdAMvp/9fu/8Xm/8Dj/7vh/7vi/8Dk/8bm/9Hr/97w/9Lr/8vo/97x/+Pz/9ju/+n1/9Hs/9Ls//e+W//wxJrN8+Tz/3Ss1v/damOQsW0/Ef+ZM////5lmM////wAAAAAAACH5BAEAAB0ALAAAAAAQABAAAAWAYNdZJGeaJCmOVcVN8KRlbYWxG3dt23VJsxYFY3FNeDwfkDYsvpC9X7Bp3EWlmQf15etiG1QGt7tMJKiBwHgZcASoEQTitVQoIAjqAgCgz/Z8VAcCAhmGhoMCB1QGBgN0EhkDjgNUBAUFh4aXBARDIxShkBmhoTciGKkYh6qpIiEAOw==); }

.wk-document-legacy .dps-resource .BNanoteContainer {
  clear: right;
  margin: 0 45px 15px;
  border-style: solid;
  border-color: #9fb1bc;
  border-width: 1px;
  padding: 3px;
  float: right;
  width: 25%; }

.wk-document-legacy .dps-resource .BNanote {
  background-color: #4e82a3;
  color: white;
  font-size: medium;
  font-weight: bold;
  margin: 0;
  padding-left: 15px; }

.wk-document-legacy .dps-resource .BNanoteBody {
  margin-top: 0;
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  font-size: small;
  margin: 0 5px 15px;
  color: #000000; }

.wk-document-legacy .dps-resource #footer {
  position: relative;
  padding: 0;
  margin: 0;
  height: 32px;
  _height: 31px;
  width: 100%;
  border-top: 1px solid #3366cc; }

.wk-document-legacy .dps-resource .footer {
  position: relative;
  padding: 0;
  margin: 0;
  height: 32px;
  _height: 31px;
  width: 100%;
  border-top: 1px solid #3366cc;
  font-size: 11px;
  font-family: Arial, Helvetica, sans-serif;
  color: #000000;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  color: #757575;
  border-color: #d9dde7;
  padding: 20px 0 17px;
  margin-top: 20px; }

.wk-document-legacy .dps-resource .header {
  position: relative;
  padding: 0;
  margin: 0;
  width: 100%;
  border-bottom: 1px solid #3366cc;
  font-size: 11px;
  font-family: Arial, Helvetica, sans-serif;
  color: #000000; }

.wk-document-legacy .dps-resource .blueRule {
  width: 100%;
  margin: 10px;
  border-top: 1px solid #3366cc; }

.wk-document-legacy .dps-resource #document-separator {
  width: 100%;
  border-bottom: 5px solid #33dddd; }

.wk-document-legacy .dps-resource .smallText {
  padding: 0;
  margin: 0;
  font-size: 11px;
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  color: #000000; }

.wk-document-legacy .dps-resource .hideText {
  display: none; }

.wk-document-legacy .dps-resource .fracNum {
  vertical-align: .2em; }

.wk-document-legacy .dps-resource .fracDen {
  vertical-align: -.2em; }

.wk-document-legacy .dps-resource #menupathIFrame {
  width: 100%;
  height: 0;
  border: 0 none;
  margin: 0; }

.wk-document-legacy .dps-resource .headnote {
  font: small Arial, Helvetica, sans-serif; }

.wk-document-legacy .dps-resource .history {
  font: small Arial, Helvetica, sans-serif; }

.wk-document-legacy .dps-resource .source-info {
  font: small Arial, Helvetica, sans-serif; }

.wk-document-legacy .dps-resource .reference-line {
  font: small Arial, Helvetica, sans-serif; }

.wk-document-legacy .dps-resource .balloonHelpIcon {
  height: 10px;
  width: 10px;
  cursor: help;
  margin-left: 2px;
  margin-right: 2px; }

.wk-document-legacy .dps-resource .not-indented-level {
  margin-left: 0; }

.wk-document-legacy .dps-resource .indented-level {
  margin-left: 20pt;
  margin-left: 0; }
  .wk-document-legacy .dps-resource .indented-level > .indented-level {
    margin-left: 20px; }

.wk-document-legacy .dps-resource .menupath-link {
  display: inline;
  padding-left: 5px; }

.wk-document-legacy .dps-resource .historyVaNum {
  padding-right: 6px;
  white-space: nowrap;
  vertical-align: top; }

.wk-document-legacy .dps-resource .relateBlock {
  display: none;
  display: none; }

.wk-document-legacy .dps-resource .docNotInFocus {
  background-color: #eeeeee; }
  .wk-document-legacy .dps-resource .docNotInFocus a.metadataLink:link {
    color: #eeeeee; }
  .wk-document-legacy .dps-resource .docNotInFocus a.metadataLink:active {
    color: #eeeeee; }
  .wk-document-legacy .dps-resource .docNotInFocus a.metadataLink:visited {
    color: #eeeeee; }
  .wk-document-legacy .dps-resource .docNotInFocus a.metadataLink:hover {
    color: #eeeeee; }

.wk-document-legacy .dps-resource .accmenu {
  font-size: .9em;
  border: 1px solid #a0a0a0;
  width: 180px;
  padding: 5px;
  position: absolute;
  background-color: #f0f0f0;
  border-radius: 3px;
  box-shadow: 0 0 5px #aaaaaa; }
  .wk-document-legacy .dps-resource .accmenu .option {
    padding: 2px 0;
    cursor: pointer; }
    .wk-document-legacy .dps-resource .accmenu .option:hover {
      background-color: #e0f1c6; }
    .wk-document-legacy .dps-resource .accmenu .option.disabled {
      color: gray;
      cursor: default; }
      .wk-document-legacy .dps-resource .accmenu .option.disabled:hover {
        background-color: #f0f0f0; }
    .wk-document-legacy .dps-resource .accmenu .option .labelwrapper {
      display: inline-block;
      zoom: 1;
      *display: inline;
      vertical-align: top; }
    .wk-document-legacy .dps-resource .accmenu .option .img {
      cursor: pointer;
      display: inline-block;
      zoom: 1;
      *display: inline;
      padding-right: 3px; }
    .wk-document-legacy .dps-resource .accmenu .option.printfragment .labelwrapper {
      margin-left: 2px; }

.wk-document-legacy .dps-resource .documentLevelNoteWrap {
  padding-bottom: 10px; }

.wk-document-legacy .dps-resource .sideNoteasFooter {
  border-top: 1px dashed black; }
  .wk-document-legacy .dps-resource .sideNoteasFooter table {
    width: 100%; }

.wk-document-legacy .dps-resource .topanswer {
  background-color: #e9f2f7;
  border: 1px solid #abcce2;
  padding: 6px;
  margin: 10px; }
  .wk-document-legacy .dps-resource .topanswer h2 {
    color: #006699;
    margin: 0 0 10px; }

.wk-document-legacy .dps-resource .docTitle {
  font-size: 22px;
  font-weight: 400;
  color: #323232;
  margin: 0 0 20px; }

.wk-document-legacy .dps-resource .docHeadSpacer {
  font-size: 16px;
  font-weight: 400; }

.wk-document-legacy .dps-resource .hP,
.wk-document-legacy .dps-resource .hP[style] {
  display: block !important;
  font-size: 17px !important; }

.wk-document-legacy .dps-resource .hP {
  color: #323232; }

.wk-document-legacy .dps-resource .docTitle,
.wk-document-legacy .dps-resource .leadIn,
.wk-document-legacy .dps-resource .level-heading {
  font-family: "Fira Sans", "Helvetica Neue", Arial, sans-serif; }
  .wk-document-legacy .dps-resource .docTitle a:hover,
  .wk-document-legacy .dps-resource .leadIn a:hover,
  .wk-document-legacy .dps-resource .level-heading a:hover {
    text-decoration: none; }

.wk-document-legacy .dps-resource .leadIn .smallCap, .wk-document-legacy .dps-resource .leadIn .smallCap[style],
.wk-document-legacy .dps-resource .level-heading .smallCap,
.wk-document-legacy .dps-resource .level-heading .smallCap[style] {
  font-size: 19px !important;
  font-weight: 700;
  line-height: normal; }

.wk-document-legacy .dps-resource .leadIn i,
.wk-document-legacy .dps-resource .level-heading i {
  font-style: normal; }

.wk-document-legacy .dps-resource .leadIn,
.wk-document-legacy .dps-resource .level-heading {
  font-size: 19px;
  font-weight: 700; }

.wk-document-legacy .dps-resource .leadIn {
  line-height: normal;
  margin-bottom: 4px; }
  .wk-document-legacy .dps-resource .leadIn > b {
    font-weight: normal; }

.wk-document-legacy .dps-resource .level-heading {
  margin: 0; }

.wk-document-legacy .dps-resource .smallLink a {
  font-size: 11px;
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  color: #0000cc;
  text-decoration: none; }
  .wk-document-legacy .dps-resource .smallLink a:hover {
    color: #0000ff;
    text-decoration: underline; }

.wk-document-legacy .dps-resource thead.xhtml {
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: bold;
  font-style: normal; }

.wk-document-legacy .dps-resource hr.rule {
  color: black;
  background-color: black;
  border-width: thin;
  height: 1pt; }

.wk-document-legacy .dps-resource .hlOn .docSearchTerm span.hl-none {
  background-color: #ffff66;
  font-weight: bold; }

.wk-document-legacy .dps-resource .hlOn .docSearchTerm span.hl-yellow {
  background-color: #ffff66;
  font-weight: bold; }

.wk-document-legacy .dps-resource .hlOn .docSearchTerm span.hl-pink {
  background-color: #ffff66;
  font-weight: bold; }

.wk-document-legacy .dps-resource .hlOn .docSearchTerm span.hl-orange {
  background-color: #ffff66;
  font-weight: bold; }

.wk-document-legacy .dps-resource .hlOn .docSearchTerm span.hl-green {
  background-color: #ffff66;
  font-weight: bold; }

.wk-document-legacy .dps-resource .hlOn .docSearchTerm span.hl-blue {
  background-color: #ffff66;
  font-weight: bold; }

.wk-document-legacy .dps-resource .dateUpdated .modifiedLabel {
  font-weight: bold; }

.wk-document-legacy .dps-resource .nav-caution-note-wrapper[style] {
  margin-left: 0 !important; }
  .wk-document-legacy .dps-resource .nav-caution-note-wrapper[style] tr[style] {
    padding-bottom: 0 !important; }
  .wk-document-legacy .dps-resource .nav-caution-note-wrapper[style] .nav-caution-note {
    display: none; }
  .wk-document-legacy .dps-resource .nav-caution-note-wrapper[style] .cautionImgR {
    display: none; }

.wk-document-legacy .dps-resource .nav-caution-note {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAMCAIAAAALR8HoAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDowQUZDM0E4NzgwRTAxMUU1QTk0Q0ZGRTI2OEM5NDEzNyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDowQUZDM0E4ODgwRTAxMUU1QTk0Q0ZGRTI2OEM5NDEzNyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjBBRkMzQTg1ODBFMDExRTVBOTRDRkZFMjY4Qzk0MTM3IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjBBRkMzQTg2ODBFMDExRTVBOTRDRkZFMjY4Qzk0MTM3Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+SQxZMQAAAF1JREFUeNqU0ssJADEIBNB8StI2LN2ajOyKgUVWnZOHeUQwU0TGE2YeUQDA57ezusBMC2g2EbXA3a0OYvMPApOCr6kA7ZjRK6XAO2YQMX3BO6u+ks/T/079uEeAAQCPfDz5xZEe4wAAAABJRU5ErkJggg==);
  background-repeat: repeat;
  height: 100%;
  margin-left: -37px;
  position: absolute;
  width: 17px; }

.wk-document-legacy .dps-resource .cautionText {
  background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAARElEQVQYlYXOMQ4AIAhD0crG/Q/bEReMARH+/NJ0mRlOJFElo1BVGYUv9QKAjCJ8+gmSBUqiWHpFRqUI6CcuaoSjXgDYStwm+pRLfNoAAAAASUVORK5CYII=) 0 0 repeat;
  border: 1px solid #a8a8a8;
  color: #000;
  font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
  font-size: 17px;
  font-weight: 300;
  line-height: 24px;
  margin-bottom: 25px;
  padding: 10px 10px;
  text-align: center; }
  .wk-document-legacy .dps-resource .cautionText:before {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2015%2014%22%3E%3Cpath%20fill%3D%22%23EC373B%22%20d%3D%22M8.044%200.151C7.877%200.05%207.694%200%207.501%200%207.305%200%207.125%200.05%206.958%200.151%206.79%200.252%206.659%200.389%206.564%200.562L0.143%2012.41c-0.195%200.354-0.19%200.707%200.016%201.061%200.095%200.163%200.223%200.291%200.388%200.387C0.712%2013.953%200.889%2014%201.079%2014h12.843c0.188%200%200.367-0.047%200.531-0.143%200.163-0.096%200.294-0.224%200.388-0.387%200.206-0.354%200.212-0.707%200.018-1.061L8.438%200.562C8.342%200.389%208.211%200.252%208.044%200.151%22%2F%3E%3Cg%20id%3D%22XMLID_1_%22%3E%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M8.57%209.97v1.601c0%200.08-0.03%200.14-0.08%200.199%20-0.05%200.051-0.11%200.08-0.19%200.08H6.7c-0.08%200-0.14-0.029-0.19-0.08%20-0.05-0.06-0.08-0.119-0.08-0.199V9.97c0-0.08%200.03-0.14%200.08-0.2C6.56%209.72%206.62%209.69%206.7%209.69h1.6c0.08%200%200.141%200.029%200.19%200.079C8.54%209.83%208.57%209.89%208.57%209.97zM8.71%204.56L8.55%208.42c0%200.061-0.03%200.1-0.09%200.14C8.41%208.6%208.35%208.62%208.27%208.62H6.72c-0.08%200-0.14-0.021-0.2-0.061C6.47%208.52%206.44%208.48%206.44%208.42L6.3%204.58c0-0.08%200.02-0.14%200.08-0.18%200.07-0.06%200.14-0.09%200.2-0.09h1.84c0.061%200%200.13%200.03%200.2%200.09C8.68%204.44%208.71%204.49%208.71%204.56z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
    background-position: 0 0;
    background-repeat: no-repeat;
    content: '';
    display: inline-block;
    height: 15px;
    margin-right: 0.3125rem;
    position: relative;
    top: 1px;
    width: 15px; }

.wk-document-legacy .dps-resource .endnotes {
  border-top: 1px solid #dee3ea;
  margin-top: 35px; }
  .wk-document-legacy .dps-resource .endnotes .fnBoxL {
    display: none; }
  .wk-document-legacy .dps-resource .endnotes .endBoxTop > h1, .wk-document-legacy .dps-resource .endnotes .endBoxTop > h2, .wk-document-legacy .dps-resource .endnotes .endBoxTop > h3, .wk-document-legacy .dps-resource .endnotes .endBoxTop > h4, .wk-document-legacy .dps-resource .endnotes .endBoxTop > h5, .wk-document-legacy .dps-resource .endnotes .endBoxTop > h6 {
    font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px; }
  .wk-document-legacy .dps-resource .endnotes .endBoxBottom > table > tbody > tr[style] {
    padding-bottom: 0 !important; }
  .wk-document-legacy .dps-resource .endnotes .tableNote sup {
    top: auto; }
  .wk-document-legacy .dps-resource .endnotes .footnoteRef,
  .wk-document-legacy .dps-resource .endnotes .footnote {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; }
  .wk-document-legacy .dps-resource .endnotes .footnoteRef {
    font-size: 15px;
    font-weight: 700;
    position: relative;
    top: 1px; }
  .wk-document-legacy .dps-resource .endnotes .footnote {
    font-size: 15px !important;
    line-height: 18px;
    margin-bottom: 11px;
    margin-left: 15px; }

.wk-pagination-bar {
  font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
  font-weight: 400;
  margin-bottom: 1.25rem; }
  .wk-pagination-bar::after {
    clear: both;
    content: ' ';
    display: table; }
  @media (min-width: 37.5rem) {
    .wk-pagination-bar > .wk-pagination {
      float: right; } }
  .wk-pagination-bar > .wk-pagination-results {
    font-size: 0.9375rem; }
    @media (min-width: 37.5rem) {
      .wk-pagination-bar > .wk-pagination-results {
        float: left; }
        .wk-pagination-bar > .wk-pagination-results .wk-field-item {
          margin-bottom: 0;
          margin-top: 0.125rem; }
          .wk-pagination-bar > .wk-pagination-results .wk-field-item .wk-field-header,
          .wk-pagination-bar > .wk-pagination-results .wk-field-item .wk-select-field {
            float: left; }
          .wk-pagination-bar > .wk-pagination-results .wk-field-item .wk-select-field {
            margin-bottom: 0; }
            .wk-pagination-bar > .wk-pagination-results .wk-field-item .wk-select-field::after {
              top: 1em; }
          .wk-pagination-bar > .wk-pagination-results .wk-field-item .wk-field-header {
            margin-right: 0.625rem;
            margin-top: 0.9375rem; } }

.wk-pagination {
  display: table;
  list-style-type: none;
  padding: 0;
  width: 100%; }
  @media (min-width: 37.5rem) {
    .wk-pagination {
      display: inline-block;
      width: auto; } }
  .wk-pagination > li {
    display: none;
    text-align: center;
    vertical-align: top; }
    @media (min-width: 37.5rem) {
      .wk-pagination > li {
        display: block;
        float: left; }
        .wk-pagination > li:last-child, .wk-pagination > li:first-child {
          display: inline-block; } }
    .wk-pagination > li > a {
      background-color: #a6d1ea;
      color: #007ac3;
      display: block;
      font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
      font-weight: 400;
      line-height: 1.875rem;
      padding: 0.5625rem 1.25rem;
      transition: background-color .1s ease-out; }
      .wk-pagination > li > a.wk-button-icon {
        padding: 0.5625rem; }
      .wk-pagination > li > a:hover {
        background-color: #80bde1;
        color: #007ac3; }
    .wk-pagination > li:last-child, .wk-pagination > li:first-child {
      display: table-cell; }
      .wk-pagination > li:last-child a, .wk-pagination > li:first-child a {
        background-color: #007ac3;
        color: #fff; }
      .wk-pagination > li:last-child a:hover, .wk-pagination > li:first-child a:hover {
        background-color: #409bd2; }
    .wk-pagination > li.wk-disabled {
      position: relative; }
      .wk-pagination > li.wk-disabled a,
      .wk-pagination > li.wk-disabled:first-child a,
      .wk-pagination > li.wk-disabled:last-child a {
        background: #dadada;
        color: #fff;
        cursor: not-allowed;
        pointer-events: none; }
    .wk-pagination > li.wk-disabled::before {
      background: transparent;
      content: '';
      cursor: not-allowed;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%; }
  .wk-pagination > .wk-active {
    display: table-cell;
    width: 80%; }
    @media (min-width: 37.5rem) {
      .wk-pagination > .wk-active {
        width: auto; } }
    .wk-pagination > .wk-active a {
      background-color: #fff;
      color: #007ac3; }

.wk-list {
  background-color: #fff;
  border: 1px solid #bfbfbf;
  border-radius: 0;
  font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
  list-style-type: none;
  padding: 0.84746% 0; }
  .wk-list > li,
  .wk-list dd,
  .wk-list dt {
    padding: 0.3125rem 1.69492%; }
  .wk-list > li:hover,
  .wk-list dd:hover {
    background-color: #ededed;
    cursor: pointer; }
  .wk-list > li:focus, .wk-list > li.wk-active,
  .wk-list dd:focus,
  .wk-list dd.wk-active {
    background-color: #409bd2;
    color: #fff; }
  .wk-list > dt {
    color: #ec5862; }
  .wk-list > dd,
  .wk-list li {
    margin-left: 0; }

.wk-modal {
  bottom: 0;
  display: none;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1050; }
  .wk-modal.wk-modal-opened {
    display: inline; }
  .wk-modal.wk-modal-small .wk-modal-content {
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto; }
    .wk-modal.wk-modal-small .wk-modal-content:after {
      content: " ";
      display: block;
      clear: both; }
    @media (min-width: 37.5rem) {
      .wk-modal.wk-modal-small .wk-modal-content {
        max-width: 28.125rem; } }
  .wk-modal.wk-modal-medium .wk-modal-content {
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 37.5rem; }
    .wk-modal.wk-modal-medium .wk-modal-content:after {
      content: " ";
      display: block;
      clear: both; }
  .wk-modal.wk-modal-large .wk-modal-content {
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto; }
    .wk-modal.wk-modal-large .wk-modal-content:after {
      content: " ";
      display: block;
      clear: both; }
    @media (min-width: 37.5rem) and (max-width: 65rem) {
      .wk-modal.wk-modal-large .wk-modal-content {
        max-width: 37.5rem; } }
    @media (min-width: 65rem) {
      .wk-modal.wk-modal-large .wk-modal-content {
        max-width: 65rem; } }
  .wk-modal.wk-modal-with-overlay::before {
    background-color: rgba(0, 0, 0, 0.4);
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: -1; }
  .wk-modal.wk-modal-vertical-middle .wk-modal-content {
    left: 50%;
    margin-top: 0;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .wk-modal.wk-modal-vertical-middle.wk-modal-large-content .wk-modal-content {
    left: auto;
    margin-top: 30px;
    position: static;
    top: auto;
    -webkit-transform: none;
        -ms-transform: none;
            transform: none; }
  .wk-modal .wk-modal-content {
    background: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4); }
    @media (min-width: 37.5rem) {
      .wk-modal .wk-modal-content {
        margin: 30px; } }
    .wk-modal .wk-modal-content .wk-modal-close {
      color: #a6d1ea;
      cursor: pointer;
      font-size: 2.0625rem;
      position: absolute;
      right: 0;
      top: 0;
      transition: all .1s ease-out;
      width: auto; }
    .wk-modal .wk-modal-content button.wk-button-icon.wk-modal-close {
      background-color: transparent;
      padding: 0.4375rem; }
    .wk-modal .wk-modal-content .wk-modal-close:hover {
      color: #fff; }
    .wk-modal .wk-modal-content .wk-modal-close:focus {
      color: #fff; }
    .wk-modal .wk-modal-content .wk-modal-header {
      background: #007ac3;
      min-height: 3rem;
      padding-bottom: 0.8125rem;
      padding-left: 1rem;
      padding-right: 3.75rem;
      padding-top: 0.8125rem;
      position: relative; }
      .wk-modal .wk-modal-content .wk-modal-header .wk-modal-title {
        color: #fff;
        font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
        font-size: 1rem;
        font-weight: 400;
        margin: 0; }
    .wk-modal .wk-modal-content .wk-modal-body {
      padding: 1rem;
      position: relative; }
    .wk-modal .wk-modal-content .wk-modal-footer {
      padding: 0 1rem 1rem; }

.wk-modal-body-hidden {
  overflow: hidden; }

.wk-nav {
  list-style: none;
  margin: 0;
  padding: 0; }
  .wk-nav::after {
    clear: both;
    content: ' ';
    display: table; }
  .wk-nav [class^='wk-icon-']::before,
  .wk-nav [class*=' wk-icon-']::before {
    font-size: 1.375rem;
    position: relative;
    top: 0.125rem;
    line-height: 0; }
  .wk-nav > li {
    display: block;
    float: none; }
    @media (min-width: 37.5rem) {
      .wk-nav > li {
        float: left; }
        .wk-nav > li::after {
          clear: both;
          content: ' ';
          display: table; } }
    .wk-nav > li > a {
      display: block;
      font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
      font-size: 0.9375rem;
      padding: 0.75rem 0.9375rem; }
    .wk-nav > li.active > a,
    .wk-nav > li[wk-active] > a,
    .wk-nav > li.wk-active > a {
      color: #000; }
  .wk-nav.wk-nav-tabs {
    border-bottom: 0;
    margin-bottom: -0.0625rem;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap; }
    .wk-nav.wk-nav-tabs > li {
      display: inline-block; }
      @media (min-width: 37.5rem) {
        .wk-nav.wk-nav-tabs > li {
          float: none; }
          .wk-nav.wk-nav-tabs > li > a {
            border: 1px solid transparent;
            border-bottom: 0;
            margin-bottom: -0.0625rem; } }
      .wk-nav.wk-nav-tabs > li.active > a,
      .wk-nav.wk-nav-tabs > li[wk-active] > a,
      .wk-nav.wk-nav-tabs > li.wk-active > a {
        background-color: #fff;
        border: 1px solid #dadada;
        border-bottom: 0;
        color: #474747; }
      .wk-nav.wk-nav-tabs > li.active:first-child, .wk-nav.wk-nav-tabs > li.active:last-child, .wk-nav.wk-nav-tabs > li[wk-active]:first-child, .wk-nav.wk-nav-tabs > li[wk-active]:last-child, .wk-nav.wk-nav-tabs > li.wk-active:first-child, .wk-nav.wk-nav-tabs > li.wk-active:last-child {
        position: relative; }
        .wk-nav.wk-nav-tabs > li.active:first-child > a::before, .wk-nav.wk-nav-tabs > li.active:last-child > a::before, .wk-nav.wk-nav-tabs > li[wk-active]:first-child > a::before, .wk-nav.wk-nav-tabs > li[wk-active]:last-child > a::before, .wk-nav.wk-nav-tabs > li.wk-active:first-child > a::before, .wk-nav.wk-nav-tabs > li.wk-active:last-child > a::before {
          border-top: 1px solid #dadada;
          content: ' ';
          display: block;
          height: 100%;
          position: absolute;
          top: 0;
          width: 20px;
          z-index: 999; }
      .wk-nav.wk-nav-tabs > li[wk-active]:first-child > a::before, .wk-nav.wk-nav-tabs > li.wk-active:first-child > a::before {
        border-left: 1px solid #dadada;
        left: 0; }
      .wk-nav.wk-nav-tabs > li.active:last-child > a::before, .wk-nav.wk-nav-tabs > li.wk-active:last-child > a::before {
        border-right: 1px solid #dadada;
        right: 0; }

html.touch .wk-tab-gradient .wk-nav-tabs {
  -webkit-overflow-scrolling: auto;
  overflow-x: scroll; }

.wk-nav-tab-bar {
  border-bottom: 1px solid #dadada;
  margin-bottom: 0.9375rem; }
  .wk-nav-tab-bar .wk-tab-bar-container {
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem; }
    .wk-nav-tab-bar .wk-tab-bar-container:after {
      content: " ";
      display: block;
      clear: both; }
    @media (min-width: 77rem) {
      .wk-nav-tab-bar .wk-tab-bar-container {
        padding-left: 0;
        padding-right: 0; } }
    .wk-nav-tab-bar .wk-tab-bar-container .wk-tab-bar-gradient {
      position: relative; }
      .wk-nav-tab-bar .wk-tab-bar-container .wk-tab-bar-gradient .wk-tab-bar-gradient-before,
      .wk-nav-tab-bar .wk-tab-bar-container .wk-tab-bar-gradient .wk-tab-bar-gradient-after {
        height: 97%;
        position: absolute;
        top: 0;
        width: 20px;
        z-index: 999; }
      .wk-nav-tab-bar .wk-tab-bar-container .wk-tab-bar-gradient .wk-tab-bar-gradient-before {
        background: linear-gradient(to right, #fff, rgba(255, 255, 255, 0)) 0% 0%;
        left: 0; }
      .wk-nav-tab-bar .wk-tab-bar-container .wk-tab-bar-gradient .wk-tab-bar-gradient-after {
        background: linear-gradient(to left, #fff, rgba(255, 255, 255, 0)) 0% 0%;
        right: 0; }

.wk-nav.wk-nav-stacked > li {
  display: block;
  float: none; }
  .wk-nav.wk-nav-stacked > li > a {
    display: block;
    font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
    font-size: 0.9375rem;
    padding: 0.75rem 0.9375rem; }

.wk-product-name-bar {
  background-color: #85bc20; }
  .wk-product-name-bar > .wk-product-name {
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem; }
    .wk-product-name-bar > .wk-product-name:after {
      content: " ";
      display: block;
      clear: both; }
    .wk-product-name-bar > .wk-product-name:after {
      content: " ";
      display: block;
      clear: both; }
    @media (min-width: 77rem) {
      .wk-product-name-bar > .wk-product-name {
        padding-left: 0;
        padding-right: 0; } }

.wk-product-name {
  background-color: #85bc20;
  color: #fff;
  font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
  font-size: 1.06667rem;
  font-weight: 300;
  padding: 0.78125rem 0.9375rem;
  text-align: right; }

.wk-navbar {
  background-color: #007ac3;
  position: relative;
  z-index: 1000; }
  .wk-navbar .wk-navbar-container {
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;
    background-color: #007ac3;
    position: static; }
    .wk-navbar .wk-navbar-container:after {
      content: " ";
      display: block;
      clear: both; }
  .wk-navbar .wk-product-name {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    z-index: 2; }
    @media (min-width: 37.5rem) {
      .wk-navbar .wk-product-name {
        float: right;
        text-align: left; } }
    .wk-navbar .wk-product-name::after {
      background-color: #85bc20;
      bottom: 0;
      content: ' ';
      display: block;
      left: 0;
      margin-left: 50%;
      position: absolute;
      right: 0;
      top: 0;
      width: 50%;
      z-index: -1; }
  .wk-navbar .wk-more-menu {
    list-style: none;
    margin: 0;
    padding: 0;
    background-color: #007ac3;
    float: left;
    font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif; }
    .wk-navbar .wk-more-menu > li {
      display: block; }
      .wk-navbar .wk-more-menu > li > a {
        display: block; }
    @media (min-width: 37.5rem) {
      .wk-navbar .wk-more-menu {
        display: none; } }
    .wk-navbar .wk-more-menu .wk-icon-menu {
      display: inline-block;
      vertical-align: middle; }
      .wk-navbar .wk-more-menu .wk-icon-menu::before {
        font-size: 1.625rem;
        margin-left: 0;
        position: relative;
        top: -0.0625rem;
        vertical-align: middle; }
    .wk-navbar .wk-more-menu a {
      transition: background-color 0.1s ease-out;
      color: #fff;
      padding: 0.75rem 1rem 0.625rem; }
      .wk-navbar .wk-more-menu a:focus, .wk-navbar .wk-more-menu a:hover {
        background-color: #80bde1;
        color: #fff; }
    .wk-navbar .wk-more-menu.open {
      background-color: #005b92; }
      .wk-navbar .wk-more-menu.open .wk-icon-menu::before {
        content: '\e833'; }
  .wk-navbar .wk-nav {
    display: none; }
    @media (min-width: 37.5rem) {
      .wk-navbar .wk-nav {
        display: block;
        float: left; } }
    .wk-navbar .wk-nav.open {
      display: block;
      position: absolute;
      top: 3rem;
      width: 100%;
      z-index: 1040; }
      @media (min-width: 37.5rem) {
        .wk-navbar .wk-nav.open {
          position: relative;
          top: 0;
          width: auto; } }
      .wk-navbar .wk-nav.open > li > a {
        background-color: rgba(0, 122, 195, 0.95); }
    .wk-navbar .wk-nav > li > a {
      transition: background-color 0.1s ease-out;
      background-color: #007ac3;
      color: #fff;
      padding: 0.84375rem 0.9375rem; }
      @media (min-width: 37.5rem) {
        .wk-navbar .wk-nav > li > a {
          border: 0;
          margin: 0; } }
    .wk-navbar .wk-nav > li.active > a,
    .wk-navbar .wk-nav > li[wk-active] > a,
    .wk-navbar .wk-nav > li.wk-active > a {
      background-color: #005b92; }
    .wk-navbar .wk-nav > li > a:focus, .wk-navbar .wk-nav > li > a:hover,
    .wk-navbar .wk-nav > li.active > a:focus,
    .wk-navbar .wk-nav > li.active > a:hover,
    .wk-navbar .wk-nav > li[wk-active] > a:focus,
    .wk-navbar .wk-nav > li[wk-active] > a:hover,
    .wk-navbar .wk-nav > li.wk-active > a:focus,
    .wk-navbar .wk-nav > li.wk-active > a:hover {
      background-color: #80bde1; }
    .wk-navbar .wk-nav.wk-nav-tabs {
      border: 0; }
      @media (min-width: 37.5rem) and (max-width: 65rem) {
        .wk-navbar .wk-nav.wk-nav-tabs {
          margin-left: 0.75rem; } }
      @media (min-width: 37.5rem) {
        .wk-navbar .wk-nav.wk-nav-tabs > li > a {
          margin-top: 0.375rem;
          padding: 0.375rem 0.625rem; } }
      .wk-navbar .wk-nav.wk-nav-tabs > li {
        margin-bottom: -0.0625rem; }
  .wk-navbar .wk-breadcrumb [class^='wk-icon-']::before,
  .wk-navbar .wk-breadcrumb [class*=' wk-icon-']::before {
    color: #80bde1; }
  .wk-navbar .wk-breadcrumb > li {
    color: #fff; }
    .wk-navbar .wk-breadcrumb > li:not(:first-child)::before {
      color: #80bde1; }
    .wk-navbar .wk-breadcrumb > li > a {
      color: #f6f6f6; }
    .wk-navbar .wk-breadcrumb > li > a:focus,
    .wk-navbar .wk-breadcrumb > li > a:hover {
      color: #d4e8b1; }

.wk-search-bar {
  background-color: #f6f6f6;
  padding-bottom: 1rem;
  padding-top: 1rem; }
  .wk-search-bar input.wk-search-input[type='search'] {
    border-color: transparent; }
  .wk-search-bar .wk-search {
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem; }
    .wk-search-bar .wk-search:after {
      content: " ";
      display: block;
      clear: both; }
    @media (min-width: 77rem) {
      .wk-search-bar .wk-search {
        padding-left: 0;
        padding-right: 0; } }

html.ie9 .wk-search,
html.no-flexbox .wk-search {
  display: block; }
  html.ie9 .wk-search .wk-select-field,
  html.no-flexbox .wk-search .wk-select-field {
    float: left; }
  html.ie9 .wk-search .wk-search-box,
  html.no-flexbox .wk-search .wk-search-box {
    margin-left: 7rem;
    width: auto; }
    @media (min-width: 37.5rem) {
      html.ie9 .wk-search .wk-search-box,
      html.no-flexbox .wk-search .wk-search-box {
        margin-left: 8rem; } }
  html.ie9 .wk-search .wk-search-container,
  html.no-flexbox .wk-search .wk-search-container {
    display: block;
    margin-right: 3rem;
    width: auto; }
    @media (min-width: 37.5rem) {
      html.ie9 .wk-search .wk-search-container,
      html.no-flexbox .wk-search .wk-search-container {
        margin-right: 5rem; } }
  html.ie9 .wk-search .wk-search-submit,
  html.no-flexbox .wk-search .wk-search-submit {
    float: right;
    height: 3rem;
    width: 3rem; }
    @media (min-width: 37.5rem) {
      html.ie9 .wk-search .wk-search-submit,
      html.no-flexbox .wk-search .wk-search-submit {
        width: 5rem; } }

.wk-search {
  -webkit-align-content: stretch;
      -ms-flex-line-pack: stretch;
          align-content: stretch;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
  .wk-search .wk-select-field {
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
    -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
            flex-shrink: 0;
    -webkit-order: 0;
        -ms-flex-order: 0;
            order: 0;
    width: 7rem; }
    @media (min-width: 37.5rem) {
      .wk-search .wk-select-field {
        width: 8rem; } }
    .wk-search .wk-select-field > select {
      background-color: #dadada;
      color: #474747;
      font-size: 0.87891rem; }
  .wk-search .wk-search-box {
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
    -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -webkit-flex-shrink: 1;
        -ms-flex-negative: 1;
            flex-shrink: 1;
    width: 100%; }
    .wk-search .wk-search-box input.wk-search-input[type='search'] {
      border-left-color: transparent; }
      .wk-search .wk-search-box input.wk-search-input[type='search']:hover {
        border-left-color: #474747; }
      .wk-search .wk-search-box input.wk-search-input[type='search']:focus, .wk-search .wk-search-box input.wk-search-input[type='search']:hover:focus {
        border-color: #409bd2; }
  .wk-search .wk-search-box,
  .wk-search .wk-search-container {
    position: relative; }
    .wk-search .wk-search-box > .wk-list,
    .wk-search .wk-search-container > .wk-list {
      display: none; }
  .wk-search .wk-list {
    margin: 0;
    max-height: 12.5rem;
    overflow: auto;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 2000; }
    @media (min-width: 37.5rem) {
      .wk-search .wk-list {
        max-height: 18.75rem; } }
    .wk-search .wk-list.wk-active {
      display: block; }

.wk-search-container {
  -webkit-align-content: stretch;
      -ms-flex-line-pack: stretch;
          align-content: stretch;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-flex-grow: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  -webkit-flex-shrink: 1;
      -ms-flex-negative: 1;
          flex-shrink: 1;
  -webkit-order: 0;
      -ms-flex-order: 0;
          order: 0;
  width: 100%; }

input.wk-search-input[type='search'] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #fff;
  border: 1px solid #dadada;
  border-radius: 0;
  color: #474747;
  font-family: "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
  font-size: 1rem;
  height: 3rem;
  line-height: 1.5;
  padding: 0.6875rem 1rem;
  width: 100%;
  border-right-color: transparent;
  box-sizing: border-box;
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
  -webkit-flex-grow: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  -webkit-flex-shrink: 1;
      -ms-flex-negative: 1;
          flex-shrink: 1;
  -webkit-order: 1;
      -ms-flex-order: 1;
          order: 1;
  overflow: hidden;
  text-overflow: ellipsis; }
  input.wk-search-input[type='search'][disabled] {
    background-color: #ededed;
    color: #ededed;
    cursor: not-allowed; }
  input.wk-search-input[type='search']:focus, input.wk-search-input[type='search']:hover:focus {
    border-color: #409bd2; }
  input.wk-search-input[type='search']:hover {
    border-color: #474747; }
  input.wk-search-input[type='search']::-ms-clear {
    height: 0;
    width: 0; }
  input.wk-search-input[type='search']:-moz-placeholder, input.wk-search-input[type='search']::-moz-placeholder, input.wk-search-input[type='search']:-ms-input-placeholder, input.wk-search-input[type='search']::-webkit-input-placeholder {
    color: #a3a3a3; }

.wk-search-submit {
  background-color: #007ac3;
  color: #fff;
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
  -webkit-flex-grow: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  font-size: 1.3rem;
  margin-bottom: 0;
  -webkit-order: 1;
      -ms-flex-order: 1;
          order: 1;
  padding: 0 0.375rem;
  text-align: center;
  width: auto; }
  @media (min-width: 37.5rem) {
    .wk-search-submit {
      padding: 0 0.9375rem; } }
  .wk-search-submit:hover:not([disabled]) {
    background-color: #409bd2; }
  @media (min-width: 37.5rem) {
    .wk-search-submit .wk-icon-search {
      display: none; } }
  .wk-search-submit .wk-search-button-text {
    display: none; }
    @media (min-width: 37.5rem) {
      .wk-search-submit .wk-search-button-text {
        display: inline;
        font-size: 0.87891rem; } }

.wk-search-results {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1em; }
  .wk-search-results:after {
    content: " ";
    display: block;
    clear: both; }
  .wk-search-results.clustered .wk-search-results-group {
    border: 1px solid #a6d1ea;
    margin-bottom: .8em;
    padding: .5em; }
    @media (min-width: 37.5rem) {
      .wk-search-results.clustered .wk-search-results-group {
        width: 49.15254%;
        float: left;
        margin-right: 1.69492%;
        margin-bottom: 1rem; }
        .wk-search-results.clustered .wk-search-results-group:nth-child(even) {
          float: right;
          margin-right: 0; } }
    .wk-search-results.clustered .wk-search-results-group dd:last-of-type {
      margin-bottom: 0; }
  .wk-search-results.clustered a.show-more {
    display: block;
    margin-top: 1rem; }

dl.wk-search-results-list {
  margin: 0; }
  dl.wk-search-results-list dt {
    margin-bottom: .5rem; }
    dl.wk-search-results-list dt .definition {
      color: #757575;
      font-weight: 200;
      margin-bottom: .1rem;
      text-transform: uppercase; }
    dl.wk-search-results-list dt .title {
      color: #007ac3;
      cursor: pointer;
      text-decoration: none; }
      dl.wk-search-results-list dt .title:hover {
        color: #85bc20; }
  dl.wk-search-results-list dd {
    color: #a3a3a3;
    margin: 0 0 1rem; }

.wk-content-filter-layout {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto; }
  .wk-content-filter-layout:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 37.5rem) and (max-width: 57rem) {
    .wk-content-filter-layout .wk-content-filter-pane {
      width: 32.20339%;
      float: left;
      margin-right: 1.69492%; } }
  @media (min-width: 57rem) {
    .wk-content-filter-layout .wk-content-filter-pane {
      width: 23.72881%;
      float: left;
      margin-right: 1.69492%; } }
  @media (min-width: 37.5rem) and (max-width: 57rem) {
    .wk-content-filter-layout .wk-content-filter-results {
      width: 66.10169%;
      float: left;
      margin-right: 1.69492%;
      float: right;
      margin-right: 0; } }
  @media (min-width: 57rem) {
    .wk-content-filter-layout .wk-content-filter-results {
      width: 74.57627%;
      float: left;
      margin-right: 1.69492%;
      float: right;
      margin-right: 0; } }

.wk-content-filter {
  margin-bottom: 0.625rem; }
  .wk-content-filter a {
    font-size: 0.87891rem; }

.wk-content-filter-title {
  color: #474747;
  font-size: 1rem;
  font-weight: 300;
  margin-top: 0;
  text-transform: uppercase; }

.wk-content-filter-list {
  list-style: none;
  margin: 0;
  padding: 0; }
  .wk-content-filter-list > li {
    display: block; }
    .wk-content-filter-list > li > a {
      display: block; }
  .wk-content-filter-list > li {
    margin-bottom: 0.0625rem; }
    .wk-content-filter-list > li::after {
      clear: both;
      content: ' ';
      display: table; }
    .wk-content-filter-list > li > a {
      background: #ededed;
      color: #757575;
      font-size: 0.87891rem;
      font-weight: 400;
      padding: 0.875rem 0.625rem; }
      .wk-content-filter-list > li > a .wk-content-filter-label {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
      .wk-content-filter-list > li > a .wk-badge {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        background-color: transparent;
        border-radius: 0;
        color: #757575;
        float: right;
        font-size: 0.87891rem;
        font-weight: 300;
        margin-left: 0.5rem;
        max-width: 3.75rem;
        padding: 0;
        top: auto; }
        @media (min-width: 37.5rem) and (max-width: 57rem) {
          .wk-content-filter-list > li > a .wk-badge {
            display: none; } }
    .wk-content-filter-list > li:last-child {
      margin-bottom: 0; }
    .wk-content-filter-list > li.active > a,
    .wk-content-filter-list > li[wk-active] > a,
    .wk-content-filter-list > li.wk-active > a {
      background: #757575;
      color: #fff; }
      .wk-content-filter-list > li.active > a .wk-badge,
      .wk-content-filter-list > li[wk-active] > a .wk-badge,
      .wk-content-filter-list > li.wk-active > a .wk-badge {
        color: #fff; }
    .wk-content-filter-list > li.wk-disabled > a,
    .wk-content-filter-list > li[wk-disabled] > a {
      cursor: default; }
      .wk-content-filter-list > li.wk-disabled > a .wk-badge,
      .wk-content-filter-list > li[wk-disabled] > a .wk-badge {
        display: none; }
  .wk-content-filter-list > li > a:focus,
  .wk-content-filter-list > li:hover > a {
    background-color: #dadada;
    color: #757575; }
  .wk-content-filter-list > li.wk-disabled > a,
  .wk-content-filter-list > li[wk-disabled] > a,
  .wk-content-filter-list > li.wk-disabled > a:focus,
  .wk-content-filter-list > li[wk-disabled] > a:focus,
  .wk-content-filter-list > li.wk-disabled:hover > a,
  .wk-content-filter-list > li[wk-disabled]:hover > a {
    background-color: #ededed;
    color: #bfbfbf; }

.wk-breadcrumb-bar {
  background-color: #f6f6f6;
  margin-bottom: 1.69492%;
  position: relative;
  z-index: 1; }
  .wk-breadcrumb-bar .wk-breadcrumb-container {
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;
    background-color: #f6f6f6; }
    .wk-breadcrumb-bar .wk-breadcrumb-container:after {
      content: " ";
      display: block;
      clear: both; }

.wk-breadcrumb {
  display: none;
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 0;
  list-style-type: none;
  margin: 0;
  padding-bottom: 0.84375rem;
  padding-top: 0.84375rem; }
  .wk-breadcrumb:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 77rem) {
    .wk-breadcrumb {
      padding-left: 0;
      padding-right: 0; } }
  @media (min-width: 37.5rem) {
    .wk-breadcrumb {
      display: block;
      float: left; } }
  .wk-breadcrumb > li {
    color: #a3a3a3;
    display: inline-block;
    font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
    font-size: 0.9375rem;
    font-weight: 300; }
    .wk-breadcrumb > li [class^='wk-icon-']::before,
    .wk-breadcrumb > li [class*=' wk-icon-']::before {
      margin-left: 0;
      margin-right: 0.4375rem; }
    .wk-breadcrumb > li:first-child {
      font-weight: 500; }
    .wk-breadcrumb > li:not(:first-child)::before {
      color: #a3a3a3;
      content: "»";
      margin-left: 0.25rem;
      margin-right: 0.25rem; }

.wk-page-content {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem; }
  .wk-page-content:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 77rem) {
    .wk-page-content {
      padding-left: 0;
      padding-right: 0; } }

.wk-tooltip {
  background: #fff;
  border: 1px solid #ededed;
  box-shadow: 4px 5px 7px -3px rgba(99, 99, 99, 0.3);
  color: #474747;
  font-size: 0.77248rem;
  padding: 0.3125rem;
  position: absolute;
  visibility: hidden; }
  .wk-tooltip.wk-position-bottom::after, .wk-tooltip.wk-position-bottom::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-tooltip.wk-position-bottom::after {
    border-color: rgba(255, 255, 255, 0);
    border-width: 7px;
    top: -14px;
    border-bottom-color: #fff;
    left: 50%;
    margin-left: -7px; }
  .wk-tooltip.wk-position-bottom::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    top: -16px;
    border-bottom-color: rgba(255, 255, 255, 0);
    left: 50%;
    margin-left: -8px; }
  .wk-tooltip.wk-position-bottom-left::after, .wk-tooltip.wk-position-bottom-left::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-tooltip.wk-position-bottom-left::after {
    border-color: rgba(255, 255, 255, 0);
    border-width: 7px;
    top: -14px;
    border-bottom-color: #fff;
    left: 0;
    margin-left: 21px; }
  .wk-tooltip.wk-position-bottom-left::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    top: -16px;
    border-bottom-color: rgba(255, 255, 255, 0);
    left: 0;
    margin-left: 20px; }
  .wk-tooltip.wk-position-bottom-right::after, .wk-tooltip.wk-position-bottom-right::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-tooltip.wk-position-bottom-right::after {
    border-color: rgba(255, 255, 255, 0);
    border-width: 7px;
    top: -14px;
    border-bottom-color: #fff;
    right: 0;
    margin-right: 21px; }
  .wk-tooltip.wk-position-bottom-right::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    top: -16px;
    border-bottom-color: rgba(255, 255, 255, 0);
    right: 0;
    margin-right: 20px; }
  .wk-tooltip.wk-position-top::after, .wk-tooltip.wk-position-top::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-tooltip.wk-position-top::after {
    border-color: rgba(255, 255, 255, 0);
    border-width: 7px;
    bottom: -14px;
    border-top-color: #fff;
    left: 50%;
    margin-left: -7px; }
  .wk-tooltip.wk-position-top::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    bottom: -16px;
    border-top-color: rgba(255, 255, 255, 0);
    left: 50%;
    margin-left: -8px; }
  .wk-tooltip.wk-position-top-left::after, .wk-tooltip.wk-position-top-left::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-tooltip.wk-position-top-left::after {
    border-color: rgba(255, 255, 255, 0);
    border-width: 7px;
    bottom: -14px;
    border-top-color: #fff;
    left: 0;
    margin-left: 21px; }
  .wk-tooltip.wk-position-top-left::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    bottom: -16px;
    border-top-color: rgba(255, 255, 255, 0);
    left: 0;
    margin-left: 20px; }
  .wk-tooltip.wk-position-top-right::after, .wk-tooltip.wk-position-top-right::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-tooltip.wk-position-top-right::after {
    border-color: rgba(255, 255, 255, 0);
    border-width: 7px;
    bottom: -14px;
    border-top-color: #fff;
    right: 0;
    margin-right: 21px; }
  .wk-tooltip.wk-position-top-right::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    bottom: -16px;
    border-top-color: rgba(255, 255, 255, 0);
    right: 0;
    margin-right: 20px; }
  .wk-tooltip.wk-position-left::after, .wk-tooltip.wk-position-left::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-tooltip.wk-position-left::after {
    border-color: rgba(255, 255, 255, 0);
    border-width: 7px;
    left: -14px;
    border-right-color: #fff;
    top: 50%;
    margin-top: -7px; }
  .wk-tooltip.wk-position-left::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    left: -16px;
    border-right-color: rgba(255, 255, 255, 0);
    top: 50%;
    margin-top: -8px; }
  .wk-tooltip.wk-position-left-bottom::after, .wk-tooltip.wk-position-left-bottom::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-tooltip.wk-position-left-bottom::after {
    border-color: rgba(255, 255, 255, 0);
    border-width: 7px;
    left: -14px;
    border-right-color: #fff;
    top: 0;
    margin-top: 6px; }
  .wk-tooltip.wk-position-left-bottom::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    left: -16px;
    border-right-color: rgba(255, 255, 255, 0);
    top: 0;
    margin-top: 5px; }
  .wk-tooltip.wk-position-left-top::after, .wk-tooltip.wk-position-left-top::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-tooltip.wk-position-left-top::after {
    border-color: rgba(255, 255, 255, 0);
    border-width: 7px;
    left: -14px;
    border-right-color: #fff;
    bottom: 0;
    margin-bottom: 6px; }
  .wk-tooltip.wk-position-left-top::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    left: -16px;
    border-right-color: rgba(255, 255, 255, 0);
    bottom: 0;
    margin-bottom: 5px; }
  .wk-tooltip.wk-position-right::after, .wk-tooltip.wk-position-right::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-tooltip.wk-position-right::after {
    border-color: rgba(255, 255, 255, 0);
    border-width: 7px;
    right: -14px;
    border-left-color: #fff;
    top: 50%;
    margin-top: -7px; }
  .wk-tooltip.wk-position-right::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    right: -16px;
    border-left-color: rgba(255, 255, 255, 0);
    top: 50%;
    margin-top: -8px; }
  .wk-tooltip.wk-position-right-bottom::after, .wk-tooltip.wk-position-right-bottom::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-tooltip.wk-position-right-bottom::after {
    border-color: rgba(255, 255, 255, 0);
    border-width: 7px;
    right: -14px;
    border-left-color: #fff;
    top: 0;
    margin-top: 6px; }
  .wk-tooltip.wk-position-right-bottom::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    right: -16px;
    border-left-color: rgba(255, 255, 255, 0);
    top: 0;
    margin-top: 5px; }
  .wk-tooltip.wk-position-right-top::after, .wk-tooltip.wk-position-right-top::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-tooltip.wk-position-right-top::after {
    border-color: rgba(255, 255, 255, 0);
    border-width: 7px;
    right: -14px;
    border-left-color: #fff;
    bottom: 0;
    margin-bottom: 6px; }
  .wk-tooltip.wk-position-right-top::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    right: -16px;
    border-left-color: rgba(255, 255, 255, 0);
    bottom: 0;
    margin-bottom: 5px; }

.wk-popover {
  background: #fff;
  border: 1px solid #ededed;
  box-shadow: 4px 5px 7px -3px rgba(99, 99, 99, 0.3);
  max-width: 300px;
  position: absolute; }
  .wk-popover.wk-position-bottom::after, .wk-popover.wk-position-bottom::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-popover.wk-position-bottom::after {
    border-color: rgba(255, 255, 255, 0);
    border-width: 7px;
    top: -14px;
    border-bottom-color: #fff;
    left: 50%;
    margin-left: -7px; }
  .wk-popover.wk-position-bottom::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    top: -16px;
    border-bottom-color: rgba(255, 255, 255, 0);
    left: 50%;
    margin-left: -8px; }
  .wk-popover.wk-position-bottom-left::after, .wk-popover.wk-position-bottom-left::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-popover.wk-position-bottom-left::after {
    border-color: rgba(255, 255, 255, 0);
    border-width: 7px;
    top: -14px;
    border-bottom-color: #fff;
    left: 0;
    margin-left: 21px; }
  .wk-popover.wk-position-bottom-left::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    top: -16px;
    border-bottom-color: rgba(255, 255, 255, 0);
    left: 0;
    margin-left: 20px; }
  .wk-popover.wk-position-bottom-right::after, .wk-popover.wk-position-bottom-right::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-popover.wk-position-bottom-right::after {
    border-color: rgba(255, 255, 255, 0);
    border-width: 7px;
    top: -14px;
    border-bottom-color: #fff;
    right: 0;
    margin-right: 21px; }
  .wk-popover.wk-position-bottom-right::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    top: -16px;
    border-bottom-color: rgba(255, 255, 255, 0);
    right: 0;
    margin-right: 20px; }
  .wk-popover.wk-position-top::after, .wk-popover.wk-position-top::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-popover.wk-position-top::after {
    border-color: rgba(255, 255, 255, 0);
    border-width: 7px;
    bottom: -14px;
    border-top-color: #fff;
    left: 50%;
    margin-left: -7px; }
  .wk-popover.wk-position-top::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    bottom: -16px;
    border-top-color: rgba(255, 255, 255, 0);
    left: 50%;
    margin-left: -8px; }
  .wk-popover.wk-position-top-left::after, .wk-popover.wk-position-top-left::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-popover.wk-position-top-left::after {
    border-color: rgba(255, 255, 255, 0);
    border-width: 7px;
    bottom: -14px;
    border-top-color: #fff;
    left: 0;
    margin-left: 21px; }
  .wk-popover.wk-position-top-left::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    bottom: -16px;
    border-top-color: rgba(255, 255, 255, 0);
    left: 0;
    margin-left: 20px; }
  .wk-popover.wk-position-top-right::after, .wk-popover.wk-position-top-right::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-popover.wk-position-top-right::after {
    border-color: rgba(255, 255, 255, 0);
    border-width: 7px;
    bottom: -14px;
    border-top-color: #fff;
    right: 0;
    margin-right: 21px; }
  .wk-popover.wk-position-top-right::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    bottom: -16px;
    border-top-color: rgba(255, 255, 255, 0);
    right: 0;
    margin-right: 20px; }
  .wk-popover.wk-position-left::after, .wk-popover.wk-position-left::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-popover.wk-position-left::after {
    border-color: rgba(255, 255, 255, 0);
    border-width: 7px;
    left: -14px;
    border-right-color: #fff;
    top: 50%;
    margin-top: -7px; }
  .wk-popover.wk-position-left::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    left: -16px;
    border-right-color: rgba(255, 255, 255, 0);
    top: 50%;
    margin-top: -8px; }
  .wk-popover.wk-position-left-bottom::after, .wk-popover.wk-position-left-bottom::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-popover.wk-position-left-bottom::after {
    border-color: rgba(255, 255, 255, 0);
    border-width: 7px;
    left: -14px;
    border-right-color: #fff;
    top: 0;
    margin-top: 6px; }
  .wk-popover.wk-position-left-bottom::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    left: -16px;
    border-right-color: rgba(255, 255, 255, 0);
    top: 0;
    margin-top: 5px; }
  .wk-popover.wk-position-left-top::after, .wk-popover.wk-position-left-top::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-popover.wk-position-left-top::after {
    border-color: rgba(255, 255, 255, 0);
    border-width: 7px;
    left: -14px;
    border-right-color: #fff;
    bottom: 0;
    margin-bottom: 6px; }
  .wk-popover.wk-position-left-top::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    left: -16px;
    border-right-color: rgba(255, 255, 255, 0);
    bottom: 0;
    margin-bottom: 5px; }
  .wk-popover.wk-position-right::after, .wk-popover.wk-position-right::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-popover.wk-position-right::after {
    border-color: rgba(255, 255, 255, 0);
    border-width: 7px;
    right: -14px;
    border-left-color: #fff;
    top: 50%;
    margin-top: -7px; }
  .wk-popover.wk-position-right::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    right: -16px;
    border-left-color: rgba(255, 255, 255, 0);
    top: 50%;
    margin-top: -8px; }
  .wk-popover.wk-position-right-bottom::after, .wk-popover.wk-position-right-bottom::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-popover.wk-position-right-bottom::after {
    border-color: rgba(255, 255, 255, 0);
    border-width: 7px;
    right: -14px;
    border-left-color: #fff;
    top: 0;
    margin-top: 6px; }
  .wk-popover.wk-position-right-bottom::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    right: -16px;
    border-left-color: rgba(255, 255, 255, 0);
    top: 0;
    margin-top: 5px; }
  .wk-popover.wk-position-right-top::after, .wk-popover.wk-position-right-top::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-popover.wk-position-right-top::after {
    border-color: rgba(255, 255, 255, 0);
    border-width: 7px;
    right: -14px;
    border-left-color: #fff;
    bottom: 0;
    margin-bottom: 6px; }
  .wk-popover.wk-position-right-top::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    right: -16px;
    border-left-color: rgba(255, 255, 255, 0);
    bottom: 0;
    margin-bottom: 5px; }
  .wk-popover .wk-popover-body {
    padding: 0.625rem; }

.wk-user-popover .wk-user-popover-section {
  border-bottom: 1px solid #ededed;
  padding: 0.625rem; }
  .wk-user-popover .wk-user-popover-section:nth-child(even) {
    background: #f6f6f6; }
  .wk-user-popover .wk-user-popover-section:last-child {
    border-bottom: 0; }
  .wk-user-popover .wk-user-popover-section .wk-module-heading {
    margin: 0;
    margin-bottom: 10px; }

.wk-card-group {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
  .wk-card-group > .wk-card {
    margin-bottom: 0.9375rem; }
    @media (min-width: 37.5rem) {
      .wk-card-group > .wk-card {
        float: none; } }

.wk-card-group-4 > .wk-card,
.wk-card-group-3 > .wk-card,
.wk-card-group-2 > .wk-card {
  margin-bottom: 0.9375rem;
  width: 100%; }

@media (min-width: 37.5rem) {
  .wk-card-group-2 > .wk-card {
    width: 49.15254%;
    float: left;
    margin-right: 1.69492%; }
    .wk-card-group-2 > .wk-card:nth-child(2n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 37.5rem) and (max-width: 65rem) {
  .wk-card-group-3 > .wk-card,
  .wk-card-group-4 > .wk-card {
    width: 49.15254%;
    float: left;
    margin-right: 1.69492%; }
    .wk-card-group-3 > .wk-card:nth-child(2n),
    .wk-card-group-4 > .wk-card:nth-child(2n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 65rem) {
  .wk-card-group-3 > .wk-card {
    width: 32.20339%;
    float: left;
    margin-right: 1.69492%; }
    .wk-card-group-3 > .wk-card:nth-child(3n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 65rem) {
  .wk-card-group-4 > .wk-card {
    width: 23.72881%;
    float: left;
    margin-right: 1.69492%; }
    .wk-card-group-4 > .wk-card:nth-child(4n) {
      float: right;
      margin-right: 0; } }

html.ie9 .wk-card-group,
html.no-flexbox .wk-card-group,
.wk-card-group-float.wk-card-group {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
  display: block; }
  html.ie9 .wk-card-group:after,
  html.no-flexbox .wk-card-group:after,
  .wk-card-group-float.wk-card-group:after {
    content: " ";
    display: block;
    clear: both; }
  html.ie9 .wk-card-group > .wk-card,
  html.no-flexbox .wk-card-group > .wk-card,
  .wk-card-group-float.wk-card-group > .wk-card {
    position: relative; }
    @media (min-width: 37.5rem) {
      html.ie9 .wk-card-group > .wk-card,
      html.no-flexbox .wk-card-group > .wk-card,
      .wk-card-group-float.wk-card-group > .wk-card {
        max-height: 11.25rem;
        min-height: 11.25rem;
        overflow: hidden; }
        html.ie9 .wk-card-group > .wk-card::after,
        html.no-flexbox .wk-card-group > .wk-card::after,
        .wk-card-group-float.wk-card-group > .wk-card::after {
          background: transparent linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #fff 100%) repeat scroll 0% 0%;
          bottom: -1px;
          content: '';
          display: block;
          height: 30px;
          left: 1px;
          position: absolute;
          right: 1px; } }

.wk-card,
a.wk-card {
  background-color: #fff;
  border: 1px solid #dadada;
  font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
  padding: 0.9375rem; }
  .wk-card .wk-card-meta,
  .wk-card .wk-card-title,
  a.wk-card .wk-card-meta,
  a.wk-card .wk-card-title {
    color: #007ac3; }
  .wk-card .wk-card-title,
  a.wk-card .wk-card-title {
    display: block;
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 0.625rem;
    margin-top: 0; }
  .wk-card .wk-card-body,
  a.wk-card .wk-card-body {
    color: #474747; }
  .wk-card .wk-label,
  .wk-card .wk-label-success,
  .wk-card .wk-label-info,
  .wk-card .wk-label-danger,
  a.wk-card .wk-label,
  a.wk-card .wk-label-success,
  a.wk-card .wk-label-info,
  a.wk-card .wk-label-danger {
    float: right;
    margin-bottom: 0.5rem;
    margin-left: 0.5rem; }
  .wk-card:focus, .wk-card:hover,
  a.wk-card:focus,
  a.wk-card:hover {
    background: #d4e8b1; }
    .wk-card:focus .wk-card-title,
    .wk-card:focus .wk-card-meta,
    .wk-card:focus .wk-card-body, .wk-card:hover .wk-card-title,
    .wk-card:hover .wk-card-meta,
    .wk-card:hover .wk-card-body,
    a.wk-card:focus .wk-card-title,
    a.wk-card:focus .wk-card-meta,
    a.wk-card:focus .wk-card-body,
    a.wk-card:hover .wk-card-title,
    a.wk-card:hover .wk-card-meta,
    a.wk-card:hover .wk-card-body {
      color: #000; }

.wk-login-screen {
  background-color: #fff;
  bottom: 0;
  color: #474747;
  height: 100%;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%; }
  .wk-login-screen .wk-header .wk-brand,
  .wk-login-screen .wk-header .wk-logo {
    float: none; }
    @media (min-width: 37.5rem) {
      .wk-login-screen .wk-header .wk-brand,
      .wk-login-screen .wk-header .wk-logo {
        float: left; } }
  .wk-login-screen .wk-header .wk-product-brand {
    color: #474747;
    display: block;
    font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
    font-size: 1.21363rem;
    font-weight: 300;
    margin: 0.625rem 0.9375rem; }
    @media (min-width: 37.5rem) {
      .wk-login-screen .wk-header .wk-product-brand {
        float: right;
        margin: 0;
        margin-top: 1.625rem; } }
    .wk-login-screen .wk-header .wk-product-brand strong {
      font-weight: 500; }
  .wk-login-screen .wk-login-layout,
  .wk-login-screen .wk-login-layout-options,
  .wk-login-screen .wk-login-layout-support {
    padding-left: 1rem;
    padding-right: 1rem; }
    @media (min-width: 37.5rem) {
      .wk-login-screen .wk-login-layout,
      .wk-login-screen .wk-login-layout-options,
      .wk-login-screen .wk-login-layout-support {
        padding-left: 0;
        padding-right: 0; } }
    .wk-login-screen .wk-login-layout .wk-copyright-text,
    .wk-login-screen .wk-login-layout-options .wk-copyright-text,
    .wk-login-screen .wk-login-layout-support .wk-copyright-text {
      color: #474747;
      font-size: 0.82397rem;
      font-weight: 300;
      margin: 1rem 0; }
  .wk-login-screen .wk-login-layout .wk-login-footer,
  .wk-login-screen .wk-login-layout .wk-login-form {
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto; }
    .wk-login-screen .wk-login-layout .wk-login-footer:after,
    .wk-login-screen .wk-login-layout .wk-login-form:after {
      content: " ";
      display: block;
      clear: both; }
    @media (min-width: 37.5rem) {
      .wk-login-screen .wk-login-layout .wk-login-footer,
      .wk-login-screen .wk-login-layout .wk-login-form {
        max-width: 28.125rem; } }
  .wk-login-screen .wk-login-layout-options .wk-login-footer,
  .wk-login-screen .wk-login-layout-options .wk-login-form {
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 37.5rem; }
    .wk-login-screen .wk-login-layout-options .wk-login-footer:after,
    .wk-login-screen .wk-login-layout-options .wk-login-form:after {
      content: " ";
      display: block;
      clear: both; }
  .wk-login-screen .wk-login-layout-support {
    padding: 0; }
    .wk-login-screen .wk-login-layout-support .wk-login-support-container,
    .wk-login-screen .wk-login-layout-support .wk-login-footer {
      max-width: 75rem;
      margin-left: auto;
      margin-right: auto;
      padding-left: 1rem;
      padding-right: 1rem; }
      .wk-login-screen .wk-login-layout-support .wk-login-support-container:after,
      .wk-login-screen .wk-login-layout-support .wk-login-footer:after {
        content: " ";
        display: block;
        clear: both; }
      @media (min-width: 77rem) {
        .wk-login-screen .wk-login-layout-support .wk-login-support-container,
        .wk-login-screen .wk-login-layout-support .wk-login-footer {
          padding-left: 0;
          padding-right: 0; } }
    @media (min-width: 37.5rem) {
      .wk-login-screen .wk-login-layout-support .wk-login-form {
        width: 57.62712%;
        float: left;
        margin-right: 1.69492%; } }
    .wk-login-screen .wk-login-layout-support .wk-login-support {
      border: 1px solid #ededed;
      color: #474747;
      margin-bottom: 1rem;
      padding: 1rem; }
      @media (min-width: 37.5rem) {
        .wk-login-screen .wk-login-layout-support .wk-login-support {
          width: 40.67797%;
          float: left;
          margin-right: 1.69492%;
          float: right;
          margin-right: 0; } }
      .wk-login-screen .wk-login-layout-support .wk-login-support h5 {
        margin-top: 0; }
      .wk-login-screen .wk-login-layout-support .wk-login-support strong {
        color: #474747; }
      .wk-login-screen .wk-login-layout-support .wk-login-support ul {
        list-style: none;
        margin: 0;
        padding: 0; }
        .wk-login-screen .wk-login-layout-support .wk-login-support ul li {
          clear: both;
          padding: 0.125rem 0;
          white-space: pre-wrap;
          word-wrap: break-word; }
          .wk-login-screen .wk-login-layout-support .wk-login-support ul li [class^='wk-icon-']::before,
          .wk-login-screen .wk-login-layout-support .wk-login-support ul li [class*=' wk-icon-']::before {
            float: left;
            font-size: 1.375rem;
            left: -0.3125rem;
            position: relative; }
            @media (min-width: 37.5rem) and (max-width: 54rem) {
              .wk-login-screen .wk-login-layout-support .wk-login-support ul li [class^='wk-icon-']::before,
              .wk-login-screen .wk-login-layout-support .wk-login-support ul li [class*=' wk-icon-']::before {
                display: none; } }
  .wk-login-screen .wk-login-form {
    background-color: #ededed;
    margin-bottom: 1rem;
    padding: 1rem; }
    .wk-login-screen .wk-login-form h3 {
      color: #474747;
      font-family: "Franziska", "Times New Roman", Times, serif;
      font-style: italic;
      margin: 0 0 1rem; }
    .wk-login-screen .wk-login-form p {
      font-size: 1rem; }
    .wk-login-screen .wk-login-form .wk-button-full {
      margin-bottom: 0; }
    .wk-login-screen .wk-login-form .forgot-link {
      display: block;
      margin-bottom: 0.9375rem; }

.wk-login-form-container {
  margin-bottom: 1rem; }
  @media (min-width: 37.5rem) {
    .wk-login-form-container {
      margin-bottom: 0; } }
  @media (min-width: 37.5rem) {
    .wk-login-form-container.has-login-options {
      max-width: 75rem;
      margin-left: auto;
      margin-right: auto; }
      .wk-login-form-container.has-login-options:after {
        content: " ";
        display: block;
        clear: both; }
      .wk-login-form-container.has-login-options .wk-login-fields,
      .wk-login-form-container.has-login-options .wk-login-options {
        width: 48.97959%;
        float: left;
        margin-right: 2.04082%; }
      .wk-login-form-container.has-login-options .wk-login-options {
        float: right;
        margin-right: 0;
        margin-top: 1.5625rem; } }

.wk-breakpoint-tag {
  background-color: #474747;
  bottom: 10px;
  color: #474747;
  opacity: .3;
  padding: 6px;
  position: fixed;
  right: 10px;
  text-shadow: 0 1px 0 #000;
  z-index: 1200; }
  .wk-breakpoint-tag::before {
    content: 'mobile'; }
    @media (min-width: 37.5rem) and (max-width: 65rem) {
      .wk-breakpoint-tag::before {
        content: 'tablet'; } }
    @media (min-width: 65rem) {
      .wk-breakpoint-tag::before {
        content: 'desktop'; } }
    @media (min-width: 75rem) {
      .wk-breakpoint-tag::before {
        content: "desktop (max 75rem)"; } }

.wk-a11y-enhanced-focus a:hover:focus,
.wk-a11y-enhanced-focus :focus {
  outline-color: #000;
  outline-offset: 0;
  outline-style: solid;
  outline-width: 2px; }

.wk-navbar-dropdown-container {
  background: #005b92;
  color: #fff;
  display: none; }
  .wk-navbar-dropdown-container::after {
    clear: both;
    content: ' ';
    display: table; }
  .wk-navbar-dropdown-container .wk-navbar-dropdown-column-group {
    -webkit-align-items: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .wk-navbar-dropdown-container .wk-navbar-dropdown-column-group > .wk-navbar-dropdown-column {
      -webkit-flex: 0 1 auto;
          -ms-flex: 0 1 auto;
              flex: 0 1 auto; }
      @media (min-width: 0) and (max-width: 37.5rem) {
        .wk-navbar-dropdown-container .wk-navbar-dropdown-column-group > .wk-navbar-dropdown-column {
          width: 100%;
          float: left;
          margin-left: 0;
          margin-right: 0; }
          .wk-navbar-dropdown-container .wk-navbar-dropdown-column-group > .wk-navbar-dropdown-column:nth-child(1n) {
            margin-right: 0; } }
      @media (min-width: 37.5rem) and (max-width: 65rem) {
        .wk-navbar-dropdown-container .wk-navbar-dropdown-column-group > .wk-navbar-dropdown-column {
          width: 47.82609%;
          float: left;
          margin-right: 4.34783%; }
          .wk-navbar-dropdown-container .wk-navbar-dropdown-column-group > .wk-navbar-dropdown-column:nth-child(2n) {
            margin-right: 0; } }
      @media (min-width: 65rem) {
        .wk-navbar-dropdown-container .wk-navbar-dropdown-column-group > .wk-navbar-dropdown-column {
          width: 21.73913%;
          float: left;
          margin-right: 4.34783%; }
          .wk-navbar-dropdown-container .wk-navbar-dropdown-column-group > .wk-navbar-dropdown-column:nth-child(4n) {
            margin-right: 0; } }
  .wk-navbar-dropdown-classic.wk-navbar-dropdown-container {
    overflow: hidden; }
    .wk-navbar-dropdown-classic.wk-navbar-dropdown-container .wk-nav > li {
      position: relative; }

.wk-navbar-dropdown {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0;
  padding-top: 0.5rem; }
  .wk-navbar-dropdown:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 77rem) {
    .wk-navbar-dropdown {
      padding-left: 0;
      padding-right: 0; } }
  @media (min-width: 37.5rem) {
    .wk-navbar-dropdown {
      padding-bottom: 2.125rem;
      padding-top: 0; } }
  @media (min-width: 77rem) {
    .wk-navbar-dropdown {
      margin-left: auto;
      margin-right: auto;
      max-width: 75rem; } }
  .wk-navbar-dropdown .wk-navbar-dropdown-column {
    padding-bottom: 0;
    padding-top: 0.9375rem; }
    @media (min-width: 37.5rem) {
      .wk-navbar-dropdown .wk-navbar-dropdown-column {
        padding-top: 3rem; } }
  @media (min-width: 77rem) {
    .wk-navbar-dropdown-classic.wk-navbar-dropdown {
      padding-left: 1rem;
      padding-right: 1rem; } }
  .wk-navbar-dropdown ul, .wk-navbar-dropdown li, .wk-navbar-dropdown dd, .wk-navbar-dropdown figure {
    margin: 0;
    padding: 0; }
  .wk-navbar-dropdown li {
    list-style-type: none; }
  .wk-navbar-dropdown .wk-dropdown-column-list li, .wk-navbar-dropdown .wk-dropdown-column-list dt, .wk-navbar-dropdown .wk-dropdown-column-list dd, .wk-navbar-dropdown [wk-dropdown-column*='list'] li, .wk-navbar-dropdown [wk-dropdown-column*='list'] dt, .wk-navbar-dropdown [wk-dropdown-column*='list'] dd {
    font-size: 0.875rem;
    line-height: 1.125rem;
    margin-bottom: 1.25rem; }
    @media (min-width: 37.5rem) {
      .wk-navbar-dropdown .wk-dropdown-column-list li, .wk-navbar-dropdown .wk-dropdown-column-list dt, .wk-navbar-dropdown .wk-dropdown-column-list dd, .wk-navbar-dropdown [wk-dropdown-column*='list'] li, .wk-navbar-dropdown [wk-dropdown-column*='list'] dt, .wk-navbar-dropdown [wk-dropdown-column*='list'] dd {
        margin-bottom: 0.875rem; } }
  .wk-navbar-dropdown .wk-dropdown-column-list dt, .wk-navbar-dropdown [wk-dropdown-column*='list'] dt {
    opacity: 0.5; }
  .wk-navbar-dropdown .wk-dropdown-column-list a, .wk-navbar-dropdown [wk-dropdown-column*='list'] a {
    color: #fff;
    display: block; }
    @media (min-width: 37.5rem) {
      .wk-navbar-dropdown .wk-dropdown-column-list a, .wk-navbar-dropdown [wk-dropdown-column*='list'] a {
        display: inline; } }
    .wk-navbar-dropdown .wk-dropdown-column-list a:hover, .wk-navbar-dropdown [wk-dropdown-column*='list'] a:hover {
      text-decoration: underline; }
  .wk-navbar-dropdown li, .wk-navbar-dropdown dd > figcaption {
    margin-bottom: 0.875rem; }

.wk-nav > li:hover .wk-navbar-dropdown-container {
  display: block;
  left: 0;
  position: relative;
  width: 100%; }
  @media (min-width: 37.5rem) {
    .wk-nav > li:hover .wk-navbar-dropdown-container {
      position: absolute; } }

.wk-nav > li:hover .wk-navbar-dropdown-container.wk-navbar-dropdown-classic {
  left: inherit;
  width: auto; }

.wk-navbar .wk-nav > li > a:hover,
.wk-navbar .wk-nav > li > a:focus,
.wk-navbar .wk-nav > li.active > a:focus {
  background-color: #005b92; }

.wk-dropdown {
  display: inline-block;
  position: relative; }
  .wk-dropdown .wk-dropdown-toggle {
    padding-right: 30px;
    position: relative; }
    .wk-dropdown .wk-dropdown-toggle::after {
      content: "";
      font-family: "wk-icons";
      position: absolute;
      right: 10px;
      -webkit-transform: rotate(360deg);
          -ms-transform: rotate(360deg);
              transform: rotate(360deg);
      transition: -webkit-transform .15s linear;
      transition: transform .15s linear; }
  .wk-dropdown .wk-dropdown-menu {
    border: 1px solid #a3a3a3;
    box-shadow: 0 0 8px #757575;
    display: none;
    position: absolute;
    top: 100%;
    z-index: 1040; }
    .wk-dropdown .wk-dropdown-menu.wk-dropdown-left {
      left: 0; }
    .wk-dropdown .wk-dropdown-menu.wk-dropdown-right {
      right: 0; }
    .wk-dropdown .wk-dropdown-menu.wk-dropdown-up {
      bottom: 100%;
      top: auto; }
  .wk-dropdown:hover > .wk-dropdown-menu, .wk-dropdown.wk-dropdown-open > .wk-dropdown-menu {
    display: block; }
  .wk-dropdown:hover > .wk-dropdown-toggle::after, .wk-dropdown.wk-dropdown-open > .wk-dropdown-toggle::after {
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
            transform: rotate(180deg);
    transition: -webkit-transform .15s;
    transition: transform .15s; }

.wk-dropdown-menu {
  background: #fff;
  min-width: 200px;
  width: 100%; }
  @media (min-width: 37.5rem) {
    .wk-dropdown-menu {
      width: auto; } }
  .wk-dropdown-menu > ul {
    list-style-type: none;
    margin: 0;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0; }
    .wk-dropdown-menu > ul li {
      min-width: 200px; }
      .wk-dropdown-menu > ul li a {
        background: #fff;
        color: #757575;
        display: block;
        font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
        padding: 0.3125rem 0.9375rem; }
      .wk-dropdown-menu > ul li a:hover {
        background: #ededed; }
  .wk-dropdown-menu > dl {
    font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%; }
    .wk-dropdown-menu > dl dt,
    .wk-dropdown-menu > dl dd {
      display: block;
      min-width: 200px;
      padding: 0.9375rem; }
      .wk-dropdown-menu > dl dt a,
      .wk-dropdown-menu > dl dd a {
        color: #757575;
        display: block;
        padding: 0.3125rem 0.9375rem; }
      .wk-dropdown-menu > dl dt a:hover,
      .wk-dropdown-menu > dl dd a:hover {
        background: #ededed; }
    .wk-dropdown-menu > dl dt {
      color: #ec5862;
      margin: 0;
      padding-bottom: 0; }
    .wk-dropdown-menu > dl dd {
      margin: 0;
      padding: 0; }

a.wk-dropdown-toggle {
  padding-right: 30px; }

.wk-header .wk-more-menu.open {
  background: rgba(71, 71, 71, 0.95); }

.wk-header .wk-nav.open {
  background: none; }
  .wk-header .wk-nav.open > li > a {
    background: rgba(71, 71, 71, 0.95);
    color: #fff; }
    @media (min-width: 37.5rem) {
      .wk-header .wk-nav.open > li > a {
        background: none;
        color: #757575; } }
  .wk-header .wk-nav.open > li > a:hover {
    background: #474747; }
    @media (min-width: 37.5rem) {
      .wk-header .wk-nav.open > li > a:hover {
        background: none;
        color: #85bc20; } }

.wk-header .wk-nav .wk-dropdown .wk-dropdown-menu {
  background: none;
  border: 0;
  box-shadow: none;
  position: relative; }
  @media (min-width: 37.5rem) {
    .wk-header .wk-nav .wk-dropdown .wk-dropdown-menu {
      position: absolute; } }

.wk-header .wk-nav .wk-dropdown .wk-dropdown-body {
  background: rgba(71, 71, 71, 0.95);
  min-width: 200px;
  padding: 0.9375rem 0.9375rem; }

.wk-header .wk-nav .wk-dropdown .wk-dropdown-toggle {
  padding-right: 30px; }

.wk-header .wk-nav .wk-dropdown:hover,
.wk-header .wk-nav .wk-dropdown.wk-dropdown-open {
  background: none; }
  .wk-header .wk-nav .wk-dropdown:hover > .wk-dropdown-toggle,
  .wk-header .wk-nav .wk-dropdown.wk-dropdown-open > .wk-dropdown-toggle {
    background: rgba(71, 71, 71, 0.95);
    color: #fff; }

.wk-header .wk-nav .wk-dropdown-menu a {
  background: rgba(71, 71, 71, 0.95);
  color: #fff;
  padding: 0.625rem 0.9375rem; }

.wk-header .wk-nav .wk-dropdown-menu a:hover {
  background: #474747; }

button.wk-dropdown-toggle {
  margin-bottom: 0;
  padding-right: 30px; }

/*! Source: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css */
@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: ""; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .navbar {
    display: none; }
  .btn > .caret,
  .dropup > .btn > .caret {
    border-top-color: #000 !important; }
  .label {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important; } }

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url("fonts/bootstrap/glyphicons-halflings-regular.eot");
  src: url("fonts/bootstrap/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype"), url("fonts/bootstrap/glyphicons-halflings-regular.woff2") format("woff2"), url("fonts/bootstrap/glyphicons-halflings-regular.woff") format("woff"), url("fonts/bootstrap/glyphicons-halflings-regular.ttf") format("truetype"), url("fonts/bootstrap/glyphicons-halflings-regular.svg#glyphicons_halflingsregular") format("svg"); }

.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.glyphicon-asterisk:before {
  content: "\002a"; }

.glyphicon-plus:before {
  content: "\002b"; }

.glyphicon-euro:before,
.glyphicon-eur:before {
  content: "\20ac"; }

.glyphicon-minus:before {
  content: "\2212"; }

.glyphicon-cloud:before {
  content: "\2601"; }

.glyphicon-envelope:before {
  content: "\2709"; }

.glyphicon-pencil:before {
  content: "\270f"; }

.glyphicon-glass:before {
  content: "\e001"; }

.glyphicon-music:before {
  content: "\e002"; }

.glyphicon-search:before {
  content: "\e003"; }

.glyphicon-heart:before {
  content: "\e005"; }

.glyphicon-star:before {
  content: "\e006"; }

.glyphicon-star-empty:before {
  content: "\e007"; }

.glyphicon-user:before {
  content: "\e008"; }

.glyphicon-film:before {
  content: "\e009"; }

.glyphicon-th-large:before {
  content: "\e010"; }

.glyphicon-th:before {
  content: "\e011"; }

.glyphicon-th-list:before {
  content: "\e012"; }

.glyphicon-ok:before {
  content: "\e013"; }

.glyphicon-remove:before {
  content: "\e014"; }

.glyphicon-zoom-in:before {
  content: "\e015"; }

.glyphicon-zoom-out:before {
  content: "\e016"; }

.glyphicon-off:before {
  content: "\e017"; }

.glyphicon-signal:before {
  content: "\e018"; }

.glyphicon-cog:before {
  content: "\e019"; }

.glyphicon-trash:before {
  content: "\e020"; }

.glyphicon-home:before {
  content: "\e021"; }

.glyphicon-file:before {
  content: "\e022"; }

.glyphicon-time:before {
  content: "\e023"; }

.glyphicon-road:before {
  content: "\e024"; }

.glyphicon-download-alt:before {
  content: "\e025"; }

.glyphicon-download:before {
  content: "\e026"; }

.glyphicon-upload:before {
  content: "\e027"; }

.glyphicon-inbox:before {
  content: "\e028"; }

.glyphicon-play-circle:before {
  content: "\e029"; }

.glyphicon-repeat:before {
  content: "\e030"; }

.glyphicon-refresh:before {
  content: "\e031"; }

.glyphicon-list-alt:before {
  content: "\e032"; }

.glyphicon-lock:before {
  content: "\e033"; }

.glyphicon-flag:before {
  content: "\e034"; }

.glyphicon-headphones:before {
  content: "\e035"; }

.glyphicon-volume-off:before {
  content: "\e036"; }

.glyphicon-volume-down:before {
  content: "\e037"; }

.glyphicon-volume-up:before {
  content: "\e038"; }

.glyphicon-qrcode:before {
  content: "\e039"; }

.glyphicon-barcode:before {
  content: "\e040"; }

.glyphicon-tag:before {
  content: "\e041"; }

.glyphicon-tags:before {
  content: "\e042"; }

.glyphicon-book:before {
  content: "\e043"; }

.glyphicon-bookmark:before {
  content: "\e044"; }

.glyphicon-print:before {
  content: "\e045"; }

.glyphicon-camera:before {
  content: "\e046"; }

.glyphicon-font:before {
  content: "\e047"; }

.glyphicon-bold:before {
  content: "\e048"; }

.glyphicon-italic:before {
  content: "\e049"; }

.glyphicon-text-height:before {
  content: "\e050"; }

.glyphicon-text-width:before {
  content: "\e051"; }

.glyphicon-align-left:before {
  content: "\e052"; }

.glyphicon-align-center:before {
  content: "\e053"; }

.glyphicon-align-right:before {
  content: "\e054"; }

.glyphicon-align-justify:before {
  content: "\e055"; }

.glyphicon-list:before {
  content: "\e056"; }

.glyphicon-indent-left:before {
  content: "\e057"; }

.glyphicon-indent-right:before {
  content: "\e058"; }

.glyphicon-facetime-video:before {
  content: "\e059"; }

.glyphicon-picture:before {
  content: "\e060"; }

.glyphicon-map-marker:before {
  content: "\e062"; }

.glyphicon-adjust:before {
  content: "\e063"; }

.glyphicon-tint:before {
  content: "\e064"; }

.glyphicon-edit:before {
  content: "\e065"; }

.glyphicon-share:before {
  content: "\e066"; }

.glyphicon-check:before {
  content: "\e067"; }

.glyphicon-move:before {
  content: "\e068"; }

.glyphicon-step-backward:before {
  content: "\e069"; }

.glyphicon-fast-backward:before {
  content: "\e070"; }

.glyphicon-backward:before {
  content: "\e071"; }

.glyphicon-play:before {
  content: "\e072"; }

.glyphicon-pause:before {
  content: "\e073"; }

.glyphicon-stop:before {
  content: "\e074"; }

.glyphicon-forward:before {
  content: "\e075"; }

.glyphicon-fast-forward:before {
  content: "\e076"; }

.glyphicon-step-forward:before {
  content: "\e077"; }

.glyphicon-eject:before {
  content: "\e078"; }

.glyphicon-chevron-left:before {
  content: "\e079"; }

.glyphicon-chevron-right:before {
  content: "\e080"; }

.glyphicon-plus-sign:before {
  content: "\e081"; }

.glyphicon-minus-sign:before {
  content: "\e082"; }

.glyphicon-remove-sign:before {
  content: "\e083"; }

.glyphicon-ok-sign:before {
  content: "\e084"; }

.glyphicon-question-sign:before {
  content: "\e085"; }

.glyphicon-info-sign:before {
  content: "\e086"; }

.glyphicon-screenshot:before {
  content: "\e087"; }

.glyphicon-remove-circle:before {
  content: "\e088"; }

.glyphicon-ok-circle:before {
  content: "\e089"; }

.glyphicon-ban-circle:before {
  content: "\e090"; }

.glyphicon-arrow-left:before {
  content: "\e091"; }

.glyphicon-arrow-right:before {
  content: "\e092"; }

.glyphicon-arrow-up:before {
  content: "\e093"; }

.glyphicon-arrow-down:before {
  content: "\e094"; }

.glyphicon-share-alt:before {
  content: "\e095"; }

.glyphicon-resize-full:before {
  content: "\e096"; }

.glyphicon-resize-small:before {
  content: "\e097"; }

.glyphicon-exclamation-sign:before {
  content: "\e101"; }

.glyphicon-gift:before {
  content: "\e102"; }

.glyphicon-leaf:before {
  content: "\e103"; }

.glyphicon-fire:before {
  content: "\e104"; }

.glyphicon-eye-open:before {
  content: "\e105"; }

.glyphicon-eye-close:before {
  content: "\e106"; }

.glyphicon-warning-sign:before {
  content: "\e107"; }

.glyphicon-plane:before {
  content: "\e108"; }

.glyphicon-calendar:before {
  content: "\e109"; }

.glyphicon-random:before {
  content: "\e110"; }

.glyphicon-comment:before {
  content: "\e111"; }

.glyphicon-magnet:before {
  content: "\e112"; }

.glyphicon-chevron-up:before {
  content: "\e113"; }

.glyphicon-chevron-down:before {
  content: "\e114"; }

.glyphicon-retweet:before {
  content: "\e115"; }

.glyphicon-shopping-cart:before {
  content: "\e116"; }

.glyphicon-folder-close:before {
  content: "\e117"; }

.glyphicon-folder-open:before {
  content: "\e118"; }

.glyphicon-resize-vertical:before {
  content: "\e119"; }

.glyphicon-resize-horizontal:before {
  content: "\e120"; }

.glyphicon-hdd:before {
  content: "\e121"; }

.glyphicon-bullhorn:before {
  content: "\e122"; }

.glyphicon-bell:before {
  content: "\e123"; }

.glyphicon-certificate:before {
  content: "\e124"; }

.glyphicon-thumbs-up:before {
  content: "\e125"; }

.glyphicon-thumbs-down:before {
  content: "\e126"; }

.glyphicon-hand-right:before {
  content: "\e127"; }

.glyphicon-hand-left:before {
  content: "\e128"; }

.glyphicon-hand-up:before {
  content: "\e129"; }

.glyphicon-hand-down:before {
  content: "\e130"; }

.glyphicon-circle-arrow-right:before {
  content: "\e131"; }

.glyphicon-circle-arrow-left:before {
  content: "\e132"; }

.glyphicon-circle-arrow-up:before {
  content: "\e133"; }

.glyphicon-circle-arrow-down:before {
  content: "\e134"; }

.glyphicon-globe:before {
  content: "\e135"; }

.glyphicon-wrench:before {
  content: "\e136"; }

.glyphicon-tasks:before {
  content: "\e137"; }

.glyphicon-filter:before {
  content: "\e138"; }

.glyphicon-briefcase:before {
  content: "\e139"; }

.glyphicon-fullscreen:before {
  content: "\e140"; }

.glyphicon-dashboard:before {
  content: "\e141"; }

.glyphicon-paperclip:before {
  content: "\e142"; }

.glyphicon-heart-empty:before {
  content: "\e143"; }

.glyphicon-link:before {
  content: "\e144"; }

.glyphicon-phone:before {
  content: "\e145"; }

.glyphicon-pushpin:before {
  content: "\e146"; }

.glyphicon-usd:before {
  content: "\e148"; }

.glyphicon-gbp:before {
  content: "\e149"; }

.glyphicon-sort:before {
  content: "\e150"; }

.glyphicon-sort-by-alphabet:before {
  content: "\e151"; }

.glyphicon-sort-by-alphabet-alt:before {
  content: "\e152"; }

.glyphicon-sort-by-order:before {
  content: "\e153"; }

.glyphicon-sort-by-order-alt:before {
  content: "\e154"; }

.glyphicon-sort-by-attributes:before {
  content: "\e155"; }

.glyphicon-sort-by-attributes-alt:before {
  content: "\e156"; }

.glyphicon-unchecked:before {
  content: "\e157"; }

.glyphicon-expand:before {
  content: "\e158"; }

.glyphicon-collapse-down:before {
  content: "\e159"; }

.glyphicon-collapse-up:before {
  content: "\e160"; }

.glyphicon-log-in:before {
  content: "\e161"; }

.glyphicon-flash:before {
  content: "\e162"; }

.glyphicon-log-out:before {
  content: "\e163"; }

.glyphicon-new-window:before {
  content: "\e164"; }

.glyphicon-record:before {
  content: "\e165"; }

.glyphicon-save:before {
  content: "\e166"; }

.glyphicon-open:before {
  content: "\e167"; }

.glyphicon-saved:before {
  content: "\e168"; }

.glyphicon-import:before {
  content: "\e169"; }

.glyphicon-export:before {
  content: "\e170"; }

.glyphicon-send:before {
  content: "\e171"; }

.glyphicon-floppy-disk:before {
  content: "\e172"; }

.glyphicon-floppy-saved:before {
  content: "\e173"; }

.glyphicon-floppy-remove:before {
  content: "\e174"; }

.glyphicon-floppy-save:before {
  content: "\e175"; }

.glyphicon-floppy-open:before {
  content: "\e176"; }

.glyphicon-credit-card:before {
  content: "\e177"; }

.glyphicon-transfer:before {
  content: "\e178"; }

.glyphicon-cutlery:before {
  content: "\e179"; }

.glyphicon-header:before {
  content: "\e180"; }

.glyphicon-compressed:before {
  content: "\e181"; }

.glyphicon-earphone:before {
  content: "\e182"; }

.glyphicon-phone-alt:before {
  content: "\e183"; }

.glyphicon-tower:before {
  content: "\e184"; }

.glyphicon-stats:before {
  content: "\e185"; }

.glyphicon-sd-video:before {
  content: "\e186"; }

.glyphicon-hd-video:before {
  content: "\e187"; }

.glyphicon-subtitles:before {
  content: "\e188"; }

.glyphicon-sound-stereo:before {
  content: "\e189"; }

.glyphicon-sound-dolby:before {
  content: "\e190"; }

.glyphicon-sound-5-1:before {
  content: "\e191"; }

.glyphicon-sound-6-1:before {
  content: "\e192"; }

.glyphicon-sound-7-1:before {
  content: "\e193"; }

.glyphicon-copyright-mark:before {
  content: "\e194"; }

.glyphicon-registration-mark:before {
  content: "\e195"; }

.glyphicon-cloud-download:before {
  content: "\e197"; }

.glyphicon-cloud-upload:before {
  content: "\e198"; }

.glyphicon-tree-conifer:before {
  content: "\e199"; }

.glyphicon-tree-deciduous:before {
  content: "\e200"; }

.glyphicon-cd:before {
  content: "\e201"; }

.glyphicon-save-file:before {
  content: "\e202"; }

.glyphicon-open-file:before {
  content: "\e203"; }

.glyphicon-level-up:before {
  content: "\e204"; }

.glyphicon-copy:before {
  content: "\e205"; }

.glyphicon-paste:before {
  content: "\e206"; }

.glyphicon-alert:before {
  content: "\e209"; }

.glyphicon-equalizer:before {
  content: "\e210"; }

.glyphicon-king:before {
  content: "\e211"; }

.glyphicon-queen:before {
  content: "\e212"; }

.glyphicon-pawn:before {
  content: "\e213"; }

.glyphicon-bishop:before {
  content: "\e214"; }

.glyphicon-knight:before {
  content: "\e215"; }

.glyphicon-baby-formula:before {
  content: "\e216"; }

.glyphicon-tent:before {
  content: "\26fa"; }

.glyphicon-blackboard:before {
  content: "\e218"; }

.glyphicon-bed:before {
  content: "\e219"; }

.glyphicon-apple:before {
  content: "\f8ff"; }

.glyphicon-erase:before {
  content: "\e221"; }

.glyphicon-hourglass:before {
  content: "\231b"; }

.glyphicon-lamp:before {
  content: "\e223"; }

.glyphicon-duplicate:before {
  content: "\e224"; }

.glyphicon-piggy-bank:before {
  content: "\e225"; }

.glyphicon-scissors:before {
  content: "\e226"; }

.glyphicon-bitcoin:before {
  content: "\e227"; }

.glyphicon-btc:before {
  content: "\e227"; }

.glyphicon-xbt:before {
  content: "\e227"; }

.glyphicon-yen:before {
  content: "\00a5"; }

.glyphicon-jpy:before {
  content: "\00a5"; }

.glyphicon-ruble:before {
  content: "\20bd"; }

.glyphicon-rub:before {
  content: "\20bd"; }

.glyphicon-scale:before {
  content: "\e230"; }

.glyphicon-ice-lolly:before {
  content: "\e231"; }

.glyphicon-ice-lolly-tasted:before {
  content: "\e232"; }

.glyphicon-education:before {
  content: "\e233"; }

.glyphicon-option-horizontal:before {
  content: "\e234"; }

.glyphicon-option-vertical:before {
  content: "\e235"; }

.glyphicon-menu-hamburger:before {
  content: "\e236"; }

.glyphicon-modal-window:before {
  content: "\e237"; }

.glyphicon-oil:before {
  content: "\e238"; }

.glyphicon-grain:before {
  content: "\e239"; }

.glyphicon-sunglasses:before {
  content: "\e240"; }

.glyphicon-text-size:before {
  content: "\e241"; }

.glyphicon-text-color:before {
  content: "\e242"; }

.glyphicon-text-background:before {
  content: "\e243"; }

.glyphicon-object-align-top:before {
  content: "\e244"; }

.glyphicon-object-align-bottom:before {
  content: "\e245"; }

.glyphicon-object-align-horizontal:before {
  content: "\e246"; }

.glyphicon-object-align-left:before {
  content: "\e247"; }

.glyphicon-object-align-vertical:before {
  content: "\e248"; }

.glyphicon-object-align-right:before {
  content: "\e249"; }

.glyphicon-triangle-right:before {
  content: "\e250"; }

.glyphicon-triangle-left:before {
  content: "\e251"; }

.glyphicon-triangle-bottom:before {
  content: "\e252"; }

.glyphicon-triangle-top:before {
  content: "\e253"; }

.glyphicon-console:before {
  content: "\e254"; }

.glyphicon-superscript:before {
  content: "\e255"; }

.glyphicon-subscript:before {
  content: "\e256"; }

.glyphicon-menu-left:before {
  content: "\e257"; }

.glyphicon-menu-right:before {
  content: "\e258"; }

.glyphicon-menu-down:before {
  content: "\e259"; }

.glyphicon-menu-up:before {
  content: "\e260"; }

* {
  box-sizing: border-box; }

*:before,
*:after {
  box-sizing: border-box; }

html {
  font-size: 10px;
  -webkit-tap-highlight-color: transparent; }

body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857;
  color: #333333;
  background-color: #fff; }

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

a {
  color: #337ab7;
  text-decoration: none; }
  a:hover, a:focus {
    color: #23527c;
    text-decoration: underline; }
  a:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }

figure {
  margin: 0; }

img {
  vertical-align: middle; }

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto; }

.img-rounded {
  border-radius: 6px; }

.img-thumbnail {
  padding: 4px;
  line-height: 1.42857;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  max-width: 100%;
  height: auto; }

.img-circle {
  border-radius: 50%; }

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eeeeee; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto; }

[role="button"] {
  cursor: pointer; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit; }
  h1 small,
  h1 .small, h2 small,
  h2 .small, h3 small,
  h3 .small, h4 small,
  h4 .small, h5 small,
  h5 .small, h6 small,
  h6 .small,
  .h1 small,
  .h1 .small, .h2 small,
  .h2 .small, .h3 small,
  .h3 .small, .h4 small,
  .h4 .small, .h5 small,
  .h5 .small, .h6 small,
  .h6 .small {
    font-weight: normal;
    line-height: 1;
    color: #777777; }

h1, .h1,
h2, .h2,
h3, .h3 {
  margin-top: 20px;
  margin-bottom: 10px; }
  h1 small,
  h1 .small, .h1 small,
  .h1 .small,
  h2 small,
  h2 .small, .h2 small,
  .h2 .small,
  h3 small,
  h3 .small, .h3 small,
  .h3 .small {
    font-size: 65%; }

h4, .h4,
h5, .h5,
h6, .h6 {
  margin-top: 10px;
  margin-bottom: 10px; }
  h4 small,
  h4 .small, .h4 small,
  .h4 .small,
  h5 small,
  h5 .small, .h5 small,
  .h5 .small,
  h6 small,
  h6 .small, .h6 small,
  .h6 .small {
    font-size: 75%; }

h1, .h1 {
  font-size: 36px; }

h2, .h2 {
  font-size: 30px; }

h3, .h3 {
  font-size: 24px; }

h4, .h4 {
  font-size: 18px; }

h5, .h5 {
  font-size: 14px; }

h6, .h6 {
  font-size: 12px; }

p {
  margin: 0 0 10px; }

.lead {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.4; }
  @media (min-width: 768px) {
    .lead {
      font-size: 21px; } }

small,
.small {
  font-size: 85%; }

mark,
.mark {
  background-color: #fcf8e3;
  padding: .2em; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

.text-nowrap {
  white-space: nowrap; }

.text-lowercase {
  text-transform: lowercase; }

.text-uppercase, .initialism {
  text-transform: uppercase; }

.text-capitalize {
  text-transform: capitalize; }

.text-muted {
  color: #777777; }

.text-primary {
  color: #337ab7; }

a.text-primary:hover,
a.text-primary:focus {
  color: #286090; }

.text-success {
  color: #3c763d; }

a.text-success:hover,
a.text-success:focus {
  color: #2b542c; }

.text-info {
  color: #31708f; }

a.text-info:hover,
a.text-info:focus {
  color: #245269; }

.text-warning {
  color: #8a6d3b; }

a.text-warning:hover,
a.text-warning:focus {
  color: #66512c; }

.text-danger {
  color: #a94442; }

a.text-danger:hover,
a.text-danger:focus {
  color: #843534; }

.bg-primary {
  color: #fff; }

.bg-primary {
  background-color: #337ab7; }

a.bg-primary:hover,
a.bg-primary:focus {
  background-color: #286090; }

.bg-success {
  background-color: #dff0d8; }

a.bg-success:hover,
a.bg-success:focus {
  background-color: #c1e2b3; }

.bg-info {
  background-color: #d9edf7; }

a.bg-info:hover,
a.bg-info:focus {
  background-color: #afd9ee; }

.bg-warning {
  background-color: #fcf8e3; }

a.bg-warning:hover,
a.bg-warning:focus {
  background-color: #f7ecb5; }

.bg-danger {
  background-color: #f2dede; }

a.bg-danger:hover,
a.bg-danger:focus {
  background-color: #e4b9b9; }

.page-header {
  padding-bottom: 9px;
  margin: 40px 0 20px;
  border-bottom: 1px solid #eeeeee; }

ul,
ol {
  margin-top: 0;
  margin-bottom: 10px; }
  ul ul,
  ul ol,
  ol ul,
  ol ol {
    margin-bottom: 0; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none;
  margin-left: -5px; }
  .list-inline > li {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px; }

dl {
  margin-top: 0;
  margin-bottom: 20px; }

dt,
dd {
  line-height: 1.42857; }

dt {
  font-weight: bold; }

dd {
  margin-left: 0; }

.dl-horizontal dd:before, .dl-horizontal dd:after {
  content: " ";
  display: table; }

.dl-horizontal dd:after {
  clear: both; }

@media (min-width: 768px) {
  .dl-horizontal dt {
    float: left;
    width: 160px;
    clear: left;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .dl-horizontal dd {
    margin-left: 180px; } }

abbr[title],
abbr[data-original-title] {
  cursor: help;
  border-bottom: 1px dotted #777777; }

.initialism {
  font-size: 90%; }

blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 17.5px;
  border-left: 5px solid #eeeeee; }
  blockquote p:last-child,
  blockquote ul:last-child,
  blockquote ol:last-child {
    margin-bottom: 0; }
  blockquote footer,
  blockquote small,
  blockquote .small {
    display: block;
    font-size: 80%;
    line-height: 1.42857;
    color: #777777; }
    blockquote footer:before,
    blockquote small:before,
    blockquote .small:before {
      content: '\2014 \00A0'; }

.blockquote-reverse,
blockquote.pull-right {
  padding-right: 15px;
  padding-left: 0;
  border-right: 5px solid #eeeeee;
  border-left: 0;
  text-align: right; }
  .blockquote-reverse footer:before,
  .blockquote-reverse small:before,
  .blockquote-reverse .small:before,
  blockquote.pull-right footer:before,
  blockquote.pull-right small:before,
  blockquote.pull-right .small:before {
    content: ''; }
  .blockquote-reverse footer:after,
  .blockquote-reverse small:after,
  .blockquote-reverse .small:after,
  blockquote.pull-right footer:after,
  blockquote.pull-right small:after,
  blockquote.pull-right .small:after {
    content: '\00A0 \2014'; }

address {
  margin-bottom: 20px;
  font-style: normal;
  line-height: 1.42857; }

code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace; }

code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px; }

kbd {
  padding: 2px 4px;
  font-size: 90%;
  color: #fff;
  background-color: #333;
  border-radius: 3px;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25); }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: bold;
    box-shadow: none; }

pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857;
  word-break: break-all;
  word-wrap: break-word;
  color: #333333;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px; }
  pre code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    white-space: pre-wrap;
    background-color: transparent;
    border-radius: 0; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px; }
  .container:before, .container:after {
    content: " ";
    display: table; }
  .container:after {
    clear: both; }
  @media (min-width: 768px) {
    .container {
      width: 750px; } }
  @media (min-width: 992px) {
    .container {
      width: 970px; } }
  @media (min-width: 1200px) {
    .container {
      width: 1170px; } }

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px; }
  .container-fluid:before, .container-fluid:after {
    content: " ";
    display: table; }
  .container-fluid:after {
    clear: both; }

.row {
  margin-left: -15px;
  margin-right: -15px; }
  .row:before, .row:after {
    content: " ";
    display: table; }
  .row:after {
    clear: both; }

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px; }

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left; }

.col-xs-1 {
  width: 8.33333%; }

.col-xs-2 {
  width: 16.66667%; }

.col-xs-3 {
  width: 25%; }

.col-xs-4 {
  width: 33.33333%; }

.col-xs-5 {
  width: 41.66667%; }

.col-xs-6 {
  width: 50%; }

.col-xs-7 {
  width: 58.33333%; }

.col-xs-8 {
  width: 66.66667%; }

.col-xs-9 {
  width: 75%; }

.col-xs-10 {
  width: 83.33333%; }

.col-xs-11 {
  width: 91.66667%; }

.col-xs-12 {
  width: 100%; }

.col-xs-pull-0 {
  right: auto; }

.col-xs-pull-1 {
  right: 8.33333%; }

.col-xs-pull-2 {
  right: 16.66667%; }

.col-xs-pull-3 {
  right: 25%; }

.col-xs-pull-4 {
  right: 33.33333%; }

.col-xs-pull-5 {
  right: 41.66667%; }

.col-xs-pull-6 {
  right: 50%; }

.col-xs-pull-7 {
  right: 58.33333%; }

.col-xs-pull-8 {
  right: 66.66667%; }

.col-xs-pull-9 {
  right: 75%; }

.col-xs-pull-10 {
  right: 83.33333%; }

.col-xs-pull-11 {
  right: 91.66667%; }

.col-xs-pull-12 {
  right: 100%; }

.col-xs-push-0 {
  left: auto; }

.col-xs-push-1 {
  left: 8.33333%; }

.col-xs-push-2 {
  left: 16.66667%; }

.col-xs-push-3 {
  left: 25%; }

.col-xs-push-4 {
  left: 33.33333%; }

.col-xs-push-5 {
  left: 41.66667%; }

.col-xs-push-6 {
  left: 50%; }

.col-xs-push-7 {
  left: 58.33333%; }

.col-xs-push-8 {
  left: 66.66667%; }

.col-xs-push-9 {
  left: 75%; }

.col-xs-push-10 {
  left: 83.33333%; }

.col-xs-push-11 {
  left: 91.66667%; }

.col-xs-push-12 {
  left: 100%; }

.col-xs-offset-0 {
  margin-left: 0%; }

.col-xs-offset-1 {
  margin-left: 8.33333%; }

.col-xs-offset-2 {
  margin-left: 16.66667%; }

.col-xs-offset-3 {
  margin-left: 25%; }

.col-xs-offset-4 {
  margin-left: 33.33333%; }

.col-xs-offset-5 {
  margin-left: 41.66667%; }

.col-xs-offset-6 {
  margin-left: 50%; }

.col-xs-offset-7 {
  margin-left: 58.33333%; }

.col-xs-offset-8 {
  margin-left: 66.66667%; }

.col-xs-offset-9 {
  margin-left: 75%; }

.col-xs-offset-10 {
  margin-left: 83.33333%; }

.col-xs-offset-11 {
  margin-left: 91.66667%; }

.col-xs-offset-12 {
  margin-left: 100%; }

@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left; }
  .col-sm-1 {
    width: 8.33333%; }
  .col-sm-2 {
    width: 16.66667%; }
  .col-sm-3 {
    width: 25%; }
  .col-sm-4 {
    width: 33.33333%; }
  .col-sm-5 {
    width: 41.66667%; }
  .col-sm-6 {
    width: 50%; }
  .col-sm-7 {
    width: 58.33333%; }
  .col-sm-8 {
    width: 66.66667%; }
  .col-sm-9 {
    width: 75%; }
  .col-sm-10 {
    width: 83.33333%; }
  .col-sm-11 {
    width: 91.66667%; }
  .col-sm-12 {
    width: 100%; }
  .col-sm-pull-0 {
    right: auto; }
  .col-sm-pull-1 {
    right: 8.33333%; }
  .col-sm-pull-2 {
    right: 16.66667%; }
  .col-sm-pull-3 {
    right: 25%; }
  .col-sm-pull-4 {
    right: 33.33333%; }
  .col-sm-pull-5 {
    right: 41.66667%; }
  .col-sm-pull-6 {
    right: 50%; }
  .col-sm-pull-7 {
    right: 58.33333%; }
  .col-sm-pull-8 {
    right: 66.66667%; }
  .col-sm-pull-9 {
    right: 75%; }
  .col-sm-pull-10 {
    right: 83.33333%; }
  .col-sm-pull-11 {
    right: 91.66667%; }
  .col-sm-pull-12 {
    right: 100%; }
  .col-sm-push-0 {
    left: auto; }
  .col-sm-push-1 {
    left: 8.33333%; }
  .col-sm-push-2 {
    left: 16.66667%; }
  .col-sm-push-3 {
    left: 25%; }
  .col-sm-push-4 {
    left: 33.33333%; }
  .col-sm-push-5 {
    left: 41.66667%; }
  .col-sm-push-6 {
    left: 50%; }
  .col-sm-push-7 {
    left: 58.33333%; }
  .col-sm-push-8 {
    left: 66.66667%; }
  .col-sm-push-9 {
    left: 75%; }
  .col-sm-push-10 {
    left: 83.33333%; }
  .col-sm-push-11 {
    left: 91.66667%; }
  .col-sm-push-12 {
    left: 100%; }
  .col-sm-offset-0 {
    margin-left: 0%; }
  .col-sm-offset-1 {
    margin-left: 8.33333%; }
  .col-sm-offset-2 {
    margin-left: 16.66667%; }
  .col-sm-offset-3 {
    margin-left: 25%; }
  .col-sm-offset-4 {
    margin-left: 33.33333%; }
  .col-sm-offset-5 {
    margin-left: 41.66667%; }
  .col-sm-offset-6 {
    margin-left: 50%; }
  .col-sm-offset-7 {
    margin-left: 58.33333%; }
  .col-sm-offset-8 {
    margin-left: 66.66667%; }
  .col-sm-offset-9 {
    margin-left: 75%; }
  .col-sm-offset-10 {
    margin-left: 83.33333%; }
  .col-sm-offset-11 {
    margin-left: 91.66667%; }
  .col-sm-offset-12 {
    margin-left: 100%; } }

@media (min-width: 992px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left; }
  .col-md-1 {
    width: 8.33333%; }
  .col-md-2 {
    width: 16.66667%; }
  .col-md-3 {
    width: 25%; }
  .col-md-4 {
    width: 33.33333%; }
  .col-md-5 {
    width: 41.66667%; }
  .col-md-6 {
    width: 50%; }
  .col-md-7 {
    width: 58.33333%; }
  .col-md-8 {
    width: 66.66667%; }
  .col-md-9 {
    width: 75%; }
  .col-md-10 {
    width: 83.33333%; }
  .col-md-11 {
    width: 91.66667%; }
  .col-md-12 {
    width: 100%; }
  .col-md-pull-0 {
    right: auto; }
  .col-md-pull-1 {
    right: 8.33333%; }
  .col-md-pull-2 {
    right: 16.66667%; }
  .col-md-pull-3 {
    right: 25%; }
  .col-md-pull-4 {
    right: 33.33333%; }
  .col-md-pull-5 {
    right: 41.66667%; }
  .col-md-pull-6 {
    right: 50%; }
  .col-md-pull-7 {
    right: 58.33333%; }
  .col-md-pull-8 {
    right: 66.66667%; }
  .col-md-pull-9 {
    right: 75%; }
  .col-md-pull-10 {
    right: 83.33333%; }
  .col-md-pull-11 {
    right: 91.66667%; }
  .col-md-pull-12 {
    right: 100%; }
  .col-md-push-0 {
    left: auto; }
  .col-md-push-1 {
    left: 8.33333%; }
  .col-md-push-2 {
    left: 16.66667%; }
  .col-md-push-3 {
    left: 25%; }
  .col-md-push-4 {
    left: 33.33333%; }
  .col-md-push-5 {
    left: 41.66667%; }
  .col-md-push-6 {
    left: 50%; }
  .col-md-push-7 {
    left: 58.33333%; }
  .col-md-push-8 {
    left: 66.66667%; }
  .col-md-push-9 {
    left: 75%; }
  .col-md-push-10 {
    left: 83.33333%; }
  .col-md-push-11 {
    left: 91.66667%; }
  .col-md-push-12 {
    left: 100%; }
  .col-md-offset-0 {
    margin-left: 0%; }
  .col-md-offset-1 {
    margin-left: 8.33333%; }
  .col-md-offset-2 {
    margin-left: 16.66667%; }
  .col-md-offset-3 {
    margin-left: 25%; }
  .col-md-offset-4 {
    margin-left: 33.33333%; }
  .col-md-offset-5 {
    margin-left: 41.66667%; }
  .col-md-offset-6 {
    margin-left: 50%; }
  .col-md-offset-7 {
    margin-left: 58.33333%; }
  .col-md-offset-8 {
    margin-left: 66.66667%; }
  .col-md-offset-9 {
    margin-left: 75%; }
  .col-md-offset-10 {
    margin-left: 83.33333%; }
  .col-md-offset-11 {
    margin-left: 91.66667%; }
  .col-md-offset-12 {
    margin-left: 100%; } }

@media (min-width: 1200px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left; }
  .col-lg-1 {
    width: 8.33333%; }
  .col-lg-2 {
    width: 16.66667%; }
  .col-lg-3 {
    width: 25%; }
  .col-lg-4 {
    width: 33.33333%; }
  .col-lg-5 {
    width: 41.66667%; }
  .col-lg-6 {
    width: 50%; }
  .col-lg-7 {
    width: 58.33333%; }
  .col-lg-8 {
    width: 66.66667%; }
  .col-lg-9 {
    width: 75%; }
  .col-lg-10 {
    width: 83.33333%; }
  .col-lg-11 {
    width: 91.66667%; }
  .col-lg-12 {
    width: 100%; }
  .col-lg-pull-0 {
    right: auto; }
  .col-lg-pull-1 {
    right: 8.33333%; }
  .col-lg-pull-2 {
    right: 16.66667%; }
  .col-lg-pull-3 {
    right: 25%; }
  .col-lg-pull-4 {
    right: 33.33333%; }
  .col-lg-pull-5 {
    right: 41.66667%; }
  .col-lg-pull-6 {
    right: 50%; }
  .col-lg-pull-7 {
    right: 58.33333%; }
  .col-lg-pull-8 {
    right: 66.66667%; }
  .col-lg-pull-9 {
    right: 75%; }
  .col-lg-pull-10 {
    right: 83.33333%; }
  .col-lg-pull-11 {
    right: 91.66667%; }
  .col-lg-pull-12 {
    right: 100%; }
  .col-lg-push-0 {
    left: auto; }
  .col-lg-push-1 {
    left: 8.33333%; }
  .col-lg-push-2 {
    left: 16.66667%; }
  .col-lg-push-3 {
    left: 25%; }
  .col-lg-push-4 {
    left: 33.33333%; }
  .col-lg-push-5 {
    left: 41.66667%; }
  .col-lg-push-6 {
    left: 50%; }
  .col-lg-push-7 {
    left: 58.33333%; }
  .col-lg-push-8 {
    left: 66.66667%; }
  .col-lg-push-9 {
    left: 75%; }
  .col-lg-push-10 {
    left: 83.33333%; }
  .col-lg-push-11 {
    left: 91.66667%; }
  .col-lg-push-12 {
    left: 100%; }
  .col-lg-offset-0 {
    margin-left: 0%; }
  .col-lg-offset-1 {
    margin-left: 8.33333%; }
  .col-lg-offset-2 {
    margin-left: 16.66667%; }
  .col-lg-offset-3 {
    margin-left: 25%; }
  .col-lg-offset-4 {
    margin-left: 33.33333%; }
  .col-lg-offset-5 {
    margin-left: 41.66667%; }
  .col-lg-offset-6 {
    margin-left: 50%; }
  .col-lg-offset-7 {
    margin-left: 58.33333%; }
  .col-lg-offset-8 {
    margin-left: 66.66667%; }
  .col-lg-offset-9 {
    margin-left: 75%; }
  .col-lg-offset-10 {
    margin-left: 83.33333%; }
  .col-lg-offset-11 {
    margin-left: 91.66667%; }
  .col-lg-offset-12 {
    margin-left: 100%; } }

table {
  background-color: transparent; }

caption {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #777777;
  text-align: left; }

th {
  text-align: left; }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px; }
  .table > thead > tr > th,
  .table > thead > tr > td,
  .table > tbody > tr > th,
  .table > tbody > tr > td,
  .table > tfoot > tr > th,
  .table > tfoot > tr > td {
    padding: 8px;
    line-height: 1.42857;
    vertical-align: top;
    border-top: 1px solid #ddd; }
  .table > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 2px solid #ddd; }
  .table > caption + thead > tr:first-child > th,
  .table > caption + thead > tr:first-child > td,
  .table > colgroup + thead > tr:first-child > th,
  .table > colgroup + thead > tr:first-child > td,
  .table > thead:first-child > tr:first-child > th,
  .table > thead:first-child > tr:first-child > td {
    border-top: 0; }
  .table > tbody + tbody {
    border-top: 2px solid #ddd; }
  .table .table {
    background-color: #fff; }

.table-condensed > thead > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > th,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > th,
.table-condensed > tfoot > tr > td {
  padding: 5px; }

.table-bordered {
  border: 1px solid #ddd; }
  .table-bordered > thead > tr > th,
  .table-bordered > thead > tr > td,
  .table-bordered > tbody > tr > th,
  .table-bordered > tbody > tr > td,
  .table-bordered > tfoot > tr > th,
  .table-bordered > tfoot > tr > td {
    border: 1px solid #ddd; }
  .table-bordered > thead > tr > th,
  .table-bordered > thead > tr > td {
    border-bottom-width: 2px; }

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f9f9f9; }

.table-hover > tbody > tr:hover {
  background-color: #f5f5f5; }

table col[class*="col-"] {
  position: static;
  float: none;
  display: table-column; }

table td[class*="col-"],
table th[class*="col-"] {
  position: static;
  float: none;
  display: table-cell; }

.table > thead > tr > td.active,
.table > thead > tr > th.active,
.table > thead > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr > td.active,
.table > tbody > tr > th.active,
.table > tbody > tr.active > td,
.table > tbody > tr.active > th,
.table > tfoot > tr > td.active,
.table > tfoot > tr > th.active,
.table > tfoot > tr.active > td,
.table > tfoot > tr.active > th {
  background-color: #f5f5f5; }

.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover,
.table-hover > tbody > tr.active:hover > td,
.table-hover > tbody > tr:hover > .active,
.table-hover > tbody > tr.active:hover > th {
  background-color: #e8e8e8; }

.table > thead > tr > td.success,
.table > thead > tr > th.success,
.table > thead > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr > td.success,
.table > tbody > tr > th.success,
.table > tbody > tr.success > td,
.table > tbody > tr.success > th,
.table > tfoot > tr > td.success,
.table > tfoot > tr > th.success,
.table > tfoot > tr.success > td,
.table > tfoot > tr.success > th {
  background-color: #dff0d8; }

.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover,
.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr:hover > .success,
.table-hover > tbody > tr.success:hover > th {
  background-color: #d0e9c6; }

.table > thead > tr > td.info,
.table > thead > tr > th.info,
.table > thead > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr > td.info,
.table > tbody > tr > th.info,
.table > tbody > tr.info > td,
.table > tbody > tr.info > th,
.table > tfoot > tr > td.info,
.table > tfoot > tr > th.info,
.table > tfoot > tr.info > td,
.table > tfoot > tr.info > th {
  background-color: #d9edf7; }

.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover,
.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr:hover > .info,
.table-hover > tbody > tr.info:hover > th {
  background-color: #c4e3f3; }

.table > thead > tr > td.warning,
.table > thead > tr > th.warning,
.table > thead > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr > td.warning,
.table > tbody > tr > th.warning,
.table > tbody > tr.warning > td,
.table > tbody > tr.warning > th,
.table > tfoot > tr > td.warning,
.table > tfoot > tr > th.warning,
.table > tfoot > tr.warning > td,
.table > tfoot > tr.warning > th {
  background-color: #fcf8e3; }

.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover,
.table-hover > tbody > tr.warning:hover > td,
.table-hover > tbody > tr:hover > .warning,
.table-hover > tbody > tr.warning:hover > th {
  background-color: #faf2cc; }

.table > thead > tr > td.danger,
.table > thead > tr > th.danger,
.table > thead > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr > td.danger,
.table > tbody > tr > th.danger,
.table > tbody > tr.danger > td,
.table > tbody > tr.danger > th,
.table > tfoot > tr > td.danger,
.table > tfoot > tr > th.danger,
.table > tfoot > tr.danger > td,
.table > tfoot > tr.danger > th {
  background-color: #f2dede; }

.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover,
.table-hover > tbody > tr.danger:hover > td,
.table-hover > tbody > tr:hover > .danger,
.table-hover > tbody > tr.danger:hover > th {
  background-color: #ebcccc; }

.table-responsive {
  overflow-x: auto;
  min-height: 0.01%; }
  @media screen and (max-width: 767px) {
    .table-responsive {
      width: 100%;
      margin-bottom: 15px;
      overflow-y: hidden;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      border: 1px solid #ddd; }
      .table-responsive > .table {
        margin-bottom: 0; }
        .table-responsive > .table > thead > tr > th,
        .table-responsive > .table > thead > tr > td,
        .table-responsive > .table > tbody > tr > th,
        .table-responsive > .table > tbody > tr > td,
        .table-responsive > .table > tfoot > tr > th,
        .table-responsive > .table > tfoot > tr > td {
          white-space: nowrap; }
      .table-responsive > .table-bordered {
        border: 0; }
        .table-responsive > .table-bordered > thead > tr > th:first-child,
        .table-responsive > .table-bordered > thead > tr > td:first-child,
        .table-responsive > .table-bordered > tbody > tr > th:first-child,
        .table-responsive > .table-bordered > tbody > tr > td:first-child,
        .table-responsive > .table-bordered > tfoot > tr > th:first-child,
        .table-responsive > .table-bordered > tfoot > tr > td:first-child {
          border-left: 0; }
        .table-responsive > .table-bordered > thead > tr > th:last-child,
        .table-responsive > .table-bordered > thead > tr > td:last-child,
        .table-responsive > .table-bordered > tbody > tr > th:last-child,
        .table-responsive > .table-bordered > tbody > tr > td:last-child,
        .table-responsive > .table-bordered > tfoot > tr > th:last-child,
        .table-responsive > .table-bordered > tfoot > tr > td:last-child {
          border-right: 0; }
        .table-responsive > .table-bordered > tbody > tr:last-child > th,
        .table-responsive > .table-bordered > tbody > tr:last-child > td,
        .table-responsive > .table-bordered > tfoot > tr:last-child > th,
        .table-responsive > .table-bordered > tfoot > tr:last-child > td {
          border-bottom: 0; } }

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  min-width: 0; }

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: inherit;
  color: #333333;
  border: 0;
  border-bottom: 1px solid #e5e5e5; }

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold; }

input[type="search"] {
  box-sizing: border-box; }

input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal; }

input[type="file"] {
  display: block; }

input[type="range"] {
  display: block;
  width: 100%; }

select[multiple],
select[size] {
  height: auto; }

input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }

output {
  display: block;
  padding-top: 7px;
  font-size: 14px;
  line-height: 1.42857;
  color: #555555; }

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s; }
  .form-control:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }
  .form-control::-moz-placeholder {
    color: #999;
    opacity: 1; }
  .form-control:-ms-input-placeholder {
    color: #999; }
  .form-control::-webkit-input-placeholder {
    color: #999; }
  .form-control::-ms-expand {
    border: 0;
    background-color: transparent; }
  .form-control[disabled], .form-control[readonly],
  fieldset[disabled] .form-control {
    background-color: #eeeeee;
    opacity: 1; }
  .form-control[disabled],
  fieldset[disabled] .form-control {
    cursor: not-allowed; }

textarea.form-control {
  height: auto; }

input[type="search"] {
  -webkit-appearance: none; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="date"].form-control,
  input[type="time"].form-control,
  input[type="datetime-local"].form-control,
  input[type="month"].form-control {
    line-height: 34px; }
  input[type="date"].input-sm, .input-group-sm > input[type="date"].form-control,
  .input-group-sm > input[type="date"].input-group-addon,
  .input-group-sm > .input-group-btn > input[type="date"].btn,
  .input-group-sm input[type="date"],
  input[type="time"].input-sm,
  .input-group-sm > input[type="time"].form-control,
  .input-group-sm > input[type="time"].input-group-addon,
  .input-group-sm > .input-group-btn > input[type="time"].btn,
  .input-group-sm
  input[type="time"],
  input[type="datetime-local"].input-sm,
  .input-group-sm > input[type="datetime-local"].form-control,
  .input-group-sm > input[type="datetime-local"].input-group-addon,
  .input-group-sm > .input-group-btn > input[type="datetime-local"].btn,
  .input-group-sm
  input[type="datetime-local"],
  input[type="month"].input-sm,
  .input-group-sm > input[type="month"].form-control,
  .input-group-sm > input[type="month"].input-group-addon,
  .input-group-sm > .input-group-btn > input[type="month"].btn,
  .input-group-sm
  input[type="month"] {
    line-height: 30px; }
  input[type="date"].input-lg, .input-group-lg > input[type="date"].form-control,
  .input-group-lg > input[type="date"].input-group-addon,
  .input-group-lg > .input-group-btn > input[type="date"].btn,
  .input-group-lg input[type="date"],
  input[type="time"].input-lg,
  .input-group-lg > input[type="time"].form-control,
  .input-group-lg > input[type="time"].input-group-addon,
  .input-group-lg > .input-group-btn > input[type="time"].btn,
  .input-group-lg
  input[type="time"],
  input[type="datetime-local"].input-lg,
  .input-group-lg > input[type="datetime-local"].form-control,
  .input-group-lg > input[type="datetime-local"].input-group-addon,
  .input-group-lg > .input-group-btn > input[type="datetime-local"].btn,
  .input-group-lg
  input[type="datetime-local"],
  input[type="month"].input-lg,
  .input-group-lg > input[type="month"].form-control,
  .input-group-lg > input[type="month"].input-group-addon,
  .input-group-lg > .input-group-btn > input[type="month"].btn,
  .input-group-lg
  input[type="month"] {
    line-height: 46px; } }

.form-group {
  margin-bottom: 15px; }

.radio,
.checkbox {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px; }
  .radio label,
  .checkbox label {
    min-height: 20px;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer; }

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  position: absolute;
  margin-left: -20px;
  margin-top: 4px \9; }

.radio + .radio,
.checkbox + .checkbox {
  margin-top: -5px; }

.radio-inline,
.checkbox-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  vertical-align: middle;
  font-weight: normal;
  cursor: pointer; }

.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
  margin-top: 0;
  margin-left: 10px; }

input[type="radio"][disabled], input[type="radio"].disabled,
fieldset[disabled] input[type="radio"],
input[type="checkbox"][disabled],
input[type="checkbox"].disabled,
fieldset[disabled]
input[type="checkbox"] {
  cursor: not-allowed; }

.radio-inline.disabled,
fieldset[disabled] .radio-inline,
.checkbox-inline.disabled,
fieldset[disabled]
.checkbox-inline {
  cursor: not-allowed; }

.radio.disabled label,
fieldset[disabled] .radio label,
.checkbox.disabled label,
fieldset[disabled]
.checkbox label {
  cursor: not-allowed; }

.form-control-static {
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 0;
  min-height: 34px; }
  .form-control-static.input-lg, .input-group-lg > .form-control-static.form-control,
  .input-group-lg > .form-control-static.input-group-addon,
  .input-group-lg > .input-group-btn > .form-control-static.btn, .form-control-static.input-sm, .input-group-sm > .form-control-static.form-control,
  .input-group-sm > .form-control-static.input-group-addon,
  .input-group-sm > .input-group-btn > .form-control-static.btn {
    padding-left: 0;
    padding-right: 0; }

.input-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px; }

select.input-sm, .input-group-sm > select.form-control,
.input-group-sm > select.input-group-addon,
.input-group-sm > .input-group-btn > select.btn {
  height: 30px;
  line-height: 30px; }

textarea.input-sm, .input-group-sm > textarea.form-control,
.input-group-sm > textarea.input-group-addon,
.input-group-sm > .input-group-btn > textarea.btn,
select[multiple].input-sm,
.input-group-sm > select[multiple].form-control,
.input-group-sm > select[multiple].input-group-addon,
.input-group-sm > .input-group-btn > select[multiple].btn {
  height: auto; }

.form-group-sm .form-control {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px; }

.form-group-sm select.form-control {
  height: 30px;
  line-height: 30px; }

.form-group-sm textarea.form-control,
.form-group-sm select[multiple].form-control {
  height: auto; }

.form-group-sm .form-control-static {
  height: 30px;
  min-height: 32px;
  padding: 6px 10px;
  font-size: 12px;
  line-height: 1.5; }

.input-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
  border-radius: 6px; }

select.input-lg, .input-group-lg > select.form-control,
.input-group-lg > select.input-group-addon,
.input-group-lg > .input-group-btn > select.btn {
  height: 46px;
  line-height: 46px; }

textarea.input-lg, .input-group-lg > textarea.form-control,
.input-group-lg > textarea.input-group-addon,
.input-group-lg > .input-group-btn > textarea.btn,
select[multiple].input-lg,
.input-group-lg > select[multiple].form-control,
.input-group-lg > select[multiple].input-group-addon,
.input-group-lg > .input-group-btn > select[multiple].btn {
  height: auto; }

.form-group-lg .form-control {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
  border-radius: 6px; }

.form-group-lg select.form-control {
  height: 46px;
  line-height: 46px; }

.form-group-lg textarea.form-control,
.form-group-lg select[multiple].form-control {
  height: auto; }

.form-group-lg .form-control-static {
  height: 46px;
  min-height: 38px;
  padding: 11px 16px;
  font-size: 18px;
  line-height: 1.33333; }

.has-feedback {
  position: relative; }
  .has-feedback .form-control {
    padding-right: 42.5px; }

.form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none; }

.input-lg + .form-control-feedback, .input-group-lg > .form-control + .form-control-feedback,
.input-group-lg > .input-group-addon + .form-control-feedback,
.input-group-lg > .input-group-btn > .btn + .form-control-feedback,
.input-group-lg + .form-control-feedback,
.form-group-lg .form-control + .form-control-feedback {
  width: 46px;
  height: 46px;
  line-height: 46px; }

.input-sm + .form-control-feedback, .input-group-sm > .form-control + .form-control-feedback,
.input-group-sm > .input-group-addon + .form-control-feedback,
.input-group-sm > .input-group-btn > .btn + .form-control-feedback,
.input-group-sm + .form-control-feedback,
.form-group-sm .form-control + .form-control-feedback {
  width: 30px;
  height: 30px;
  line-height: 30px; }

.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline,
.has-success.radio label,
.has-success.checkbox label,
.has-success.radio-inline label,
.has-success.checkbox-inline label {
  color: #3c763d; }

.has-success .form-control {
  border-color: #3c763d;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  .has-success .form-control:focus {
    border-color: #2b542c;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168; }

.has-success .input-group-addon {
  color: #3c763d;
  border-color: #3c763d;
  background-color: #dff0d8; }

.has-success .form-control-feedback {
  color: #3c763d; }

.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline,
.has-warning.radio label,
.has-warning.checkbox label,
.has-warning.radio-inline label,
.has-warning.checkbox-inline label {
  color: #8a6d3b; }

.has-warning .form-control {
  border-color: #8a6d3b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  .has-warning .form-control:focus {
    border-color: #66512c;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b; }

.has-warning .input-group-addon {
  color: #8a6d3b;
  border-color: #8a6d3b;
  background-color: #fcf8e3; }

.has-warning .form-control-feedback {
  color: #8a6d3b; }

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
  color: #a94442; }

.has-error .form-control {
  border-color: #a94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  .has-error .form-control:focus {
    border-color: #843534;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483; }

.has-error .input-group-addon {
  color: #a94442;
  border-color: #a94442;
  background-color: #f2dede; }

.has-error .form-control-feedback {
  color: #a94442; }

.has-feedback label ~ .form-control-feedback {
  top: 25px; }

.has-feedback label.sr-only ~ .form-control-feedback {
  top: 0; }

.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #737373; }

@media (min-width: 768px) {
  .form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle; }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle; }
  .form-inline .form-control-static {
    display: inline-block; }
  .form-inline .input-group {
    display: inline-table;
    vertical-align: middle; }
    .form-inline .input-group .input-group-addon,
    .form-inline .input-group .input-group-btn,
    .form-inline .input-group .form-control {
      width: auto; }
  .form-inline .input-group > .form-control {
    width: 100%; }
  .form-inline .control-label {
    margin-bottom: 0;
    vertical-align: middle; }
  .form-inline .radio,
  .form-inline .checkbox {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle; }
    .form-inline .radio label,
    .form-inline .checkbox label {
      padding-left: 0; }
  .form-inline .radio input[type="radio"],
  .form-inline .checkbox input[type="checkbox"] {
    position: relative;
    margin-left: 0; }
  .form-inline .has-feedback .form-control-feedback {
    top: 0; } }

.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 7px; }

.form-horizontal .radio,
.form-horizontal .checkbox {
  min-height: 27px; }

.form-horizontal .form-group {
  margin-left: -15px;
  margin-right: -15px; }
  .form-horizontal .form-group:before, .form-horizontal .form-group:after {
    content: " ";
    display: table; }
  .form-horizontal .form-group:after {
    clear: both; }

@media (min-width: 768px) {
  .form-horizontal .control-label {
    text-align: right;
    margin-bottom: 0;
    padding-top: 7px; } }

.form-horizontal .has-feedback .form-control-feedback {
  right: 15px; }

@media (min-width: 768px) {
  .form-horizontal .form-group-lg .control-label {
    padding-top: 11px;
    font-size: 18px; } }

@media (min-width: 768px) {
  .form-horizontal .form-group-sm .control-label {
    padding-top: 6px;
    font-size: 12px; } }

.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  -ms-touch-action: manipulation;
      touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .btn:focus, .btn.focus, .btn:active:focus, .btn:active.focus, .btn.active:focus, .btn.active.focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
  .btn:hover, .btn:focus, .btn.focus {
    color: #333;
    text-decoration: none; }
  .btn:active, .btn.active {
    outline: 0;
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .btn.disabled, .btn[disabled],
  fieldset[disabled] .btn {
    cursor: not-allowed;
    opacity: 0.65;
    filter: alpha(opacity=65);
    box-shadow: none; }

a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none; }

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc; }
  .btn-default:focus, .btn-default.focus {
    color: #333;
    background-color: #e6e6e6;
    border-color: #8c8c8c; }
  .btn-default:hover {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad; }
  .btn-default:active, .btn-default.active,
  .open > .btn-default.dropdown-toggle {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad; }
    .btn-default:active:hover, .btn-default:active:focus, .btn-default:active.focus, .btn-default.active:hover, .btn-default.active:focus, .btn-default.active.focus,
    .open > .btn-default.dropdown-toggle:hover,
    .open > .btn-default.dropdown-toggle:focus,
    .open > .btn-default.dropdown-toggle.focus {
      color: #333;
      background-color: #d4d4d4;
      border-color: #8c8c8c; }
  .btn-default:active, .btn-default.active,
  .open > .btn-default.dropdown-toggle {
    background-image: none; }
  .btn-default.disabled:hover, .btn-default.disabled:focus, .btn-default.disabled.focus, .btn-default[disabled]:hover, .btn-default[disabled]:focus, .btn-default[disabled].focus,
  fieldset[disabled] .btn-default:hover,
  fieldset[disabled] .btn-default:focus,
  fieldset[disabled] .btn-default.focus {
    background-color: #fff;
    border-color: #ccc; }
  .btn-default .badge {
    color: #fff;
    background-color: #333; }

.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #286090;
    border-color: #122b40; }
  .btn-primary:hover {
    color: #fff;
    background-color: #286090;
    border-color: #204d74; }
  .btn-primary:active, .btn-primary.active,
  .open > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #286090;
    border-color: #204d74; }
    .btn-primary:active:hover, .btn-primary:active:focus, .btn-primary:active.focus, .btn-primary.active:hover, .btn-primary.active:focus, .btn-primary.active.focus,
    .open > .btn-primary.dropdown-toggle:hover,
    .open > .btn-primary.dropdown-toggle:focus,
    .open > .btn-primary.dropdown-toggle.focus {
      color: #fff;
      background-color: #204d74;
      border-color: #122b40; }
  .btn-primary:active, .btn-primary.active,
  .open > .btn-primary.dropdown-toggle {
    background-image: none; }
  .btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled.focus, .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary[disabled].focus,
  fieldset[disabled] .btn-primary:hover,
  fieldset[disabled] .btn-primary:focus,
  fieldset[disabled] .btn-primary.focus {
    background-color: #337ab7;
    border-color: #2e6da4; }
  .btn-primary .badge {
    color: #337ab7;
    background-color: #fff; }

.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #449d44;
    border-color: #255625; }
  .btn-success:hover {
    color: #fff;
    background-color: #449d44;
    border-color: #398439; }
  .btn-success:active, .btn-success.active,
  .open > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #449d44;
    border-color: #398439; }
    .btn-success:active:hover, .btn-success:active:focus, .btn-success:active.focus, .btn-success.active:hover, .btn-success.active:focus, .btn-success.active.focus,
    .open > .btn-success.dropdown-toggle:hover,
    .open > .btn-success.dropdown-toggle:focus,
    .open > .btn-success.dropdown-toggle.focus {
      color: #fff;
      background-color: #398439;
      border-color: #255625; }
  .btn-success:active, .btn-success.active,
  .open > .btn-success.dropdown-toggle {
    background-image: none; }
  .btn-success.disabled:hover, .btn-success.disabled:focus, .btn-success.disabled.focus, .btn-success[disabled]:hover, .btn-success[disabled]:focus, .btn-success[disabled].focus,
  fieldset[disabled] .btn-success:hover,
  fieldset[disabled] .btn-success:focus,
  fieldset[disabled] .btn-success.focus {
    background-color: #5cb85c;
    border-color: #4cae4c; }
  .btn-success .badge {
    color: #5cb85c;
    background-color: #fff; }

.btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #31b0d5;
    border-color: #1b6d85; }
  .btn-info:hover {
    color: #fff;
    background-color: #31b0d5;
    border-color: #269abc; }
  .btn-info:active, .btn-info.active,
  .open > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #31b0d5;
    border-color: #269abc; }
    .btn-info:active:hover, .btn-info:active:focus, .btn-info:active.focus, .btn-info.active:hover, .btn-info.active:focus, .btn-info.active.focus,
    .open > .btn-info.dropdown-toggle:hover,
    .open > .btn-info.dropdown-toggle:focus,
    .open > .btn-info.dropdown-toggle.focus {
      color: #fff;
      background-color: #269abc;
      border-color: #1b6d85; }
  .btn-info:active, .btn-info.active,
  .open > .btn-info.dropdown-toggle {
    background-image: none; }
  .btn-info.disabled:hover, .btn-info.disabled:focus, .btn-info.disabled.focus, .btn-info[disabled]:hover, .btn-info[disabled]:focus, .btn-info[disabled].focus,
  fieldset[disabled] .btn-info:hover,
  fieldset[disabled] .btn-info:focus,
  fieldset[disabled] .btn-info.focus {
    background-color: #5bc0de;
    border-color: #46b8da; }
  .btn-info .badge {
    color: #5bc0de;
    background-color: #fff; }

.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236; }
  .btn-warning:focus, .btn-warning.focus {
    color: #fff;
    background-color: #ec971f;
    border-color: #985f0d; }
  .btn-warning:hover {
    color: #fff;
    background-color: #ec971f;
    border-color: #d58512; }
  .btn-warning:active, .btn-warning.active,
  .open > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #ec971f;
    border-color: #d58512; }
    .btn-warning:active:hover, .btn-warning:active:focus, .btn-warning:active.focus, .btn-warning.active:hover, .btn-warning.active:focus, .btn-warning.active.focus,
    .open > .btn-warning.dropdown-toggle:hover,
    .open > .btn-warning.dropdown-toggle:focus,
    .open > .btn-warning.dropdown-toggle.focus {
      color: #fff;
      background-color: #d58512;
      border-color: #985f0d; }
  .btn-warning:active, .btn-warning.active,
  .open > .btn-warning.dropdown-toggle {
    background-image: none; }
  .btn-warning.disabled:hover, .btn-warning.disabled:focus, .btn-warning.disabled.focus, .btn-warning[disabled]:hover, .btn-warning[disabled]:focus, .btn-warning[disabled].focus,
  fieldset[disabled] .btn-warning:hover,
  fieldset[disabled] .btn-warning:focus,
  fieldset[disabled] .btn-warning.focus {
    background-color: #f0ad4e;
    border-color: #eea236; }
  .btn-warning .badge {
    color: #f0ad4e;
    background-color: #fff; }

.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c9302c;
    border-color: #761c19; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c9302c;
    border-color: #ac2925; }
  .btn-danger:active, .btn-danger.active,
  .open > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #c9302c;
    border-color: #ac2925; }
    .btn-danger:active:hover, .btn-danger:active:focus, .btn-danger:active.focus, .btn-danger.active:hover, .btn-danger.active:focus, .btn-danger.active.focus,
    .open > .btn-danger.dropdown-toggle:hover,
    .open > .btn-danger.dropdown-toggle:focus,
    .open > .btn-danger.dropdown-toggle.focus {
      color: #fff;
      background-color: #ac2925;
      border-color: #761c19; }
  .btn-danger:active, .btn-danger.active,
  .open > .btn-danger.dropdown-toggle {
    background-image: none; }
  .btn-danger.disabled:hover, .btn-danger.disabled:focus, .btn-danger.disabled.focus, .btn-danger[disabled]:hover, .btn-danger[disabled]:focus, .btn-danger[disabled].focus,
  fieldset[disabled] .btn-danger:hover,
  fieldset[disabled] .btn-danger:focus,
  fieldset[disabled] .btn-danger.focus {
    background-color: #d9534f;
    border-color: #d43f3a; }
  .btn-danger .badge {
    color: #d9534f;
    background-color: #fff; }

.btn-link {
  color: #337ab7;
  font-weight: normal;
  border-radius: 0; }
  .btn-link, .btn-link:active, .btn-link.active, .btn-link[disabled],
  fieldset[disabled] .btn-link {
    background-color: transparent;
    box-shadow: none; }
  .btn-link, .btn-link:hover, .btn-link:focus, .btn-link:active {
    border-color: transparent; }
  .btn-link:hover, .btn-link:focus {
    color: #23527c;
    text-decoration: underline;
    background-color: transparent; }
  .btn-link[disabled]:hover, .btn-link[disabled]:focus,
  fieldset[disabled] .btn-link:hover,
  fieldset[disabled] .btn-link:focus {
    color: #777777;
    text-decoration: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
  border-radius: 6px; }

.btn-sm, .btn-group-sm > .btn {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px; }

.btn-xs, .btn-group-xs > .btn {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px; }

.btn-block {
  display: block;
  width: 100%; }

.btn-block + .btn-block {
  margin-top: 5px; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  opacity: 0;
  transition: opacity 0.15s linear; }
  .fade.in {
    opacity: 1; }

.collapse {
  display: none; }
  .collapse.in {
    display: block; }

tr.collapse.in {
  display: table-row; }

tbody.collapse.in {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition-property: height, visibility;
  transition-duration: 0.35s;
  transition-timing-function: ease; }

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent; }

.dropup,
.dropdown {
  position: relative; }

.dropdown-toggle:focus {
  outline: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box; }
  .dropdown-menu.pull-right {
    right: 0;
    left: auto; }
  .dropdown-menu .divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5; }
  .dropdown-menu > li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857;
    color: #333333;
    white-space: nowrap; }

.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
  text-decoration: none;
  color: #262626;
  background-color: #f5f5f5; }

.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  background-color: #337ab7; }

.dropdown-menu > .disabled > a, .dropdown-menu > .disabled > a:hover, .dropdown-menu > .disabled > a:focus {
  color: #777777; }

.dropdown-menu > .disabled > a:hover, .dropdown-menu > .disabled > a:focus {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  cursor: not-allowed; }

.open > .dropdown-menu {
  display: block; }

.open > a {
  outline: 0; }

.dropdown-menu-right {
  left: auto;
  right: 0; }

.dropdown-menu-left {
  left: 0;
  right: auto; }

.dropdown-header {
  display: block;
  padding: 3px 20px;
  font-size: 12px;
  line-height: 1.42857;
  color: #777777;
  white-space: nowrap; }

.dropdown-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 990; }

.pull-right > .dropdown-menu {
  right: 0;
  left: auto; }

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: ""; }

.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 2px; }

@media (min-width: 768px) {
  .navbar-right .dropdown-menu {
    right: 0;
    left: auto; }
  .navbar-right .dropdown-menu-left {
    left: 0;
    right: auto; } }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-block;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    float: left; }
    .btn-group > .btn:hover, .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:hover,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 2; }

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group {
  margin-left: -1px; }

.btn-toolbar {
  margin-left: -5px; }
  .btn-toolbar:before, .btn-toolbar:after {
    content: " ";
    display: table; }
  .btn-toolbar:after {
    clear: both; }
  .btn-toolbar .btn,
  .btn-toolbar .btn-group,
  .btn-toolbar .input-group {
    float: left; }
  .btn-toolbar > .btn,
  .btn-toolbar > .btn-group,
  .btn-toolbar > .input-group {
    margin-left: 5px; }

.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0; }

.btn-group > .btn:first-child {
  margin-left: 0; }
  .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }

.btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0; }

.btn-group > .btn-group {
  float: left; }

.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0; }

.btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0; }

.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0; }

.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
  outline: 0; }

.btn-group > .btn + .dropdown-toggle {
  padding-left: 8px;
  padding-right: 8px; }

.btn-group > .btn-lg + .dropdown-toggle, .btn-group-lg.btn-group > .btn + .dropdown-toggle {
  padding-left: 12px;
  padding-right: 12px; }

.btn-group.open .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .btn-group.open .dropdown-toggle.btn-link {
    box-shadow: none; }

.btn .caret {
  margin-left: 0; }

.btn-lg .caret, .btn-group-lg > .btn .caret {
  border-width: 5px 5px 0;
  border-bottom-width: 0; }

.dropup .btn-lg .caret, .dropup .btn-group-lg > .btn .caret {
  border-width: 0 5px 5px; }

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group,
.btn-group-vertical > .btn-group > .btn {
  display: block;
  float: none;
  width: 100%;
  max-width: 100%; }

.btn-group-vertical > .btn-group:before, .btn-group-vertical > .btn-group:after {
  content: " ";
  display: table; }

.btn-group-vertical > .btn-group:after {
  clear: both; }

.btn-group-vertical > .btn-group > .btn {
  float: none; }

.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0; }

.btn-group-vertical > .btn:not(:first-child):not(:last-child) {
  border-radius: 0; }

.btn-group-vertical > .btn:first-child:not(:last-child) {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group-vertical > .btn:last-child:not(:first-child) {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px; }

.btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0; }

.btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-right-radius: 0;
  border-top-left-radius: 0; }

.btn-group-justified {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: separate; }
  .btn-group-justified > .btn,
  .btn-group-justified > .btn-group {
    float: none;
    display: table-cell;
    width: 1%; }
  .btn-group-justified > .btn-group .btn {
    width: 100%; }
  .btn-group-justified > .btn-group .dropdown-menu {
    left: auto; }

[data-toggle="buttons"] > .btn input[type="radio"],
[data-toggle="buttons"] > .btn input[type="checkbox"],
[data-toggle="buttons"] > .btn-group > .btn input[type="radio"],
[data-toggle="buttons"] > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }

.input-group {
  position: relative;
  display: table;
  border-collapse: separate; }
  .input-group[class*="col-"] {
    float: none;
    padding-left: 0;
    padding-right: 0; }
  .input-group .form-control {
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0; }
    .input-group .form-control:focus {
      z-index: 3; }

.input-group-addon,
.input-group-btn,
.input-group .form-control {
  display: table-cell; }
  .input-group-addon:not(:first-child):not(:last-child),
  .input-group-btn:not(:first-child):not(:last-child),
  .input-group .form-control:not(:first-child):not(:last-child) {
    border-radius: 0; }

.input-group-addon,
.input-group-btn {
  width: 1%;
  white-space: nowrap;
  vertical-align: middle; }

.input-group-addon {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1;
  color: #555555;
  text-align: center;
  background-color: #eeeeee;
  border: 1px solid #ccc;
  border-radius: 4px; }
  .input-group-addon.input-sm,
  .input-group-sm > .input-group-addon,
  .input-group-sm > .input-group-btn > .input-group-addon.btn {
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 3px; }
  .input-group-addon.input-lg,
  .input-group-lg > .input-group-addon,
  .input-group-lg > .input-group-btn > .input-group-addon.btn {
    padding: 10px 16px;
    font-size: 18px;
    border-radius: 6px; }
  .input-group-addon input[type="radio"],
  .input-group-addon input[type="checkbox"] {
    margin-top: 0; }

.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group > .btn,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0; }

.input-group-addon:first-child {
  border-right: 0; }

.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group > .btn,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child),
.input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0; }

.input-group-addon:last-child {
  border-left: 0; }

.input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap; }
  .input-group-btn > .btn {
    position: relative; }
    .input-group-btn > .btn + .btn {
      margin-left: -1px; }
    .input-group-btn > .btn:hover, .input-group-btn > .btn:focus, .input-group-btn > .btn:active {
      z-index: 2; }
  .input-group-btn:first-child > .btn,
  .input-group-btn:first-child > .btn-group {
    margin-right: -1px; }
  .input-group-btn:last-child > .btn,
  .input-group-btn:last-child > .btn-group {
    z-index: 2;
    margin-left: -1px; }

.nav {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none; }
  .nav:before, .nav:after {
    content: " ";
    display: table; }
  .nav:after {
    clear: both; }
  .nav > li {
    position: relative;
    display: block; }
    .nav > li > a {
      position: relative;
      display: block;
      padding: 10px 15px; }
      .nav > li > a:hover, .nav > li > a:focus {
        text-decoration: none;
        background-color: #eeeeee; }
    .nav > li.disabled > a {
      color: #777777; }
      .nav > li.disabled > a:hover, .nav > li.disabled > a:focus {
        color: #777777;
        text-decoration: none;
        background-color: transparent;
        cursor: not-allowed; }
  .nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
    background-color: #eeeeee;
    border-color: #337ab7; }
  .nav .nav-divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5; }
  .nav > li > a > img {
    max-width: none; }

.nav-tabs {
  border-bottom: 1px solid #ddd; }
  .nav-tabs > li {
    float: left;
    margin-bottom: -1px; }
    .nav-tabs > li > a {
      margin-right: 2px;
      line-height: 1.42857;
      border: 1px solid transparent;
      border-radius: 4px 4px 0 0; }
      .nav-tabs > li > a:hover {
        border-color: #eeeeee #eeeeee #ddd; }
    .nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
      color: #555555;
      background-color: #fff;
      border: 1px solid #ddd;
      border-bottom-color: transparent;
      cursor: default; }

.nav-pills > li {
  float: left; }
  .nav-pills > li > a {
    border-radius: 4px; }
  .nav-pills > li + li {
    margin-left: 2px; }
  .nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
    color: #fff;
    background-color: #337ab7; }

.nav-stacked > li {
  float: none; }
  .nav-stacked > li + li {
    margin-top: 2px;
    margin-left: 0; }

.nav-justified, .nav-tabs.nav-justified {
  width: 100%; }
  .nav-justified > li, .nav-tabs.nav-justified > li {
    float: none; }
    .nav-justified > li > a, .nav-tabs.nav-justified > li > a {
      text-align: center;
      margin-bottom: 5px; }
  .nav-justified > .dropdown .dropdown-menu {
    top: auto;
    left: auto; }
  @media (min-width: 768px) {
    .nav-justified > li, .nav-tabs.nav-justified > li {
      display: table-cell;
      width: 1%; }
      .nav-justified > li > a, .nav-tabs.nav-justified > li > a {
        margin-bottom: 0; } }

.nav-tabs-justified, .nav-tabs.nav-justified {
  border-bottom: 0; }
  .nav-tabs-justified > li > a, .nav-tabs.nav-justified > li > a {
    margin-right: 0;
    border-radius: 4px; }
  .nav-tabs-justified > .active > a, .nav-tabs.nav-justified > .active > a,
  .nav-tabs-justified > .active > a:hover, .nav-tabs.nav-justified > .active > a:hover,
  .nav-tabs-justified > .active > a:focus, .nav-tabs.nav-justified > .active > a:focus {
    border: 1px solid #ddd; }
  @media (min-width: 768px) {
    .nav-tabs-justified > li > a, .nav-tabs.nav-justified > li > a {
      border-bottom: 1px solid #ddd;
      border-radius: 4px 4px 0 0; }
    .nav-tabs-justified > .active > a, .nav-tabs.nav-justified > .active > a,
    .nav-tabs-justified > .active > a:hover, .nav-tabs.nav-justified > .active > a:hover,
    .nav-tabs-justified > .active > a:focus, .nav-tabs.nav-justified > .active > a:focus {
      border-bottom-color: #fff; } }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-right-radius: 0;
  border-top-left-radius: 0; }

.navbar {
  position: relative;
  min-height: 50px;
  margin-bottom: 20px;
  border: 1px solid transparent; }
  .navbar:before, .navbar:after {
    content: " ";
    display: table; }
  .navbar:after {
    clear: both; }
  @media (min-width: 768px) {
    .navbar {
      border-radius: 4px; } }

.navbar-header:before, .navbar-header:after {
  content: " ";
  display: table; }

.navbar-header:after {
  clear: both; }

@media (min-width: 768px) {
  .navbar-header {
    float: left; } }

.navbar-collapse {
  overflow-x: visible;
  padding-right: 15px;
  padding-left: 15px;
  border-top: 1px solid transparent;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  -webkit-overflow-scrolling: touch; }
  .navbar-collapse:before, .navbar-collapse:after {
    content: " ";
    display: table; }
  .navbar-collapse:after {
    clear: both; }
  .navbar-collapse.in {
    overflow-y: auto; }
  @media (min-width: 768px) {
    .navbar-collapse {
      width: auto;
      border-top: 0;
      box-shadow: none; }
      .navbar-collapse.collapse {
        display: block !important;
        height: auto !important;
        padding-bottom: 0;
        overflow: visible !important; }
      .navbar-collapse.in {
        overflow-y: visible; }
      .navbar-fixed-top .navbar-collapse,
      .navbar-static-top .navbar-collapse,
      .navbar-fixed-bottom .navbar-collapse {
        padding-left: 0;
        padding-right: 0; } }

.navbar-fixed-top .navbar-collapse,
.navbar-fixed-bottom .navbar-collapse {
  max-height: 340px; }
  @media (max-device-width: 480px) and (orientation: landscape) {
    .navbar-fixed-top .navbar-collapse,
    .navbar-fixed-bottom .navbar-collapse {
      max-height: 200px; } }

.container > .navbar-header,
.container > .navbar-collapse,
.container-fluid > .navbar-header,
.container-fluid > .navbar-collapse {
  margin-right: -15px;
  margin-left: -15px; }
  @media (min-width: 768px) {
    .container > .navbar-header,
    .container > .navbar-collapse,
    .container-fluid > .navbar-header,
    .container-fluid > .navbar-collapse {
      margin-right: 0;
      margin-left: 0; } }

.navbar-static-top {
  z-index: 1000;
  border-width: 0 0 1px; }
  @media (min-width: 768px) {
    .navbar-static-top {
      border-radius: 0; } }

.navbar-fixed-top,
.navbar-fixed-bottom {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030; }
  @media (min-width: 768px) {
    .navbar-fixed-top,
    .navbar-fixed-bottom {
      border-radius: 0; } }

.navbar-fixed-top {
  top: 0;
  border-width: 0 0 1px; }

.navbar-fixed-bottom {
  bottom: 0;
  margin-bottom: 0;
  border-width: 1px 0 0; }

.navbar-brand {
  float: left;
  padding: 15px 15px;
  font-size: 18px;
  line-height: 20px;
  height: 50px; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }
  .navbar-brand > img {
    display: block; }
  @media (min-width: 768px) {
    .navbar > .container .navbar-brand,
    .navbar > .container-fluid .navbar-brand {
      margin-left: -15px; } }

.navbar-toggle {
  position: relative;
  float: right;
  margin-right: 15px;
  padding: 9px 10px;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px; }
  .navbar-toggle:focus {
    outline: 0; }
  .navbar-toggle .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px; }
  .navbar-toggle .icon-bar + .icon-bar {
    margin-top: 4px; }
  @media (min-width: 768px) {
    .navbar-toggle {
      display: none; } }

.navbar-nav {
  margin: 7.5px -15px; }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 20px; }
  @media (max-width: 767px) {
    .navbar-nav .open .dropdown-menu {
      position: static;
      float: none;
      width: auto;
      margin-top: 0;
      background-color: transparent;
      border: 0;
      box-shadow: none; }
      .navbar-nav .open .dropdown-menu > li > a,
      .navbar-nav .open .dropdown-menu .dropdown-header {
        padding: 5px 15px 5px 25px; }
      .navbar-nav .open .dropdown-menu > li > a {
        line-height: 20px; }
        .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-nav .open .dropdown-menu > li > a:focus {
          background-image: none; } }
  @media (min-width: 768px) {
    .navbar-nav {
      float: left;
      margin: 0; }
      .navbar-nav > li {
        float: left; }
        .navbar-nav > li > a {
          padding-top: 15px;
          padding-bottom: 15px; } }

.navbar-form {
  margin-left: -15px;
  margin-right: -15px;
  padding: 10px 15px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
  margin-top: 8px;
  margin-bottom: 8px; }
  @media (min-width: 768px) {
    .navbar-form .form-group {
      display: inline-block;
      margin-bottom: 0;
      vertical-align: middle; }
    .navbar-form .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .navbar-form .form-control-static {
      display: inline-block; }
    .navbar-form .input-group {
      display: inline-table;
      vertical-align: middle; }
      .navbar-form .input-group .input-group-addon,
      .navbar-form .input-group .input-group-btn,
      .navbar-form .input-group .form-control {
        width: auto; }
    .navbar-form .input-group > .form-control {
      width: 100%; }
    .navbar-form .control-label {
      margin-bottom: 0;
      vertical-align: middle; }
    .navbar-form .radio,
    .navbar-form .checkbox {
      display: inline-block;
      margin-top: 0;
      margin-bottom: 0;
      vertical-align: middle; }
      .navbar-form .radio label,
      .navbar-form .checkbox label {
        padding-left: 0; }
    .navbar-form .radio input[type="radio"],
    .navbar-form .checkbox input[type="checkbox"] {
      position: relative;
      margin-left: 0; }
    .navbar-form .has-feedback .form-control-feedback {
      top: 0; } }
  @media (max-width: 767px) {
    .navbar-form .form-group {
      margin-bottom: 5px; }
      .navbar-form .form-group:last-child {
        margin-bottom: 0; } }
  @media (min-width: 768px) {
    .navbar-form {
      width: auto;
      border: 0;
      margin-left: 0;
      margin-right: 0;
      padding-top: 0;
      padding-bottom: 0;
      box-shadow: none; } }

.navbar-nav > li > .dropdown-menu {
  margin-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0; }

.navbar-fixed-bottom .navbar-nav > li > .dropdown-menu {
  margin-bottom: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.navbar-btn {
  margin-top: 8px;
  margin-bottom: 8px; }
  .navbar-btn.btn-sm, .btn-group-sm > .navbar-btn.btn {
    margin-top: 10px;
    margin-bottom: 10px; }
  .navbar-btn.btn-xs, .btn-group-xs > .navbar-btn.btn {
    margin-top: 14px;
    margin-bottom: 14px; }

.navbar-text {
  margin-top: 15px;
  margin-bottom: 15px; }
  @media (min-width: 768px) {
    .navbar-text {
      float: left;
      margin-left: 15px;
      margin-right: 15px; } }

@media (min-width: 768px) {
  .navbar-left {
    float: left !important; }
  .navbar-right {
    float: right !important;
    margin-right: -15px; }
    .navbar-right ~ .navbar-right {
      margin-right: 0; } }

.navbar-default {
  background-color: #f8f8f8;
  border-color: #e7e7e7; }
  .navbar-default .navbar-brand {
    color: #777; }
    .navbar-default .navbar-brand:hover, .navbar-default .navbar-brand:focus {
      color: #5e5e5e;
      background-color: transparent; }
  .navbar-default .navbar-text {
    color: #777; }
  .navbar-default .navbar-nav > li > a {
    color: #777; }
    .navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
      color: #333;
      background-color: transparent; }
  .navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
    color: #555;
    background-color: #e7e7e7; }
  .navbar-default .navbar-nav > .disabled > a, .navbar-default .navbar-nav > .disabled > a:hover, .navbar-default .navbar-nav > .disabled > a:focus {
    color: #ccc;
    background-color: transparent; }
  .navbar-default .navbar-toggle {
    border-color: #ddd; }
    .navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
      background-color: #ddd; }
    .navbar-default .navbar-toggle .icon-bar {
      background-color: #888; }
  .navbar-default .navbar-collapse,
  .navbar-default .navbar-form {
    border-color: #e7e7e7; }
  .navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
    background-color: #e7e7e7;
    color: #555; }
  @media (max-width: 767px) {
    .navbar-default .navbar-nav .open .dropdown-menu > li > a {
      color: #777; }
      .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
        color: #333;
        background-color: transparent; }
    .navbar-default .navbar-nav .open .dropdown-menu > .active > a, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
      color: #555;
      background-color: #e7e7e7; }
    .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a, .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:focus {
      color: #ccc;
      background-color: transparent; } }
  .navbar-default .navbar-link {
    color: #777; }
    .navbar-default .navbar-link:hover {
      color: #333; }
  .navbar-default .btn-link {
    color: #777; }
    .navbar-default .btn-link:hover, .navbar-default .btn-link:focus {
      color: #333; }
    .navbar-default .btn-link[disabled]:hover, .navbar-default .btn-link[disabled]:focus,
    fieldset[disabled] .navbar-default .btn-link:hover,
    fieldset[disabled] .navbar-default .btn-link:focus {
      color: #ccc; }

.navbar-inverse {
  background-color: #222;
  border-color: #090909; }
  .navbar-inverse .navbar-brand {
    color: #9d9d9d; }
    .navbar-inverse .navbar-brand:hover, .navbar-inverse .navbar-brand:focus {
      color: #fff;
      background-color: transparent; }
  .navbar-inverse .navbar-text {
    color: #9d9d9d; }
  .navbar-inverse .navbar-nav > li > a {
    color: #9d9d9d; }
    .navbar-inverse .navbar-nav > li > a:hover, .navbar-inverse .navbar-nav > li > a:focus {
      color: #fff;
      background-color: transparent; }
  .navbar-inverse .navbar-nav > .active > a, .navbar-inverse .navbar-nav > .active > a:hover, .navbar-inverse .navbar-nav > .active > a:focus {
    color: #fff;
    background-color: #090909; }
  .navbar-inverse .navbar-nav > .disabled > a, .navbar-inverse .navbar-nav > .disabled > a:hover, .navbar-inverse .navbar-nav > .disabled > a:focus {
    color: #444;
    background-color: transparent; }
  .navbar-inverse .navbar-toggle {
    border-color: #333; }
    .navbar-inverse .navbar-toggle:hover, .navbar-inverse .navbar-toggle:focus {
      background-color: #333; }
    .navbar-inverse .navbar-toggle .icon-bar {
      background-color: #fff; }
  .navbar-inverse .navbar-collapse,
  .navbar-inverse .navbar-form {
    border-color: #101010; }
  .navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:hover, .navbar-inverse .navbar-nav > .open > a:focus {
    background-color: #090909;
    color: #fff; }
  @media (max-width: 767px) {
    .navbar-inverse .navbar-nav .open .dropdown-menu > .dropdown-header {
      border-color: #090909; }
    .navbar-inverse .navbar-nav .open .dropdown-menu .divider {
      background-color: #090909; }
    .navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
      color: #9d9d9d; }
      .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus {
        color: #fff;
        background-color: transparent; }
    .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a, .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:focus {
      color: #fff;
      background-color: #090909; }
    .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a, .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:focus {
      color: #444;
      background-color: transparent; } }
  .navbar-inverse .navbar-link {
    color: #9d9d9d; }
    .navbar-inverse .navbar-link:hover {
      color: #fff; }
  .navbar-inverse .btn-link {
    color: #9d9d9d; }
    .navbar-inverse .btn-link:hover, .navbar-inverse .btn-link:focus {
      color: #fff; }
    .navbar-inverse .btn-link[disabled]:hover, .navbar-inverse .btn-link[disabled]:focus,
    fieldset[disabled] .navbar-inverse .btn-link:hover,
    fieldset[disabled] .navbar-inverse .btn-link:focus {
      color: #444; }

.breadcrumb {
  padding: 8px 15px;
  margin-bottom: 20px;
  list-style: none;
  background-color: #f5f5f5;
  border-radius: 4px; }
  .breadcrumb > li {
    display: inline-block; }
    .breadcrumb > li + li:before {
      content: "/ ";
      padding: 0 5px;
      color: #ccc; }
  .breadcrumb > .active {
    color: #777777; }

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px; }
  .pagination > li {
    display: inline; }
    .pagination > li > a,
    .pagination > li > span {
      position: relative;
      float: left;
      padding: 6px 12px;
      line-height: 1.42857;
      text-decoration: none;
      color: #337ab7;
      background-color: #fff;
      border: 1px solid #ddd;
      margin-left: -1px; }
    .pagination > li:first-child > a,
    .pagination > li:first-child > span {
      margin-left: 0;
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px; }
    .pagination > li:last-child > a,
    .pagination > li:last-child > span {
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px; }
  .pagination > li > a:hover, .pagination > li > a:focus,
  .pagination > li > span:hover,
  .pagination > li > span:focus {
    z-index: 2;
    color: #23527c;
    background-color: #eeeeee;
    border-color: #ddd; }
  .pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus,
  .pagination > .active > span,
  .pagination > .active > span:hover,
  .pagination > .active > span:focus {
    z-index: 3;
    color: #fff;
    background-color: #337ab7;
    border-color: #337ab7;
    cursor: default; }
  .pagination > .disabled > span,
  .pagination > .disabled > span:hover,
  .pagination > .disabled > span:focus,
  .pagination > .disabled > a,
  .pagination > .disabled > a:hover,
  .pagination > .disabled > a:focus {
    color: #777777;
    background-color: #fff;
    border-color: #ddd;
    cursor: not-allowed; }

.pagination-lg > li > a,
.pagination-lg > li > span {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333; }

.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px; }

.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px; }

.pagination-sm > li > a,
.pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5; }

.pagination-sm > li:first-child > a,
.pagination-sm > li:first-child > span {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px; }

.pagination-sm > li:last-child > a,
.pagination-sm > li:last-child > span {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px; }

.pager {
  padding-left: 0;
  margin: 20px 0;
  list-style: none;
  text-align: center; }
  .pager:before, .pager:after {
    content: " ";
    display: table; }
  .pager:after {
    clear: both; }
  .pager li {
    display: inline; }
    .pager li > a,
    .pager li > span {
      display: inline-block;
      padding: 5px 14px;
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 15px; }
    .pager li > a:hover,
    .pager li > a:focus {
      text-decoration: none;
      background-color: #eeeeee; }
  .pager .next > a,
  .pager .next > span {
    float: right; }
  .pager .previous > a,
  .pager .previous > span {
    float: left; }
  .pager .disabled > a,
  .pager .disabled > a:hover,
  .pager .disabled > a:focus,
  .pager .disabled > span {
    color: #777777;
    background-color: #fff;
    cursor: not-allowed; }

.label {
  display: inline;
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em; }
  .label:empty {
    display: none; }
  .btn .label {
    position: relative;
    top: -1px; }

a.label:hover, a.label:focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer; }

.label-default {
  background-color: #777777; }
  .label-default[href]:hover, .label-default[href]:focus {
    background-color: #5e5e5e; }

.label-primary {
  background-color: #337ab7; }
  .label-primary[href]:hover, .label-primary[href]:focus {
    background-color: #286090; }

.label-success {
  background-color: #5cb85c; }
  .label-success[href]:hover, .label-success[href]:focus {
    background-color: #449d44; }

.label-info {
  background-color: #5bc0de; }
  .label-info[href]:hover, .label-info[href]:focus {
    background-color: #31b0d5; }

.label-warning {
  background-color: #f0ad4e; }
  .label-warning[href]:hover, .label-warning[href]:focus {
    background-color: #ec971f; }

.label-danger {
  background-color: #d9534f; }
  .label-danger[href]:hover, .label-danger[href]:focus {
    background-color: #c9302c; }

.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  line-height: 1;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  background-color: #777777;
  border-radius: 10px; }
  .badge:empty {
    display: none; }
  .btn .badge {
    position: relative;
    top: -1px; }
  .btn-xs .badge, .btn-group-xs > .btn .badge,
  .btn-group-xs > .btn .badge {
    top: 0;
    padding: 1px 5px; }
  .list-group-item.active > .badge,
  .nav-pills > .active > a > .badge {
    color: #337ab7;
    background-color: #fff; }
  .list-group-item > .badge {
    float: right; }
  .list-group-item > .badge + .badge {
    margin-right: 5px; }
  .nav-pills > li > a > .badge {
    margin-left: 3px; }

a.badge:hover, a.badge:focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer; }

.jumbotron {
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  color: inherit;
  background-color: #eeeeee; }
  .jumbotron h1,
  .jumbotron .h1 {
    color: inherit; }
  .jumbotron p {
    margin-bottom: 15px;
    font-size: 21px;
    font-weight: 200; }
  .jumbotron > hr {
    border-top-color: #d5d5d5; }
  .container .jumbotron,
  .container-fluid .jumbotron {
    border-radius: 6px;
    padding-left: 15px;
    padding-right: 15px; }
  .jumbotron .container {
    max-width: 100%; }
  @media screen and (min-width: 768px) {
    .jumbotron {
      padding-top: 48px;
      padding-bottom: 48px; }
      .container .jumbotron,
      .container-fluid .jumbotron {
        padding-left: 60px;
        padding-right: 60px; }
      .jumbotron h1,
      .jumbotron .h1 {
        font-size: 63px; } }

.thumbnail {
  display: block;
  padding: 4px;
  margin-bottom: 20px;
  line-height: 1.42857;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border 0.2s ease-in-out; }
  .thumbnail > img,
  .thumbnail a > img {
    display: block;
    max-width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto; }
  .thumbnail .caption {
    padding: 9px;
    color: #333333; }

a.thumbnail:hover,
a.thumbnail:focus,
a.thumbnail.active {
  border-color: #337ab7; }

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px; }
  .alert h4 {
    margin-top: 0;
    color: inherit; }
  .alert .alert-link {
    font-weight: bold; }
  .alert > p,
  .alert > ul {
    margin-bottom: 0; }
  .alert > p + p {
    margin-top: 5px; }

.alert-dismissable,
.alert-dismissible {
  padding-right: 35px; }
  .alert-dismissable .close,
  .alert-dismissible .close {
    position: relative;
    top: -2px;
    right: -21px;
    color: inherit; }

.alert-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #3c763d; }
  .alert-success hr {
    border-top-color: #c9e2b3; }
  .alert-success .alert-link {
    color: #2b542c; }

.alert-info {
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #31708f; }
  .alert-info hr {
    border-top-color: #a6e1ec; }
  .alert-info .alert-link {
    color: #245269; }

.alert-warning {
  background-color: #fcf8e3;
  border-color: #faebcc;
  color: #8a6d3b; }
  .alert-warning hr {
    border-top-color: #f7e1b5; }
  .alert-warning .alert-link {
    color: #66512c; }

.alert-danger {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442; }
  .alert-danger hr {
    border-top-color: #e4b9c0; }
  .alert-danger .alert-link {
    color: #843534; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0; }
  to {
    background-position: 0 0; } }

.progress {
  overflow: hidden;
  height: 20px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border-radius: 4px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1); }

.progress-bar {
  float: left;
  width: 0%;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #337ab7;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  transition: width 0.6s ease; }

.progress-striped .progress-bar,
.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 40px 40px; }

.progress.active .progress-bar,
.progress-bar.active {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite; }

.progress-bar-success {
  background-color: #5cb85c; }
  .progress-striped .progress-bar-success {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent); }

.progress-bar-info {
  background-color: #5bc0de; }
  .progress-striped .progress-bar-info {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent); }

.progress-bar-warning {
  background-color: #f0ad4e; }
  .progress-striped .progress-bar-warning {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent); }

.progress-bar-danger {
  background-color: #d9534f; }
  .progress-striped .progress-bar-danger {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent); }

.media {
  margin-top: 15px; }
  .media:first-child {
    margin-top: 0; }

.media,
.media-body {
  zoom: 1;
  overflow: hidden; }

.media-body {
  width: 10000px; }

.media-object {
  display: block; }
  .media-object.img-thumbnail {
    max-width: none; }

.media-right,
.media > .pull-right {
  padding-left: 10px; }

.media-left,
.media > .pull-left {
  padding-right: 10px; }

.media-left,
.media-right,
.media-body {
  display: table-cell;
  vertical-align: top; }

.media-middle {
  vertical-align: middle; }

.media-bottom {
  vertical-align: bottom; }

.media-heading {
  margin-top: 0;
  margin-bottom: 5px; }

.media-list {
  padding-left: 0;
  list-style: none; }

.list-group {
  margin-bottom: 20px;
  padding-left: 0; }

.list-group-item {
  position: relative;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid #ddd; }
  .list-group-item:first-child {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px; }

a.list-group-item,
button.list-group-item {
  color: #555; }
  a.list-group-item .list-group-item-heading,
  button.list-group-item .list-group-item-heading {
    color: #333; }
  a.list-group-item:hover, a.list-group-item:focus,
  button.list-group-item:hover,
  button.list-group-item:focus {
    text-decoration: none;
    color: #555;
    background-color: #f5f5f5; }

button.list-group-item {
  width: 100%;
  text-align: left; }

.list-group-item.disabled, .list-group-item.disabled:hover, .list-group-item.disabled:focus {
  background-color: #eeeeee;
  color: #777777;
  cursor: not-allowed; }
  .list-group-item.disabled .list-group-item-heading, .list-group-item.disabled:hover .list-group-item-heading, .list-group-item.disabled:focus .list-group-item-heading {
    color: inherit; }
  .list-group-item.disabled .list-group-item-text, .list-group-item.disabled:hover .list-group-item-text, .list-group-item.disabled:focus .list-group-item-text {
    color: #777777; }

.list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
  z-index: 2;
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7; }
  .list-group-item.active .list-group-item-heading,
  .list-group-item.active .list-group-item-heading > small,
  .list-group-item.active .list-group-item-heading > .small, .list-group-item.active:hover .list-group-item-heading,
  .list-group-item.active:hover .list-group-item-heading > small,
  .list-group-item.active:hover .list-group-item-heading > .small, .list-group-item.active:focus .list-group-item-heading,
  .list-group-item.active:focus .list-group-item-heading > small,
  .list-group-item.active:focus .list-group-item-heading > .small {
    color: inherit; }
  .list-group-item.active .list-group-item-text, .list-group-item.active:hover .list-group-item-text, .list-group-item.active:focus .list-group-item-text {
    color: #c7ddef; }

.list-group-item-success {
  color: #3c763d;
  background-color: #dff0d8; }

a.list-group-item-success,
button.list-group-item-success {
  color: #3c763d; }
  a.list-group-item-success .list-group-item-heading,
  button.list-group-item-success .list-group-item-heading {
    color: inherit; }
  a.list-group-item-success:hover, a.list-group-item-success:focus,
  button.list-group-item-success:hover,
  button.list-group-item-success:focus {
    color: #3c763d;
    background-color: #d0e9c6; }
  a.list-group-item-success.active, a.list-group-item-success.active:hover, a.list-group-item-success.active:focus,
  button.list-group-item-success.active,
  button.list-group-item-success.active:hover,
  button.list-group-item-success.active:focus {
    color: #fff;
    background-color: #3c763d;
    border-color: #3c763d; }

.list-group-item-info {
  color: #31708f;
  background-color: #d9edf7; }

a.list-group-item-info,
button.list-group-item-info {
  color: #31708f; }
  a.list-group-item-info .list-group-item-heading,
  button.list-group-item-info .list-group-item-heading {
    color: inherit; }
  a.list-group-item-info:hover, a.list-group-item-info:focus,
  button.list-group-item-info:hover,
  button.list-group-item-info:focus {
    color: #31708f;
    background-color: #c4e3f3; }
  a.list-group-item-info.active, a.list-group-item-info.active:hover, a.list-group-item-info.active:focus,
  button.list-group-item-info.active,
  button.list-group-item-info.active:hover,
  button.list-group-item-info.active:focus {
    color: #fff;
    background-color: #31708f;
    border-color: #31708f; }

.list-group-item-warning {
  color: #8a6d3b;
  background-color: #fcf8e3; }

a.list-group-item-warning,
button.list-group-item-warning {
  color: #8a6d3b; }
  a.list-group-item-warning .list-group-item-heading,
  button.list-group-item-warning .list-group-item-heading {
    color: inherit; }
  a.list-group-item-warning:hover, a.list-group-item-warning:focus,
  button.list-group-item-warning:hover,
  button.list-group-item-warning:focus {
    color: #8a6d3b;
    background-color: #faf2cc; }
  a.list-group-item-warning.active, a.list-group-item-warning.active:hover, a.list-group-item-warning.active:focus,
  button.list-group-item-warning.active,
  button.list-group-item-warning.active:hover,
  button.list-group-item-warning.active:focus {
    color: #fff;
    background-color: #8a6d3b;
    border-color: #8a6d3b; }

.list-group-item-danger {
  color: #a94442;
  background-color: #f2dede; }

a.list-group-item-danger,
button.list-group-item-danger {
  color: #a94442; }
  a.list-group-item-danger .list-group-item-heading,
  button.list-group-item-danger .list-group-item-heading {
    color: inherit; }
  a.list-group-item-danger:hover, a.list-group-item-danger:focus,
  button.list-group-item-danger:hover,
  button.list-group-item-danger:focus {
    color: #a94442;
    background-color: #ebcccc; }
  a.list-group-item-danger.active, a.list-group-item-danger.active:hover, a.list-group-item-danger.active:focus,
  button.list-group-item-danger.active,
  button.list-group-item-danger.active:hover,
  button.list-group-item-danger.active:focus {
    color: #fff;
    background-color: #a94442;
    border-color: #a94442; }

.list-group-item-heading {
  margin-top: 0;
  margin-bottom: 5px; }

.list-group-item-text {
  margin-bottom: 0;
  line-height: 1.3; }

.panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); }

.panel-body {
  padding: 15px; }
  .panel-body:before, .panel-body:after {
    content: " ";
    display: table; }
  .panel-body:after {
    clear: both; }

.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px; }
  .panel-heading > .dropdown .dropdown-toggle {
    color: inherit; }

.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  color: inherit; }
  .panel-title > a,
  .panel-title > small,
  .panel-title > .small,
  .panel-title > small > a,
  .panel-title > .small > a {
    color: inherit; }

.panel-footer {
  padding: 10px 15px;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px; }

.panel > .list-group,
.panel > .panel-collapse > .list-group {
  margin-bottom: 0; }
  .panel > .list-group .list-group-item,
  .panel > .panel-collapse > .list-group .list-group-item {
    border-width: 1px 0;
    border-radius: 0; }
  .panel > .list-group:first-child .list-group-item:first-child,
  .panel > .panel-collapse > .list-group:first-child .list-group-item:first-child {
    border-top: 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px; }
  .panel > .list-group:last-child .list-group-item:last-child,
  .panel > .panel-collapse > .list-group:last-child .list-group-item:last-child {
    border-bottom: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px; }

.panel > .panel-heading + .panel-collapse > .list-group .list-group-item:first-child {
  border-top-right-radius: 0;
  border-top-left-radius: 0; }

.panel-heading + .list-group .list-group-item:first-child {
  border-top-width: 0; }

.list-group + .panel-footer {
  border-top-width: 0; }

.panel > .table,
.panel > .table-responsive > .table,
.panel > .panel-collapse > .table {
  margin-bottom: 0; }
  .panel > .table caption,
  .panel > .table-responsive > .table caption,
  .panel > .panel-collapse > .table caption {
    padding-left: 15px;
    padding-right: 15px; }

.panel > .table:first-child,
.panel > .table-responsive:first-child > .table:first-child {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px; }
  .panel > .table:first-child > thead:first-child > tr:first-child,
  .panel > .table:first-child > tbody:first-child > tr:first-child,
  .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child,
  .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px; }
    .panel > .table:first-child > thead:first-child > tr:first-child td:first-child,
    .panel > .table:first-child > thead:first-child > tr:first-child th:first-child,
    .panel > .table:first-child > tbody:first-child > tr:first-child td:first-child,
    .panel > .table:first-child > tbody:first-child > tr:first-child th:first-child,
    .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:first-child,
    .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:first-child,
    .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:first-child,
    .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:first-child {
      border-top-left-radius: 3px; }
    .panel > .table:first-child > thead:first-child > tr:first-child td:last-child,
    .panel > .table:first-child > thead:first-child > tr:first-child th:last-child,
    .panel > .table:first-child > tbody:first-child > tr:first-child td:last-child,
    .panel > .table:first-child > tbody:first-child > tr:first-child th:last-child,
    .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:last-child,
    .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:last-child,
    .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:last-child,
    .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:last-child {
      border-top-right-radius: 3px; }

.panel > .table:last-child,
.panel > .table-responsive:last-child > .table:last-child {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px; }
  .panel > .table:last-child > tbody:last-child > tr:last-child,
  .panel > .table:last-child > tfoot:last-child > tr:last-child,
  .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child,
  .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px; }
    .panel > .table:last-child > tbody:last-child > tr:last-child td:first-child,
    .panel > .table:last-child > tbody:last-child > tr:last-child th:first-child,
    .panel > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
    .panel > .table:last-child > tfoot:last-child > tr:last-child th:first-child,
    .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:first-child,
    .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:first-child,
    .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
    .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:first-child {
      border-bottom-left-radius: 3px; }
    .panel > .table:last-child > tbody:last-child > tr:last-child td:last-child,
    .panel > .table:last-child > tbody:last-child > tr:last-child th:last-child,
    .panel > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
    .panel > .table:last-child > tfoot:last-child > tr:last-child th:last-child,
    .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:last-child,
    .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:last-child,
    .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
    .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:last-child {
      border-bottom-right-radius: 3px; }

.panel > .panel-body + .table,
.panel > .panel-body + .table-responsive,
.panel > .table + .panel-body,
.panel > .table-responsive + .panel-body {
  border-top: 1px solid #ddd; }

.panel > .table > tbody:first-child > tr:first-child th,
.panel > .table > tbody:first-child > tr:first-child td {
  border-top: 0; }

.panel > .table-bordered,
.panel > .table-responsive > .table-bordered {
  border: 0; }
  .panel > .table-bordered > thead > tr > th:first-child,
  .panel > .table-bordered > thead > tr > td:first-child,
  .panel > .table-bordered > tbody > tr > th:first-child,
  .panel > .table-bordered > tbody > tr > td:first-child,
  .panel > .table-bordered > tfoot > tr > th:first-child,
  .panel > .table-bordered > tfoot > tr > td:first-child,
  .panel > .table-responsive > .table-bordered > thead > tr > th:first-child,
  .panel > .table-responsive > .table-bordered > thead > tr > td:first-child,
  .panel > .table-responsive > .table-bordered > tbody > tr > th:first-child,
  .panel > .table-responsive > .table-bordered > tbody > tr > td:first-child,
  .panel > .table-responsive > .table-bordered > tfoot > tr > th:first-child,
  .panel > .table-responsive > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0; }
  .panel > .table-bordered > thead > tr > th:last-child,
  .panel > .table-bordered > thead > tr > td:last-child,
  .panel > .table-bordered > tbody > tr > th:last-child,
  .panel > .table-bordered > tbody > tr > td:last-child,
  .panel > .table-bordered > tfoot > tr > th:last-child,
  .panel > .table-bordered > tfoot > tr > td:last-child,
  .panel > .table-responsive > .table-bordered > thead > tr > th:last-child,
  .panel > .table-responsive > .table-bordered > thead > tr > td:last-child,
  .panel > .table-responsive > .table-bordered > tbody > tr > th:last-child,
  .panel > .table-responsive > .table-bordered > tbody > tr > td:last-child,
  .panel > .table-responsive > .table-bordered > tfoot > tr > th:last-child,
  .panel > .table-responsive > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0; }
  .panel > .table-bordered > thead > tr:first-child > td,
  .panel > .table-bordered > thead > tr:first-child > th,
  .panel > .table-bordered > tbody > tr:first-child > td,
  .panel > .table-bordered > tbody > tr:first-child > th,
  .panel > .table-responsive > .table-bordered > thead > tr:first-child > td,
  .panel > .table-responsive > .table-bordered > thead > tr:first-child > th,
  .panel > .table-responsive > .table-bordered > tbody > tr:first-child > td,
  .panel > .table-responsive > .table-bordered > tbody > tr:first-child > th {
    border-bottom: 0; }
  .panel > .table-bordered > tbody > tr:last-child > td,
  .panel > .table-bordered > tbody > tr:last-child > th,
  .panel > .table-bordered > tfoot > tr:last-child > td,
  .panel > .table-bordered > tfoot > tr:last-child > th,
  .panel > .table-responsive > .table-bordered > tbody > tr:last-child > td,
  .panel > .table-responsive > .table-bordered > tbody > tr:last-child > th,
  .panel > .table-responsive > .table-bordered > tfoot > tr:last-child > td,
  .panel > .table-responsive > .table-bordered > tfoot > tr:last-child > th {
    border-bottom: 0; }

.panel > .table-responsive {
  border: 0;
  margin-bottom: 0; }

.panel-group {
  margin-bottom: 20px; }
  .panel-group .panel {
    margin-bottom: 0;
    border-radius: 4px; }
    .panel-group .panel + .panel {
      margin-top: 5px; }
  .panel-group .panel-heading {
    border-bottom: 0; }
    .panel-group .panel-heading + .panel-collapse > .panel-body,
    .panel-group .panel-heading + .panel-collapse > .list-group {
      border-top: 1px solid #ddd; }
  .panel-group .panel-footer {
    border-top: 0; }
    .panel-group .panel-footer + .panel-collapse .panel-body {
      border-bottom: 1px solid #ddd; }

.panel-default {
  border-color: #ddd; }
  .panel-default > .panel-heading {
    color: #333333;
    background-color: #f5f5f5;
    border-color: #ddd; }
    .panel-default > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #ddd; }
    .panel-default > .panel-heading .badge {
      color: #f5f5f5;
      background-color: #333333; }
  .panel-default > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #ddd; }

.panel-primary {
  border-color: #337ab7; }
  .panel-primary > .panel-heading {
    color: #fff;
    background-color: #337ab7;
    border-color: #337ab7; }
    .panel-primary > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #337ab7; }
    .panel-primary > .panel-heading .badge {
      color: #337ab7;
      background-color: #fff; }
  .panel-primary > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #337ab7; }

.panel-success {
  border-color: #d6e9c6; }
  .panel-success > .panel-heading {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6; }
    .panel-success > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #d6e9c6; }
    .panel-success > .panel-heading .badge {
      color: #dff0d8;
      background-color: #3c763d; }
  .panel-success > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #d6e9c6; }

.panel-info {
  border-color: #bce8f1; }
  .panel-info > .panel-heading {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1; }
    .panel-info > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #bce8f1; }
    .panel-info > .panel-heading .badge {
      color: #d9edf7;
      background-color: #31708f; }
  .panel-info > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #bce8f1; }

.panel-warning {
  border-color: #faebcc; }
  .panel-warning > .panel-heading {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc; }
    .panel-warning > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #faebcc; }
    .panel-warning > .panel-heading .badge {
      color: #fcf8e3;
      background-color: #8a6d3b; }
  .panel-warning > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #faebcc; }

.panel-danger {
  border-color: #ebccd1; }
  .panel-danger > .panel-heading {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1; }
    .panel-danger > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #ebccd1; }
    .panel-danger > .panel-heading .badge {
      color: #f2dede;
      background-color: #a94442; }
  .panel-danger > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #ebccd1; }

.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0; }

.embed-responsive-16by9 {
  padding-bottom: 56.25%; }

.embed-responsive-4by3 {
  padding-bottom: 75%; }

.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05); }
  .well blockquote {
    border-color: #ddd;
    border-color: rgba(0, 0, 0, 0.15); }

.well-lg {
  padding: 24px;
  border-radius: 6px; }

.well-sm {
  padding: 9px;
  border-radius: 3px; }

.close {
  float: right;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.2;
  filter: alpha(opacity=20); }
  .close:hover, .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.5;
    filter: alpha(opacity=50); }

button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }

.modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0; }
  .modal.fade .modal-dialog {
    -webkit-transform: translate(0, -25%);
    -ms-transform: translate(0, -25%);
    transform: translate(0, -25%);
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out; }
  .modal.in .modal-dialog {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0); }

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px; }

.modal-content {
  position: relative;
  background-color: #fff;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  background-clip: padding-box;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0;
    filter: alpha(opacity=0); }
  .modal-backdrop.in {
    opacity: 0.5;
    filter: alpha(opacity=50); }

.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5; }
  .modal-header:before, .modal-header:after {
    content: " ";
    display: table; }
  .modal-header:after {
    clear: both; }

.modal-header .close {
  margin-top: -2px; }

.modal-title {
  margin: 0;
  line-height: 1.42857; }

.modal-body {
  position: relative;
  padding: 15px; }

.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5; }
  .modal-footer:before, .modal-footer:after {
    content: " ";
    display: table; }
  .modal-footer:after {
    clear: both; }
  .modal-footer .btn + .btn {
    margin-left: 5px;
    margin-bottom: 0; }
  .modal-footer .btn-group .btn + .btn {
    margin-left: -1px; }
  .modal-footer .btn-block + .btn-block {
    margin-left: 0; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto; }
  .modal-content {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5); }
  .modal-sm {
    width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    width: 900px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.42857;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  font-size: 12px;
  opacity: 0;
  filter: alpha(opacity=0); }
  .tooltip.in {
    opacity: 0.9;
    filter: alpha(opacity=90); }
  .tooltip.top {
    margin-top: -3px;
    padding: 5px 0; }
  .tooltip.right {
    margin-left: 3px;
    padding: 0 5px; }
  .tooltip.bottom {
    margin-top: 3px;
    padding: 5px 0; }
  .tooltip.left {
    margin-left: -3px;
    padding: 0 5px; }

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px; }

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid; }

.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000; }

.tooltip.top-left .tooltip-arrow {
  bottom: 0;
  right: 5px;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000; }

.tooltip.top-right .tooltip-arrow {
  bottom: 0;
  left: 5px;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000; }

.tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000; }

.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #000; }

.tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000; }

.tooltip.bottom-left .tooltip-arrow {
  top: 0;
  right: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000; }

.tooltip.bottom-right .tooltip-arrow {
  top: 0;
  left: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  max-width: 276px;
  padding: 1px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.42857;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  font-size: 14px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2); }
  .popover.top {
    margin-top: -10px; }
  .popover.right {
    margin-left: 10px; }
  .popover.bottom {
    margin-top: 10px; }
  .popover.left {
    margin-left: -10px; }

.popover-title {
  margin: 0;
  padding: 8px 14px;
  font-size: 14px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0; }

.popover-content {
  padding: 9px 14px; }

.popover > .arrow, .popover > .arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid; }

.popover > .arrow {
  border-width: 11px; }

.popover > .arrow:after {
  border-width: 10px;
  content: ""; }

.popover.top > .arrow {
  left: 50%;
  margin-left: -11px;
  border-bottom-width: 0;
  border-top-color: #999999;
  border-top-color: rgba(0, 0, 0, 0.25);
  bottom: -11px; }
  .popover.top > .arrow:after {
    content: " ";
    bottom: 1px;
    margin-left: -10px;
    border-bottom-width: 0;
    border-top-color: #fff; }

.popover.right > .arrow {
  top: 50%;
  left: -11px;
  margin-top: -11px;
  border-left-width: 0;
  border-right-color: #999999;
  border-right-color: rgba(0, 0, 0, 0.25); }
  .popover.right > .arrow:after {
    content: " ";
    left: 1px;
    bottom: -10px;
    border-left-width: 0;
    border-right-color: #fff; }

.popover.bottom > .arrow {
  left: 50%;
  margin-left: -11px;
  border-top-width: 0;
  border-bottom-color: #999999;
  border-bottom-color: rgba(0, 0, 0, 0.25);
  top: -11px; }
  .popover.bottom > .arrow:after {
    content: " ";
    top: 1px;
    margin-left: -10px;
    border-top-width: 0;
    border-bottom-color: #fff; }

.popover.left > .arrow {
  top: 50%;
  right: -11px;
  margin-top: -11px;
  border-right-width: 0;
  border-left-color: #999999;
  border-left-color: rgba(0, 0, 0, 0.25); }
  .popover.left > .arrow:after {
    content: " ";
    right: 1px;
    border-right-width: 0;
    border-left-color: #fff;
    bottom: -10px; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  overflow: hidden;
  width: 100%; }
  .carousel-inner > .item {
    display: none;
    position: relative;
    transition: 0.6s ease-in-out left; }
    .carousel-inner > .item > img,
    .carousel-inner > .item > a > img {
      display: block;
      max-width: 100%;
      height: auto;
      line-height: 1; }
    @media all and (transform-3d), (-webkit-transform-3d) {
      .carousel-inner > .item {
        transition: -webkit-transform 0.6s ease-in-out;
        transition: transform 0.6s ease-in-out;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-perspective: 1000px;
        perspective: 1000px; }
        .carousel-inner > .item.next, .carousel-inner > .item.active.right {
          -webkit-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0);
          left: 0; }
        .carousel-inner > .item.prev, .carousel-inner > .item.active.left {
          -webkit-transform: translate3d(-100%, 0, 0);
          transform: translate3d(-100%, 0, 0);
          left: 0; }
        .carousel-inner > .item.next.left, .carousel-inner > .item.prev.right, .carousel-inner > .item.active {
          -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
          left: 0; } }
  .carousel-inner > .active,
  .carousel-inner > .next,
  .carousel-inner > .prev {
    display: block; }
  .carousel-inner > .active {
    left: 0; }
  .carousel-inner > .next,
  .carousel-inner > .prev {
    position: absolute;
    top: 0;
    width: 100%; }
  .carousel-inner > .next {
    left: 100%; }
  .carousel-inner > .prev {
    left: -100%; }
  .carousel-inner > .next.left,
  .carousel-inner > .prev.right {
    left: 0; }
  .carousel-inner > .active.left {
    left: -100%; }
  .carousel-inner > .active.right {
    left: 100%; }

.carousel-control {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 15%;
  opacity: 0.5;
  filter: alpha(opacity=50);
  font-size: 20px;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  background-color: transparent; }
  .carousel-control.left {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1); }
  .carousel-control.right {
    left: auto;
    right: 0;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1); }
  .carousel-control:hover, .carousel-control:focus {
    outline: 0;
    color: #fff;
    text-decoration: none;
    opacity: 0.9;
    filter: alpha(opacity=90); }
  .carousel-control .icon-prev,
  .carousel-control .icon-next,
  .carousel-control .glyphicon-chevron-left,
  .carousel-control .glyphicon-chevron-right {
    position: absolute;
    top: 50%;
    margin-top: -10px;
    z-index: 5;
    display: inline-block; }
  .carousel-control .icon-prev,
  .carousel-control .glyphicon-chevron-left {
    left: 50%;
    margin-left: -10px; }
  .carousel-control .icon-next,
  .carousel-control .glyphicon-chevron-right {
    right: 50%;
    margin-right: -10px; }
  .carousel-control .icon-prev,
  .carousel-control .icon-next {
    width: 20px;
    height: 20px;
    line-height: 1;
    font-family: serif; }
  .carousel-control .icon-prev:before {
    content: '\2039'; }
  .carousel-control .icon-next:before {
    content: '\203a'; }

.carousel-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  z-index: 15;
  width: 60%;
  margin-left: -30%;
  padding-left: 0;
  list-style: none;
  text-align: center; }
  .carousel-indicators li {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 1px;
    text-indent: -999px;
    border: 1px solid #fff;
    border-radius: 10px;
    cursor: pointer;
    background-color: #000 \9;
    background-color: transparent; }
  .carousel-indicators .active {
    margin: 0;
    width: 12px;
    height: 12px;
    background-color: #fff; }

.carousel-caption {
  position: absolute;
  left: 15%;
  right: 15%;
  bottom: 20px;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6); }
  .carousel-caption .btn {
    text-shadow: none; }

@media screen and (min-width: 768px) {
  .carousel-control .glyphicon-chevron-left,
  .carousel-control .glyphicon-chevron-right,
  .carousel-control .icon-prev,
  .carousel-control .icon-next {
    width: 30px;
    height: 30px;
    margin-top: -10px;
    font-size: 30px; }
  .carousel-control .glyphicon-chevron-left,
  .carousel-control .icon-prev {
    margin-left: -10px; }
  .carousel-control .glyphicon-chevron-right,
  .carousel-control .icon-next {
    margin-right: -10px; }
  .carousel-caption {
    left: 20%;
    right: 20%;
    padding-bottom: 30px; }
  .carousel-indicators {
    bottom: 20px; } }

.clearfix:before, .clearfix:after {
  content: " ";
  display: table; }

.clearfix:after {
  clear: both; }

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.pull-right {
  float: right !important; }

.pull-left {
  float: left !important; }

.hide {
  display: none !important; }

.show {
  display: block !important; }

.invisible {
  visibility: hidden; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.hidden {
  display: none !important; }

.affix {
  position: fixed; }

@-ms-viewport {
  width: device-width; }

.visible-xs {
  display: none !important; }

.visible-sm {
  display: none !important; }

.visible-md {
  display: none !important; }

.visible-lg {
  display: none !important; }

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important; }

@media (max-width: 767px) {
  .visible-xs {
    display: block !important; }
  table.visible-xs {
    display: table !important; }
  tr.visible-xs {
    display: table-row !important; }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important; } }

@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important; } }

@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important; } }

@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important; }
  table.visible-sm {
    display: table !important; }
  tr.visible-sm {
    display: table-row !important; }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important; }
  table.visible-md {
    display: table !important; }
  tr.visible-md {
    display: table-row !important; }
  th.visible-md,
  td.visible-md {
    display: table-cell !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important; } }

@media (min-width: 1200px) {
  .visible-lg {
    display: block !important; }
  table.visible-lg {
    display: table !important; }
  tr.visible-lg {
    display: table-row !important; }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important; } }

@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important; } }

@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important; } }

@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important; } }

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important; } }

.visible-print {
  display: none !important; }

@media print {
  .visible-print {
    display: block !important; }
  table.visible-print {
    display: table !important; }
  tr.visible-print {
    display: table-row !important; }
  th.visible-print,
  td.visible-print {
    display: table-cell !important; } }

.visible-print-block {
  display: none !important; }
  @media print {
    .visible-print-block {
      display: block !important; } }

.visible-print-inline {
  display: none !important; }
  @media print {
    .visible-print-inline {
      display: inline !important; } }

.visible-print-inline-block {
  display: none !important; }
  @media print {
    .visible-print-inline-block {
      display: inline-block !important; } }

@media print {
  .hidden-print {
    display: none !important; } }

.btn-default:hover:not([disabled]) {
  color: #333;
  background-color: #e6e6e6; }

.btn-primary:hover:not([disabled]) {
  color: #fff;
  background-color: #286090; }

.btn-info:hover:not([disabled]) {
  color: #fff;
  background-color: #31b0d5; }

.btn-warning:hover:not([disabled]) {
  color: #fff;
  background-color: #ec971f; }

.btn-danger:hover:not([disabled]) {
  color: #fff;
  background-color: #c9302c; }

.btn-success:hover:not([disabled]) {
  color: #fff;
  background-color: #449d44; }

.btn-link:hover:not([disabled]) {
  color: #337ab7;
  background-color: transparent; }

html {
  font-size: 16px; }
