////
/// @group wk-css.components.forms
/// @author [GPO](https://gpo.wolterskluwer.com/)
/// @since 0.5.0
/// @access public
////

@import 'forms.variables';

@include wk-exports('forms') {

    %scss-deps-forms {
        components: wk-component-dependencies(inputs);
        utils: wk-utils-dependencies(
            function wk-primary-color,
            mixin container,
            mixin wk-clearfix,
            mixin wk-target-icons,
            mixin wk-transition,
            variable wk-font-stacks,
            variable wk-font-scale,
            variable wk-font-weight,
            variable wk-mobile-max-breakpoint,
            variable wk-target-tablet-desktop
        );
    }

    label {
        font-weight: map-get($wk-font-weight, regular);
    }

    .wk-field-item {
        font-family: map-get($wk-font-stacks, 'global-sans');
        margin-bottom: wk-rem(16px);

        textarea,
        input:not([type='radio']):not([type='checkbox']), //TODO use @include wk-inputs
        .wk-textarea,
        .wk-select-field,
        .wk-radio-field,
        .wk-checkbox-field,
        .wk-input {
            font-weight: map-get($wk-font-weight, light);
            margin-bottom: wk-rem(3px); //provides space between the error message below
        }

        .wk-field-header {
            font-weight: map-get($wk-font-weight, regular);
            margin-bottom: wk-rem(3px);

            > .wk-field-tip {
                color: $wk-field-tip-color;
                float: right;
                font-size: map-get($wk-font-scale, smaller);
                font-style: italic;
                margin-left: wk-rem(6px);
                margin-top: .1em; //em on purpose
            }
        }

        &.wk-field-has-error {
            textarea,
            input:not([type='radio']):not([type='checkbox']), //TODO use @include wk-inputs
            .wk-select-field > select,
            .wk-textarea,
            .wk-input {
                border-color: $wk-field-error-color;
                color: $wk-field-error-color;
            }

            .wk-field-error {
                visibility: visible;
            }

            .wk-field-has-icon .wk-field-icon {
                color: $wk-field-icon-error-color;
            }
        }
    }

    .wk-field-has-icon {
        position: $wk-field-has-icon-position;

        @include wk-inputs {
            padding-right: $wk-field-has-icon-right-padding;
        }

        .wk-field-icon {
            background-color: $wk-field-icon-background-color;
            padding: $wk-field-icon-padding; // overwrites button element style
            position: $wk-field-icon-position;
            right: $wk-field-icon-position-right;
            top: $wk-field-icon-position-top;
            width: $wk-field-icon-width; // overwrites button element style
            z-index: $wk-field-icon-z-index;

            @include wk-target-icons {

                @include wk-transition(color);

                color: $wk-field-icon-color;
                font-size: $wk-field-icon-font-size;
                margin: $wk-field-icon-margin;
            }

            &:hover {
                background-color: $wk-field-icon-hover-background-color; // overwrites button element style
            }

            &.wk-field-icon-button {

                &:hover {

                    @include wk-target-icons {
                        color: $wk-field-icon-hover-color;
                    }
                }

                &:focus {

                    @include wk-target-icons {
                        color: $wk-field-icon-focus-color;
                    }
                }
            }
        }
    }

    .wk-field-error {
        color: $wk-field-error-color;
        font-size: map-get($wk-font-scale, smaller);
        font-style: italic;
        visibility: hidden;

        &::after {
            content: '.';
            visibility: hidden;
        }
    }
}
