////
/// @group wk-css.components.cards
/// @author [GPO](https://gpo.wolterskluwer.com/)
/// @since 0.11.0
/// @access public
////

@import 'cards.variables';

@include wk-exports('cards') {

    %scss-deps-cards {
        utils: wk-utils-dependencies(
            function wk-rem,
            function wk-primary-color,
            mixin container,
            mixin span,
            mixin last,
            variable wk-font-stacks,
            variable wk-font-weight,
            variable wk-heading-scale,
            variable wk-body-selection-color,
            variable wk-global-columns,
            variable wk-target-tablet,
            variable wk-target-tablet-desktop
        );
    }

    .wk-card-group {
        display: flex; //we assume flexbox is suppored
        flex-wrap: wrap;

        > .wk-card {
            margin-bottom: $wk-card-margin;

            @include breakpoint($wk-target-tablet-desktop) {
                float: none; //wipe out the @include span() float: left when flexbox is available
            }

        }
    }

    //card groups generic styles
    .wk-card-group-4,
    .wk-card-group-3,
    .wk-card-group-2 {
        > .wk-card {
            margin-bottom: $wk-card-margin;
            width: 100%;
        }
    }

    //two cards on tablet/desktop
    //card group layouts that are applicable whether or not flexbox is used or floats
    .wk-card-group-2 {
        > .wk-card {
            @include breakpoint($wk-target-tablet-desktop) {
                @include span($wk-global-columns / 2 of $wk-global-columns);

                &:nth-child(2n) {
                    @include last;
                }

            }
        }
    }

    //two cards for tablet
    .wk-card-group-3,
    .wk-card-group-4 {
        > .wk-card {
            @include breakpoint($wk-target-tablet) {
                @include span($wk-global-columns / 2 of $wk-global-columns);

                &:nth-child(2n) {
                    @include last;
                }

            }
        }
    }

    //three cards on desktop
    //card group layouts that are applicable whether or not flexbox is used or floats
    .wk-card-group-3 {
        > .wk-card {
            @include breakpoint($wk-target-desktop) {
                @include span($wk-global-columns / 3 of $wk-global-columns);

                &:nth-child(3n) {
                    @include last;
                }

            }
        }
    }

    //four cards on desktop
    //card group layouts that are applicable whether or not flexbox is used or floats
    .wk-card-group-4 {
        > .wk-card {
            @include breakpoint($wk-target-desktop) {
                @include span($wk-global-columns / 4 of $wk-global-columns);

                &:nth-child(4n) {
                    @include last;
                }

            }
        }
    }

    //when no flexbox is available
    //.ie9 comes from HTML conditional comments
    //.no-flexbox comes from Modernizr
    //.wk-card-group-float is for forcing the float layout
    html.ie9 .wk-card-group,
    html.no-flexbox .wk-card-group,
    .wk-card-group-float.wk-card-group {
        @include container;

        display: block;

        > .wk-card {
            position: relative;

            @include breakpoint($wk-target-tablet-desktop) {
                max-height: $wk-card-float-height;
                min-height: $wk-card-float-height;
                overflow: hidden;

                //fade to indicate there is more content
                &::after {
                    background: $wk-card-after-layer;
                    bottom: -1px;
                    content: '';
                    display: block;
                    height: 30px;
                    left: 1px;
                    position: absolute;
                    right: 1px;
                }
            }
        }
    }

    .wk-card,
    a.wk-card {
        background-color: $wk-card-background;
        border: $wk-card-border;
        font-family: map-get($wk-font-stacks, global-sans);
        padding: $wk-card-padding;

        .wk-card-meta,
        .wk-card-title {
            color: $wk-card-heading;
        }

        .wk-card-title {
            display: block;
            font-size: map-get($wk-heading-scale, h5);
            font-weight: map-get($wk-font-weight, bold);
            margin-bottom: $wk-card-title-margin-bottom;
            margin-top: 0;
        }

        .wk-card-body {
            color: $wk-body-text-color;
        }

        .wk-label,
        .wk-label-success,
        .wk-label-info,
        .wk-label-danger {
            float: right;
            margin-bottom: $wk-card-label-margin;
            margin-left: $wk-card-label-margin;
        }

        &:focus,
        &:hover {
            background: $wk-body-selection-color;

            .wk-card-title,
            .wk-card-meta,
            .wk-card-body {
                color: $wk-card-text-color;
            }
        }
    }
}
