////
/// @group wk-css.components.icons
/// @author [GPO](https://gpo.wolterskluwer.com/)
/// @since 0.3.0
/// @access public
////

@import 'icons.variables';
@import 'icons.mixins';

// For now let's use a separate .scss file for our icons,
// not the one generated by fontello, because:
// 1. they generate a css
// 2. font-face paths not the ones we want
// 3. TODO: check this one later: https://github.com/fireball-x/gulp-fontello-import

@include wk-exports('icons') {

    @include wk-font-face('wk-icons', $wk-font-path + 'wk-icons/font/wk-icons');

    @include wk-target-icons-element {
        display: $wk-icons-element-display;
    }

    @include wk-target-icons {
        @extend %icon-base;
    }

    %scss-deps-icons {
        utils: wk-utils-dependencies(
            mixin wk-font-face,
            mixin wk-target-icons,
            mixin wk-target-icons-element,
            variable wk-font-path
        );
    }

    %icon-base {
        display: inline-block;
        font-family: 'wk-icons';
        font-smoothing: grayscale;
        font-style: normal;
        font-variant: normal;
        font-weight: normal;
        line-height: 1em;
        margin-left: .2em;
        margin-right: .2em;
        speak: none;
        text-align: center;
        text-decoration: inherit;
        text-transform: none;
        width: 1em;
    }
}

@import '../fonts/wk-icons/css/wk-icons-codes';
